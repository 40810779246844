import axios from 'axios';

import config from '../config';
import { validateToken } from '@utils/isTokenExpired';
import {
  GetTrialsResponsePayload,
  GetTrialGrowersMembersApiResponsePayload,
  GetTrialFieldsListResponsePayload,
  GetTrialProductsResponsePayload,
  AddMemberRequestPayload,
  AddMemberResponsePayload
} from '@mytypes/trial';

export const getTrialsApi = async (clientId: string): Promise<GetTrialsResponsePayload | undefined> => {
  if (!(await validateToken())) {
    throw new Error("Failure fetching client trials");
  }

  const { data } = await axios({
    url: `${config.REACT_APP_TRIAL_SERVICE_URL}/v2/myself?client_id=${clientId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return data as GetTrialsResponsePayload;
};

export const getTrialGrowersMembersApi = async (trial: string, role: string): Promise<GetTrialGrowersMembersApiResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_TRIAL_SERVICE_URL}/v2/groups/members?trial_id=${trial}&group_name=${role}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetTrialGrowersMembersApiResponsePayload;
  } catch (error) {
    console.log('Something went wrong ', error);
  }
};

export const getTrialFieldsListApi = async (trialId: string): Promise<GetTrialFieldsListResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_TRIAL_SERVICE_URL}/v2/fields?trial_id=${trialId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetTrialFieldsListResponsePayload;
  } catch (error) {
    console.log('Something went wrong ', error);
  }
};

export const getTrialProductsApi = async (trialId: string): Promise<GetTrialProductsResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_TRIAL_SERVICE_URL}/v2/products?trial_id=${trialId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetTrialProductsResponsePayload;
  } catch (error) {
    console.log('Something went wrong ', error);
  }
};

export const addMemberApi = async (payload: AddMemberRequestPayload): Promise<AddMemberResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_TRIAL_SERVICE_URL}/v2/groups/members/add`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        trial_id: payload.trialId,
        client_id: payload.clientId,
        members: payload.members
      }
    });

    return data as AddMemberResponsePayload;
  } catch (error) {
    console.log(error);
  }
};
