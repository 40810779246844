import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    min-height: 100px;
    margin-bottom: 30px;
    padding: 10px;
  `,
  thumbnail: css`
    background-size: cover;
    position: relative;
    min-height: 85px;
  `,
  selected: css`
    border: 2px solid red;
  `,
  hidden: css`
    display: none;
  `,
  deletePhotoBtn: css`
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #000;
    color: #fff;
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
  `,
  deletePhotoBtnIcon: css`
    width: 20px;
    height: 20px;
  `,
  addPhotoBtn: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 85px;
  `,
  addPhotoBtnIconContainer: css`
    color: '#dbdbdb';
  `,
  addPhotoBtnIcon: css`
    width: 40px;
    height: 40px;
  `,
  emptyState: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    max-height: 200px;
    justify-content: center;
    background-color: #1f2128;
  `,
  inputget: css`
    font-size: 1px;
    position: absolute;
    z-index: -1;
  `,
  dialogText: css`
    font-weight: 500;
  `,
  photoWrapper: css`
    -webkit-user-select: none;
    border: none;
    padding: 0;
  `
};
