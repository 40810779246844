/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Autocomplete, IconButton, TextField } from '@mui/material';

import { ToastType } from '@components/ui/Toast';
import { useToast } from '@hooks/app/useToast';
import { useAppTheme } from '@hooks/useAppTheme';
import { Client } from '@mytypes/client';
import { GrowerSelectOption } from '@mytypes/grower';
import { favoriteClientApi } from '@services/user';
import { RootState } from '@state-mgmt/store';
import { setClientId, setFavoriteClient, setTrialId } from '@state-mgmt/slices/appSlice';
import { setCurrentProtocol } from '@state-mgmt/slices/protocol-slice';
import { styles } from './styles';

export interface Props {
  onClose: () => void;
}

const SwitchClientModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const { themeMode, clientId, favoriteClientId } = useSelector((state: RootState) => state.app);
  const { clients } = useSelector((state: RootState) => state.client);
  const [selectedClient, setSelectedClient] = useState<GrowerSelectOption | undefined>();
  const showToast = useToast();

  const orderedClients = [...clients].sort((a: Client, b: Client) => a.name.localeCompare(b.name));
  const [clientList, setClientList] = useState<Client[]>(orderedClients);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedClient) {
      setClientList(clientList.filter(client => (selectedClient ? selectedClient.id === client.id : true)));
    } else {
      setClientList(orderedClients);
    }
  }, [selectedClient]);

  const handleOnSwitchClientPressed = (id: string) => {
    dispatch(setClientId(id));
    localStorage.setItem('clientId', id);
    dispatch(setTrialId(undefined));
    localStorage.setItem('trialId', '');
    dispatch(setCurrentProtocol(undefined));
    onClose();
    navigate('/splash');
  };

  const handleOnFavoriteClientBtnPressed = async (id: string) => {
    try {
      const value = favoriteClientId === id ? null : id;
      await favoriteClientApi({ favoriteClientId: value });
      dispatch(setFavoriteClient(value));
      if (value) {
        handleOnSwitchClientPressed(id);
      }
    } catch (error) {
      showToast({ type: ToastType.ERROR, children: t('switch-client.error-message') });
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('switch-client.title')}</DialogTitle>
      {clientList.length > 0 && (
        <>
          <Autocomplete
            options={clientList.map(({ id, name }) => ({ id, label: name }))}
            renderInput={params => <TextField {...params} label={t('switch-client.search-label')} />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            onChange={(event: any, newValue) => setSelectedClient(newValue ? newValue : undefined)}
            value={selectedClient || null}
            css={styles.filter}
          />

          <List css={styles.list}>
            {clientList
              .slice()
              .sort((a: Client, b: Client) => a.name.localeCompare(b.name))
              .map(({ id, name }) => (
                <ListItem
                  css={[styles.listItem, id === clientId && styles.selectedItem(theme)]}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleOnFavoriteClientBtnPressed(id)}>
                      {favoriteClientId === id ? <GradeIcon /> : <StarOutlineIcon />}
                    </IconButton>
                  }
                  key={id}
                >
                  <ListItemButton selected={id === clientId} onClick={() => handleOnSwitchClientPressed(id)}>
                    <ListItemAvatar>
                      <Avatar css={styles.avatar}>
                        <Typography variant="h5">{name.charAt(0)}</Typography>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </>
      )}
    </div>
  );
};

export default SwitchClientModal;
