import { LocalPage } from './local';

export type SyncDataField = {
  createdAt: string;
  fieldId: string;
  fieldName: string;
  id: number;
  pages: LocalPage[];
  protocolId: string;
  protocolName: string;
  stepId: string;
};

export type SyncDataTrial = {
  fields: SyncDataField[];
  trialId: string;
  trialName: string;
};

export type SyncData = {
  client: {
    id: string;
    name: string;
  };
  clientId: string;
  clientName: string;
  trials: SyncDataTrial[];
};

export type SyncDataResult = {
  data: SyncData[];
  progress: syncProgress[];
};

export enum SYNC_PROGRESS_STATE {
  ERROR = 'error',
  ON_HOLD = 'on-hold',
  SYNCED = 'synced',
  SYNCING = 'syncing'
}

export type syncProgress = {
  localId: number;
  state: SYNC_PROGRESS_STATE;
};
