import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toast } from '@components/ui/Toast';
import { RootState } from '@state-mgmt/store';
import { removeToast } from '@state-mgmt/slices/appSlice';

export const ToastList = () => {
  const [item] = useSelector((state: RootState) => state.app.toastList);
  const dispatch = useDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(removeToast({ key: item.key }));
  }, [item]);

  return !item ? null : <Toast key={item.key} {...item.props} onClose={handleOnClose} />;
};

export default ToastList;
