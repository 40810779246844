import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Trial } from '@mytypes/trial';
import { RootState } from '@state-mgmt/store';

export const useGetTrial = () => {
  const { trials } = useSelector((state: RootState) => state.trial);
  return useCallback((trialId: string): Trial | undefined => trials.find(trial => trial.id === trialId), [trials]);
};
