/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary';
import { Button, Paper, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SendIcon from '@mui/icons-material/Send';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const navigate = useNavigate();

  const { themeMode } = useSelector((state: RootState) => state.app);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const handleReturnHome = () => {
    resetErrorBoundary();
    navigate(`/splash`);
  };

  const onSubmitSupportTicketBtnClicked = () => {
    const currentURL = window.location.href;
    const message = `The app encountered an unexpected error affecting the current action or view.\n The error message captured is as follows: ${error.message}\n URL: ${currentURL}`;
    resetErrorBoundary();
    navigate(`/support?message=${message}`);
  };

  return (
    <Paper css={styles.container(theme, themeMode)}>
      <ErrorOutlineIcon css={styles.icon} />
      <Typography css={styles.title}>{t('error-fallback.title')}</Typography>

      <Typography css={styles.subTitle}>{t('error-fallback.text')}</Typography>

      <Button variant="contained" color="primary" onClick={handleReturnHome}>
        {t('error-fallback.return-button')}
      </Button>

      {!showDetails && <span onClick={() => setShowDetails(true)}>{t('error-fallback.details-button')}</span>}

      {showDetails && (
        <div css={styles.detailsContainer}>
          <textarea readOnly css={[styles.details]}>
            {error.message}
          </textarea>

          <div css={styles.detailsButtonsContainer}>
            <Button onClick={onSubmitSupportTicketBtnClicked} variant="outlined" autoFocus endIcon={<SendIcon sx={{ fontSize: '16px' }} />}>
              {t('error-fallback.submit-ticket-button')}
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default ErrorFallback;
