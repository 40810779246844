/** @jsxImportSource @emotion/react */
import * as turf from '@turf/turf';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import Map, { MapRef, Marker, MarkerDragEvent } from 'react-map-gl';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Avatar, Typography, darken } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SquareIcon from '@mui/icons-material/Square';
import CircleIcon from '@mui/icons-material/Circle';
import { Fab } from '@mui/material';
import { MapViewState, Position } from '@mytypes/map';
import NearMeIcon from '@mui/icons-material/NearMe';
import PlaceIcon from '@mui/icons-material/Place';
import 'mapbox-gl/dist/mapbox-gl.css';

import useSsurgoColorMap from '@hooks/useSsurgoColorMap';
import { useGetSsurgoMunames } from '@hooks/useGetSsurgoMunames';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { getFieldCenterPoint } from '@utils/getFieldCenterPoint';
import { convertHexToArray, convertRgbToMapColorArray } from '@utils/colorUtility';
import GeojsonPolygonLayer from './GeojsonPolygonLayer';
import GeojsonLayer from './GeojsonLayer';
import config from '../../config';
import { styles as mapViewStyles } from './styles';
// import { styles } from 'src/styles';

export const defaultViewState: MapViewState = {
  longitude: -100,
  latitude: 40,
  zoom: 4
};

export interface Props {
  disableFlyToAnimation?: boolean;
  disableZoom?: boolean;
  fieldBoundary?: any;
  initialZoom?: number;
  markers?: any[];
  position?: Position;
  previousVisitsMarkers?: any[];
  showAccuracyArea?: boolean;
  showCenterPin?: boolean;
  showCurrentPosition?: boolean;
  showEditMarkerLocationMessage?: boolean;
  showFlyToCurrentLocation?: boolean;
  showSsurgo?: boolean;
  showTreatmentZones?: boolean;
  showTrialArea?: boolean;
  soilGeojson?: any;
  trialAreaGeoJson?: any;
  zoneGeojson?: any;
  onDraggStatusChange?: (dragging: boolean) => void;
  onFocusMarkerLocationChange?: (markerId: string, position: Position) => void;
  onMapDrag?: (center: Position) => void;
  onMarkerLocationClicked?: (markerId: string) => void;
  onPreviousMarkerLocationClicked?: (markerId: string) => void;
}

const mapStyles = {
  dark: 'mapbox://styles/mapbox/dark-v10',
  light: 'mapbox://styles/mapbox/light-v10',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v12',
  navigationLight: 'mapbox://styles/mapbox/navigation-day-v1',
  navigationNight: 'mapbox://styles/mapbox/navigation-night-v1'
};

const positionZoom = 13;

const MapView = ({
  disableFlyToAnimation = false,
  disableZoom = false,
  fieldBoundary,
  initialZoom,
  markers,
  position,
  previousVisitsMarkers,
  showAccuracyArea = false,
  showCenterPin = false,
  showCurrentPosition = false,
  showEditMarkerLocationMessage = false,
  showFlyToCurrentLocation = false,
  showSsurgo = false,
  showTreatmentZones = false,
  showTrialArea = true,
  soilGeojson,
  trialAreaGeoJson,
  zoneGeojson,
  onDraggStatusChange,
  onFocusMarkerLocationChange,
  onMapDrag,
  onMarkerLocationClicked,
  onPreviousMarkerLocationClicked
}: Props) => {
  const { showBoundary } = useErrorBoundary();

  const { t } = useTranslation();
  const theme = useAppTheme({});
  const mapRef = useRef<MapRef>();
  const { accuracy, currentPosition, online, offSite } = useSelector((state: RootState) => state.app);
  const { trialProducts } = useSelector((state: RootState) => state.trial);

  const [getSsurgoMunamesState, getSsurgoMunames] = useGetSsurgoMunames();
  const ssurgoColorMap = useSsurgoColorMap(soilGeojson);

  const [flyToCurrentLocation, setFlyToCurrentLocation] = useState<boolean>(false);
  const [floatMessage, setFloatMessage] = useState<string | undefined>();
  const [centerPoint, setCenterPoint] = useState<Position | undefined>();
  const [circle, setCircle] = useState<any>();

  useEffect(() => {
    if (fieldBoundary) {
      setCenterPoint(getFieldCenterPoint(fieldBoundary));
    }
  }, [fieldBoundary]);

  useEffect(() => {
    if (centerPoint) {
      mapRef.current?.flyTo({
        center: position ? [position.longitude, position.latitude] : [centerPoint.longitude, centerPoint.latitude],
        zoom: initialZoom || positionZoom,
        essential: true,
        ...(disableFlyToAnimation && { duration: 0 })
      });
    }
  }, [centerPoint]);

  useEffect(() => {
    if (flyToCurrentLocation && currentPosition) {
      mapRef.current?.flyTo({
        center: [currentPosition.longitude, currentPosition.latitude],
        zoom: positionZoom,
        essential: true
      });
    }
    if (!flyToCurrentLocation && centerPoint) {
      mapRef.current?.flyTo({
        center: [centerPoint.longitude, centerPoint.latitude],
        zoom: positionZoom,
        essential: true
      });
    }
  }, [flyToCurrentLocation]);

  useEffect(() => {
    getSsurgoMunames(soilGeojson);
  }, [soilGeojson]);

  useEffect(() => {
    if (currentPosition && accuracy) {
      setCircle(
        turf.circle([currentPosition.longitude, currentPosition.latitude], accuracy / 1000, { steps: 50, units: 'kilometers', properties: { foo: 'bar' } })
      );
    }
  }, [currentPosition, accuracy]);

  const accuracyCircleColor = useMemo(() => {
    if (!accuracy) return 'transparent';
    if (accuracy <= config.REACT_APP_ACCURACY_GOOD) return '#022414';
    if (accuracy <= config.REACT_APP_ACCURACY_FAIR) return '#96610b';
    return '#fc0303';
  }, [accuracy]);

  const onMarkerDragEnd = (event: MarkerDragEvent, marker: any) => {
    try {
      const newPosition: Position = {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng
      };

      onFocusMarkerLocationChange && onFocusMarkerLocationChange(marker.id, newPosition);
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <>
      <Map
        attributionControl={false}
        boxZoom={!disableZoom}
        doubleClickZoom={!disableZoom}
        initialViewState={defaultViewState}
        mapStyle={mapStyles.satellite}
        mapboxAccessToken={config.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onClick={() => setFloatMessage(undefined)}
        // @ts-ignore
        ref={mapRef}
        scrollZoom={!disableZoom}
        touchZoomRotate={!disableZoom}
        onError={e => console.log('Map.onError >>> ', e)}
        onDragEnd={() => {
          if (onMapDrag && mapRef.current?.getCenter().lat && mapRef.current?.getCenter().lng) {
            onMapDrag({ latitude: mapRef.current?.getCenter().lat, longitude: mapRef.current?.getCenter().lng });
          }
        }}
      >
        {showEditMarkerLocationMessage && <span css={mapViewStyles.draggableContainer(theme)}>{t('map-view.drag-marker-message')}</span>}

        {markers?.map((marker, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={marker.longitude || 0}
            latitude={marker.latitude || 0}
            anchor="center"
            draggable={marker.draggable}
            onDragStart={() => {
              onDraggStatusChange && onDraggStatusChange(true);
            }}
            onDragEnd={event => {
              onDraggStatusChange && onDraggStatusChange(false);
              onMarkerDragEnd(event, marker);
            }}
            onClick={() => onMarkerLocationClicked && onMarkerLocationClicked(marker.id)}
            style={{ zIndex: marker.focus ? 931 : 930 }}
          >
            {marker.thumb ? (
              <Avatar alt="" src={marker.thumb} style={{ border: marker.focus ? '3px solid rgb(218, 46, 42)' : '3px solid rgb(110, 91, 90)' }} />
            ) : (
              <SquareIcon
                style={{
                  transform: 'rotate(45deg)',
                  color: marker.focus ? 'rgb(218, 46, 42)' : 'rgb(110, 91, 90)',
                  boxShadow: 'inset 0 0 10px black',
                  fontSize: marker.focus ? '20px' : '14px'
                }}
              />
            )}
          </Marker>
        ))}

        {showCurrentPosition && currentPosition && (
          <Marker longitude={currentPosition.longitude} latitude={currentPosition.latitude} style={{ zIndex: 999 }}>
            <RadioButtonCheckedIcon />
          </Marker>
        )}

        {showSsurgo && soilGeojson && getSsurgoMunamesState.data ? (
          <GeojsonLayer
            id="soils-layer"
            geometry={soilGeojson}
            color="black"
            getFeatureColor={(feature: any) => {
              return convertRgbToMapColorArray(feature.properties.mukey ? ssurgoColorMap[feature.properties.mukey] : ssurgoColorMap[feature.properties.muname]);
            }}
            onSelect={({ object }: any) => {
              const mukey = object?.properties?.mukey as string;
              const muname = object?.properties?.muname;

              const munames = getSsurgoMunamesState.data;
              if (munames) {
                setFloatMessage(munames.find(value => value.id === mukey)?.legend || (muname ? muname : mukey));
              }
            }}
            filled={true}
            opacity={0.4}
            style={{ zIndex: 900 }}
          />
        ) : null}

        {fieldBoundary ? (
          <GeojsonPolygonLayer
            geometry={fieldBoundary}
            filled={!online}
            lineWidthScale={2}
            dashedOutline={true}
            getFillColor={() => convertHexToArray('#707070', 0.9)}
            tooltip="Field Boundary"
            style={{ zIndex: 910 }}
          />
        ) : null}

        {showTrialArea && trialAreaGeoJson ? (
          <GeojsonPolygonLayer
            geometry={trialAreaGeoJson}
            filled={!online}
            color="white"
            lineWidthScale={7}
            getFillColor={() => convertHexToArray('#707070', 0.9)}
            tooltip="Trial Area"
            style={{ zIndex: 920 }}
          />
        ) : null}

        {showTreatmentZones && zoneGeojson ? (
          <GeojsonPolygonLayer
            geometry={zoneGeojson}
            color="white"
            filled
            getFillColor={(feature: any) => {
              let product = feature?.properties?.product_id ? trialProducts?.find(p => p.id === feature.properties.product_id) : null;
              let color = product?.color ? darken(product.color, 0.7) : '#ffffff';
              return convertHexToArray(color, 0.6);
            }}
            getLineColor={(feature: any) => {
              let product = feature?.properties?.product_id ? trialProducts?.find(p => p.id === feature.properties.product_id) : null;
              return convertHexToArray(product?.color || '#ffffff');
            }}
            lineWidthScale={2}
            style={{ zIndex: 930 }}
          />
        ) : null}

        {previousVisitsMarkers?.map((marker, index) => (
          <Marker
            key={`previousMarker-${index}`}
            longitude={marker.longitude || 0}
            latitude={marker.latitude || 0}
            anchor="center"
            onClick={() => {
              onPreviousMarkerLocationClicked && onPreviousMarkerLocationClicked(marker.characterization_id);
            }}
          >
            <CircleIcon
              style={{
                color: marker.color,
                fontSize: '18px',
                zIndex: 940
              }}
            />
          </Marker>
        ))}

        {showCenterPin && (
          <PlaceIcon sx={{ zIndex: '950', color: '#06B7FF', position: 'absolute', top: 'calc(50% - 50px)', left: 'calc(50% - 30px)', fontSize: '60px' }} />
        )}

        {offSite && showAccuracyArea && currentPosition && circle && accuracy && (
          <GeojsonPolygonLayer
            geometry={circle}
            filled={true}
            lineWidthScale={0}
            lineWidth={0}
            getFillColor={() => convertHexToArray(accuracyCircleColor, 0.2)}
            getLineColor={() => convertHexToArray(accuracyCircleColor, 0.4)}
            style={{ zIndex: 980 }}
          />
        )}

        {showFlyToCurrentLocation && (
          <Fab
            color={flyToCurrentLocation ? 'primary' : 'default'}
            aria-label="add"
            size="small"
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 10
            }}
            onClick={() => setFlyToCurrentLocation(!flyToCurrentLocation)}
          >
            <NearMeIcon />
          </Fab>
        )}

        {floatMessage && (
          <Typography
            style={{
              zIndex: '10',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              backgroundColor: '#111111b3',
              margin: 0,
              padding: '8px 16px',
              textAlign: 'center'
            }}
          >
            {floatMessage}
          </Typography>
        )}
      </Map>
    </>
  );
};

export default MapView;
