/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';

import { useAppTheme } from '@hooks/useAppTheme';
import { styles } from './styles';

const EmptyState = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();

  return (
    <div css={styles.container(theme)}>
      <ChecklistIcon fontSize="large" /> <Typography css={styles.title}>{t('planner.empty-state.title')}</Typography>
      <Typography css={styles.subTitle}>{t('planner.empty-state.subtitle')}</Typography>
      <Typography css={styles.subTitle} variant="subtitle2">
        {t('planner.empty-state.text')}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/planner/new')}>
        {t('planner.empty-state.button')}
      </Button>
    </div>
  );
};

export default EmptyState;
