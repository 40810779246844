/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

import { useGetProtocolName } from '@hooks/useGetProtocolName';
import { useGetGrower } from '@hooks/useGetGrower';
import { dropoutGrowerApi } from '@services/protocol';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export enum FIELD_ACTION_MODE {
  DROP = 'drop',
  UNDROP = 'undrop'
}

export interface Props {
  grower?: any;
  onClose: (confirmed: boolean) => void;
  onError?: () => void;
}

const DropGrowerModal = ({ grower, onClose, onError }: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [selectedProtocols, setSelectedProtocols] = useState<string[]>([]);
  const { themeMode, trialId } = useSelector((state: RootState) => state.app);
  const { trialGrowers } = useSelector((state: RootState) => state.trial);
  const getProtocolName = useGetProtocolName();
  const getGrower = useGetGrower();

  useEffect(() => {
    if (grower) {
      const protocols = getGrower(grower.user_id)?.protocol_ids;
      setSelectedProtocols(protocols?.map(p => p.protocol_id) || []);
    }
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = event.target.value;
    const updatedSelectedCheckboxes = [...selectedProtocols];
    if (event.target.checked) {
      updatedSelectedCheckboxes.push(checkboxValue);
    } else {
      const index = updatedSelectedCheckboxes.indexOf(checkboxValue);
      if (index !== -1) {
        updatedSelectedCheckboxes.splice(index, 1);
      }
    }
    setSelectedProtocols(updatedSelectedCheckboxes);
  };

  const onConfirmBtnClicked = () => {
    if (!trialId) return;

    setIsLoading(true);
    let promises = [];
    for (const protocol of selectedProtocols) {
      promises.push(
        new Promise(resolve => {
          resolve(dropoutGrowerApi({ trialId, protocolId: protocol, userId: grower.user_id }));
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        setIsLoading(false);
        onClose(true);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
        onClose(true);
        onError && onError();
      });
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('grower-dropout.title')}</DialogTitle>

      <DialogContent>
        <Typography css={styles.title}>{t('grower-dropout.text', { fullName: `${grower?.first} ${grower?.last}` })}</Typography>
        <FormGroup>
          {trialGrowers
            .find(tg => tg.user_id === grower.user_id)
            ?.protocol_ids.map(({ protocol_id }) => (
              <FormControlLabel
                key={protocol_id}
                control={<Checkbox defaultChecked value={protocol_id} onChange={handleCheckboxChange} />}
                label={getProtocolName(protocol_id)}
              />
            ))}
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <LoadingButton
          disabled={isLoading || !selectedProtocols.length}
          variant="contained"
          onClick={() => onConfirmBtnClicked()}
          loading={isLoading}
          loadingIndicator={t('grower-dropout.dropping-message')}
          css={styles.loadingBtn}
        >
          {t('general.confirm')}
        </LoadingButton>
      </DialogActions>
    </div>
  );
};

export default DropGrowerModal;
