/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ListSubheader, MenuItem, TextField } from '@mui/material';
import { useGetTrialData } from '@hooks/useGetTrialData';
import { Trial } from '@mytypes/trial';
import { RootState } from '@state-mgmt/store';
import { setTrialId } from '@state-mgmt/slices/appSlice';
import { setCurrentProtocol } from '@state-mgmt/slices/protocol-slice';
import { TrialsGropedByYear, groupTrialsByYear } from '@utils/groupTrialsByYear';

export interface Props {
  onTrialSwitching: (isSwitching: boolean) => void;
}

const TrialFilter = ({ onTrialSwitching }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trials } = useSelector((state: RootState) => state.trial);
  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const { getTrialData, isLoading } = useGetTrialData();

  useEffect(() => onTrialSwitching(isLoading), [isLoading]);

  const trialOptions = useMemo(() => {
    const options = [] as ReactNode[];
    const trialsGroupedByYear = groupTrialsByYear(trials);

    const trialYears = Object.keys(trialsGroupedByYear)
      .sort((a: string, b: string) => a.localeCompare(b))
      .reverse();

    trialYears.forEach(year => {
      options.push(<ListSubheader key={year}>{year}</ListSubheader>);

      trialsGroupedByYear[year as keyof TrialsGropedByYear]
        .sort((a: Trial, b: Trial) => a.name.localeCompare(b.name))
        .forEach((trial: Trial) => {
          options.push(
            <MenuItem key={trial.id} value={trial.id}>
              {trial.name}
            </MenuItem>
          );
        });
    });
    return options;
  }, [trials]);

  const onTrialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!clientId) return;
    const id = event.target.value as string;
    dispatch(setTrialId(id));
    localStorage.setItem('trialId', id);
    dispatch(setCurrentProtocol(undefined));

    getTrialData(clientId, id);
  };

  return (
    <TextField select fullWidth label={t('general.trial')} value={trialId} onChange={onTrialChange}>
      {trialOptions}
    </TextField>
  );
};

export default TrialFilter;
