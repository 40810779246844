import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SelectedFieldsModal } from '@components/Planner/SelectedFieldsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useSelectedFieldsModal = () => {
  const dispatch = useDispatch();
  const modalKey = 'selected-fields-modal';

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(() => {
    const children = <SelectedFieldsModal onClose={onClose} />;
    dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
  }, [dispatch, onClose]);

  return displayModal;
};
