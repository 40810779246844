import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(2)};
    height: 100vh;
  `,
  logoContainer: css`
    display: flex;
    width: 80%;
    padding: 20px 0;
  `,
  logo: css`
    width: 100%;
  `,
  title: css`
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
  `,
  subtitle: css`
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    width: 80%;
  `,
  sendBtn: css`
    margin: 20px 0;
  `,
  errorMessage: css`
    color: #ec6363;
    font-size: 14px;
    font-weight: 100;
  `,
  sucessMessage: css`
    font-size: 14px;
    font-weight: 100;
  `
};
