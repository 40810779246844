import { css } from '@emotion/react';

export const styles = {
  locationBtnContainer: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  `,
  updatePositionBtn: css``,
  updatePositionDisabledBtn: css`
    color: #ccc;
  `
};
