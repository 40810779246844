import React, { useMemo } from 'react';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import { useControl } from 'react-map-gl';

import colorString from 'color-string';

const DeckGLOverlay = (
  props: MapboxOverlayProps & {
    interleaved?: boolean;
  }
) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

/**
 * Generic map layer for displaying geojson as a polygon
 *
 * @param props:
 *    layerId - unique string identifying the layer - make sure it's unique for the map as it
 *      will only display one with the same layerId
 *    geometry - geojson to display
 *    color - color for polygon outline.  Defaults to white
 */
export default function GeojsonLayer({
  color,
  filled = false,
  geometry,
  getFeatureColor,
  id,
  onSelect,
  opacity = 1,
  style,
  tooltip,
  tooltipSelector
}: {
  color?: string;
  filled?: boolean;
  geometry: string;
  getFeatureColor?: (d: any) => any;
  id: string;
  onSelect?: (info: any) => any;
  opacity?: number;
  style?: any;
  tooltip?: string;
  tooltipSelector?: (info: any) => string | undefined;
}) {
  const rgbColor = useMemo(() => colorString.get.rgb(color || null), [color]);

  const layer = useMemo(() => {
    return new GeoJsonLayer({
      id,
      data: geometry,
      pickable: true,
      stroked: true,
      filled: filled,
      extruded: false,
      wireframe: false,
      lineWidthScale: 5,
      pointType: 'circle',
      lineWidthMinPixels: 1,
      getLineColor: getFeatureColor
        ? getFeatureColor
        : d => {
            return rgbColor ? new Uint8Array(rgbColor.slice(0, 3)) : new Uint8Array([255, 255, 255]);
          },
      getFillColor: filled
        ? getFeatureColor
        : d => {
            return rgbColor ? new Uint8Array(rgbColor.slice(0, 3)) : new Uint8Array([255, 255, 255]);
          },
      getPointRadius: 1,
      pointRadiusMaxPixels: 1,
      pointRadiusScale: 1,
      pointAntialiasing: false,
      getLineWidth: 1,
      autoHighlight: true,
      onClick: onSelect ? (info: any) => onSelect(info) : null,
      opacity: opacity
    });
  }, [geometry, rgbColor, id, getFeatureColor, filled, opacity]);

  return (
    <DeckGLOverlay
      layers={[layer]}
      getTooltip={
        tooltipSelector
          ? tooltipSelector
          : ({ object }) => {
              return object && tooltip;
            }
      }
      style={style}
    />
  );
}
