import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #35363a;
    position: relative;
  `,
  btnContainer: css`
    width: 40px;
  `,
  title: (theme: Theme) => css`
    text-align: center;
    margin: 0;
  `,
  subtitle: (theme: Theme) => css`
    font-size: 14px;
    text-align: center;
    margin: 0;
  `,
  widthRefreshBtn: css`
    // margin-right: 40px;
  `,
  logoContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
  `,
  logo: css`
    width: 100%;
    height: auto;
  `,
  claimSpace: css`
    display: flex;
    flex-direction: row;
    width: 80px;
  `,
  claimSpaceLeft: css`
    justify-content: start;
  `,
  claimSpaceRight: css`
    justify-content: end;
  `
};
