import { getFieldVisitValuesApi } from '@services/protocol';
import { getFieldVisitsByFieldIdWithDexie } from '@services/local';
import { FieldVisitStep, QUESTION_TYPE } from '@mytypes/protocol';
import { getProtocolStatus } from './getProtocolStatus';
import { getProtocolStatusField } from './getProtocolStatusField';
import { getStepCharacteristics } from './getStepCharacteristics';
import { parseCharacterizationValueReceived } from './parseCharacterizationValueReceived';
import { getLocationValueFromWktPoint } from './getLocationValueFromWktPoint';
import { getStepSavedValues } from './getStepSavedValues';

export const getFieldVisitSteps = async (trialId: string, protocolId: string, fieldId: string): Promise<FieldVisitStep[] | undefined> => {
  try {
    const protocolStatusField = await getProtocolStatusField(trialId, protocolId, fieldId); // the questions of the protocol
    const protocolStatus = await getProtocolStatus(trialId, protocolId); // the questions details
    const stepValues = await getFieldVisitValuesApi({ trialId, protocolId, fieldId }); // get values
    const localVisits = await getFieldVisitsByFieldIdWithDexie(fieldId); // local visits

    const result: FieldVisitStep[] = [];
    for (const step of protocolStatus) {
      const stepStatus = protocolStatusField.find((values: any) => values.step_id === step.step_id);
      if (!stepStatus) return;

      const localId: number | null = localVisits.find(localVisit => localVisit.fieldId === fieldId && localVisit.stepId === step.step_id)?.id || null;

      // @ts-ignore
      let pages = [];
      const characteristicsByPage = await getStepCharacteristics(trialId, protocolId, step.step_id);

      if ('no-page' in characteristicsByPage) {
        const questionsParsed = characteristicsByPage['no-page'].map(({ characterization_id, label, options, order, required, value_type }) => {
          const characteritValues = stepValues?.values.filter(characteritValue => characteritValue.characterization_id === characterization_id);

          return {
            characterization_id,
            label,
            options,
            order,
            pageId: 'no-page',
            required,
            value: parseCharacterizationValueReceived(characteritValues, value_type as QUESTION_TYPE),
            valueId: characteritValues?.map(v => v.id),
            valueType: value_type,
            ...(value_type !== QUESTION_TYPE.UPLOAD &&
              characteritValues?.length &&
              characteritValues[0].wkt_point &&
              getLocationValueFromWktPoint(characteritValues[0].wkt_point))
          };
        });

        pages.push({
          order: 0,
          pageId: 'no-page',
          label: 'Standalone Questions',
          products: [],
          questions: questionsParsed
        });
      }

      step.pages.map(({ page_id, number, label, product_ids }) => {
        const pageCharacteritctis = characteristicsByPage[page_id];

        const pageCharacteritctisWithValues = pageCharacteritctis?.map(({ characterization_id, label, required, order, value_type, options }) => {
          const characteritValue = stepValues?.values.filter((v: any) => v.characterization_id === characterization_id);

          return {
            characterization_id,
            label,
            options: options,
            order,
            required,
            value: parseCharacterizationValueReceived(characteritValue, value_type as QUESTION_TYPE),
            valueId: characteritValue?.map(v => v.id),
            valueType: value_type as QUESTION_TYPE,
            ...(value_type !== QUESTION_TYPE.UPLOAD &&
              characteritValue?.length &&
              characteritValue[0].wkt_point &&
              getLocationValueFromWktPoint(characteritValue[0].wkt_point))
          };
        });

        if (pageCharacteritctisWithValues) {
          pages = [
            // @ts-ignore
            ...pages,
            {
              order: number + 1,
              pageId: page_id,
              label: label,
              products: product_ids || [],
              questions: pageCharacteritctisWithValues || []
            }
          ];
        }
      });

      result.push({
        // @ts-ignore
        localId,
        stepId: step.step_id,
        label: step.label,
        completedBy:
          stepStatus.completed_by_first || stepStatus.completed_by_last
            ? `${stepStatus.completed_by_first || ''} ${stepStatus.completed_by_last || ''}`
            : 'N/A',
        completedAt: `${stepStatus.completed_date}`,
        mainActionCompleted: stepStatus.main_action_completed,
        // @ts-ignore
        startDate: step.start_date,
        endDate: step.end_date,
        // @ts-ignore
        pages
      });
    }

    // get saved values for all steps
    for (const step of result) {
      step['savedValues'] = getStepSavedValues(step);
    }

    return result;
  } catch (error) {
    console.log(error);
  }
};
