import { getProtocolStatusApi } from '@services/protocol';
import { STEP_TYPE, Step } from '../types/protocol';

export const getProtocolStatus = async (trialId: string, protocolId: string): Promise<Step[]> => {
  try {
    const response = await getProtocolStatusApi({ trialId, protocolId, stepType: STEP_TYPE.FIELD_VISIT });
    return response?.status || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
