import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '@components/ui/Modal';
import { RootState } from '@state-mgmt/store';

export const ModalList = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [modal] = useSelector((state: RootState) => state.app.modalList);

  useEffect(() => {
    if (modal) {
      setVisible(true);
    }
  }, [modal]);

  return !modal ? null : <Modal {...modal.props} key={modal.key} visible={visible} />;
};

export default ModalList;
