import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getAllFieldVisitsWithDexie } from '@services/local';

export const useMustSyncFieldVisits = () => {
  return useAsyncCallback(
    useCallback(async () => {
      try {
        const localVisits = await getAllFieldVisitsWithDexie();
        return localVisits.length ? true : false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }, [])
  );
};
