import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

import { Position } from '@mytypes/map';
import { LocationWarningModal } from '@components/LocationWarningModal';
import { LocationWarningType } from '@components/LocationWarningModal/LocationWarningModal';

export const useLocationWarningModal = () => {
  const modalKey = 'low-accuracy-alert-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (characterizationId: string, position: Position, locationWarningType: LocationWarningType) => {
      const children = (
        <LocationWarningModal
          accuracy={30}
          characterizationId={characterizationId}
          locationWarningType={locationWarningType}
          position={position}
          onClose={onClose}
        />
      );
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
