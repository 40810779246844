import { useMemo } from 'react';

const SSURGO_LAYER_SHADES = [
  // shades of green
  '#306B03',
  '#F2FDCF',
  '#418106',
  '#E2FBA0',
  '#58A10A',
  '#CAF56F',
  '#71C00E',
  '#B2EC4A',
  '#8EE014',
  // shades of blue
  '#091A7A',
  '#D6E4FF',
  '#102693',
  '#ADC8FF',
  '#1939B7',
  '#84A9FF',
  '#254EDB',
  '#6690FF',
  '#3366FF'
];

export default function useSsurgoColorMap(geometry: any) {
  return useMemo(() => {
    if (!geometry?.features) return null;

    // @ts-ignore
    const munames: string[] = [...new Set(geometry.features.map((feature: any) => feature.properties.mukey || feature.properties.muname))] as string[];

    return munames.reduce((acc: any, key, index) => {
      acc[key] = SSURGO_LAYER_SHADES[index % SSURGO_LAYER_SHADES.length];
      return acc;
    }, {});
  }, [geometry]);
}
