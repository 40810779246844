/** @jsxImportSource @emotion/react */
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useAppTheme } from '@hooks/useAppTheme';
import { selectCurrentNote } from '@state-mgmt/slices/notesSlice';
import { styles } from './styles';

const PhotoList = ({ onDelete }: { onDelete: (valueId: any) => void }) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const selectedNote = useSelector(selectCurrentNote);

  return selectedNote && Array.isArray(selectedNote.media) && selectedNote.media.length ? (
    <div>
      <Typography variant="body2" css={styles.subtitle(theme)}>
        {t('edit-field-note.photos-list')}
      </Typography>

      {selectedNote && Array.isArray(selectedNote.media) && selectedNote.media.length > 0 ? (
        <div css={styles.photos(theme)}>
          {selectedNote.media.map((media: any, i: number) => (
            <div key={i}>
              <div style={{ backgroundImage: `url(${media.bucket_location})` }} css={styles.thumbnail} />
              <div css={styles.photosActions}>
                <Button variant="text" size="small" color="error" onClick={() => onDelete(media.upload_id)}>
                  {t('general.delete')}
                </Button>
                <Button variant="text" size="small">
                  <a href={media.bucket_location || ''} download css={styles.downloadBtn}>
                    {t('general.download')}
                  </a>
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default PhotoList;
