/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Button, Paper } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import { ViewHeader } from '@components/ViewHeader';
import MapView from '@components/MapView/MapView';
import { MAP_LAYERS } from '@components/FieldMapOptionsModal/FieldMapOptionsModal';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { useGetCurrentLocation } from '@hooks/useGetCurrentLocation';
import { useFieldMapOptionsModal } from '@hooks/modals/useFieldMapOptionsModal';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const FieldMap = () => {
  const { t } = useTranslation();

  const { fieldId, protocolId } = useParams();
  const theme = useAppTheme({});
  const [fieldBoundary, setFieldBoundary] = useState<any | undefined>();
  const [trialAreaGeoJson, setTrialAreaGeoJson] = useState<any | undefined>();
  const [zoneGeojson, setZoneGeojson] = useState<any | undefined>();
  const [soilGeojson, setSoilGeojson] = useState<any | undefined>();
  const [getFieldTablesState, getFieldTables] = useGetFieldTables();
  const { displayModal, selectedLayers } = useFieldMapOptionsModal();

  const [treatmentZoneLegends, setTreatmentZoneLegends] = useState<any[]>([]);
  const getCurrentLocation = useGetCurrentLocation();
  const { trialId } = useSelector((state: RootState) => state.app);
  const { trialProducts } = useSelector((state: RootState) => state.trial);

  useEffect(() => {
    getCurrentLocation();

    if (trialId && protocolId && fieldId) {
      getFieldTables(trialId, protocolId, fieldId, true, true);
    }
  }, [fieldId]);

  useEffect(() => {
    if (getFieldTablesState.data) {
      if (getFieldTablesState.data.field) {
        setFieldBoundary(getFieldTablesState.data.field);
      }
      if (getFieldTablesState.data.area) {
        setTrialAreaGeoJson(getFieldTablesState.data.area);
      }

      if (getFieldTablesState.data.treatmentsZones) {
        let treatmentZones: any[] = [];
        getFieldTablesState.data.treatmentsZones.features.forEach((treatmentZone: any) => {
          const zoneProductId = treatmentZone.properties.product_id;
          if (zoneProductId) {
            const product = trialProducts.find(trialProduct => trialProduct.id === zoneProductId);
            if (product) {
              treatmentZones = [...treatmentZones, { label: product.name, color: product.color }];
            }
          }
        });
        setTreatmentZoneLegends(treatmentZones);
        setZoneGeojson(getFieldTablesState.data.treatmentsZones);
      }

      if (getFieldTablesState.data.soil) {
        const data = getFieldTablesState.data.soil;
        setSoilGeojson(data);
      }
    }
  }, [getFieldTablesState.data]);

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('field-map.title')} />
      <Button css={styles.mapOptionBtn} variant="text" startIcon={<TuneIcon />} onClick={() => displayModal(treatmentZoneLegends)}>
        {t('field-map.options-button')}
      </Button>
      <div css={styles.mapContainer}>
        <LoadingOverlay
          spinner
          active={getFieldTablesState.isLoading}
          styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
          text={t('field-map.loading-field')}
        >
          <MapView
            fieldBoundary={fieldBoundary}
            showCurrentPosition={true}
            showFlyToCurrentLocation={true}
            showSsurgo={selectedLayers.includes(MAP_LAYERS.SURGO)}
            showTreatmentZones={selectedLayers.includes(MAP_LAYERS.TREATMENT_ZONES)}
            showTrialArea={selectedLayers.includes(MAP_LAYERS.TRIAL_AREA)}
            soilGeojson={soilGeojson}
            trialAreaGeoJson={trialAreaGeoJson}
            zoneGeojson={zoneGeojson}
          />
        </LoadingOverlay>
      </div>
    </Paper>
  );
};

export default FieldMap;
