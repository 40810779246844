/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useAppTheme } from '@hooks/useAppTheme';
import { styles } from './styles';
import { DotsElasticLoading } from '@components/ui/DotsElasticLoading';

interface Props {
  isSaving: boolean | undefined;
}

export const AutoSaveBtn = ({ isSaving }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});

  return isSaving !== undefined ? (
    <div css={styles.container(theme, !!isSaving)}>
      <span css={styles.textContainer}>{isSaving ? t('auto-save.saving-state') : t('auto-save.saved-state')}</span>

      <span css={styles.iconContainer}>{isSaving ? <DotsElasticLoading /> : <CheckCircleOutlineIcon css={styles.checkIcon} />}</span>
    </div>
  ) : (
    <div css={styles.emptyContainer} />
  );
};

export default AutoSaveBtn;
