import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  title: () => css`
    font-size: 22px;
  `,
  scrollableArea: (theme: Theme) => css`
    overflow-y: scroll;
    width: 100%;
    padding: 0 ${theme.spacing(2)};
    flex-grow: 2;
  `,
  list: (theme: Theme) => css`
    padding: ${theme.spacing(2)};
  `,
  iconProgress: () => css`
    min-width: 40px;
  `,
  growerName: () => css`
    flex-grow: 2;
  `,
  form: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
  `,
  footer: (theme: Theme) => css`
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(2)};
    margin: 0;
    border-radius: 0;
  `,
  footerMessage: (theme: Theme) => css`
    font-weight: normal;
    padding-bottom: ${theme.spacing(2)};
  `
};
