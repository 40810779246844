import {
  AddFieldPayload,
  CreateFieldVisitNotePayload,
  CreateFieldVisitPayload,
  CreateFieldVisitPlanPayload,
  FieldObjStore,
  FieldVisitNoteObjStore,
  FieldVisitObjStore,
  FieldVisitPlanObjStore,
  FieldVisitProgressObjStore,
  LocalVisitProgress,
  UpdateFieldPayload,
  UpdateFieldVisitPayload,
  UpdateFieldVisitPlanPayload
} from '@mytypes/local';
import moment from 'moment';

import { db } from '../../src/db';

// FieldVisitObjStore
export const getAllFieldVisitsWithDexie = async (): Promise<FieldVisitObjStore[]> => {
  return await db.fieldVisits.toArray();
};

export const getFieldVisitWithDexie = async (id: number): Promise<FieldVisitObjStore | undefined> => {
  return await db.fieldVisits.get(id);
};

export const getFieldVisitForWizardWithDexie = async (fieldId: string, protocolId: string, stepId: string): Promise<FieldVisitObjStore | undefined> => {
  try {
    const fieldVisit = await db.fieldVisits
      .where('fieldId')
      .equals(fieldId)
      .and(item => item.protocolId === protocolId)
      .and(item => item.stepId === stepId)
      .first();

    return fieldVisit;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getFieldVisitsByFieldIdWithDexie = async (fieldId: string): Promise<FieldVisitObjStore[]> => {
  try {
    const fieldVisits = await db.fieldVisits.where('fieldId').equals(fieldId).toArray();

    return fieldVisits;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createFieldVisitWithDexie = async (payload: CreateFieldVisitPayload): Promise<number | undefined> => {
  return await db.fieldVisits.add({
    ...payload,
    updatedAt: moment().toISOString(),
    createdAt: moment().toISOString()
  });
};

export const updateFieldVisitWithDexie = async (id: number, payload: UpdateFieldVisitPayload): Promise<number | undefined> => {
  return await db.fieldVisits.update(id, {
    ...payload,
    updatedAt: moment().toISOString()
  });
};

export const deleteFieldVisitWithDexie = async (id: number): Promise<void> => {
  return await db.fieldVisits.delete(id);
};

// FieldVisitProgressObjStore
export const getFieldVisitProgressWithDexie = async (): Promise<FieldVisitProgressObjStore | undefined> => {
  return await db.fieldVisitsProgress.toCollection().first();
};

export const setFieldVisitProgressWithDexie = async (localAutoSaveVisit: LocalVisitProgress): Promise<void> => {
  const progress = await db.fieldVisitsProgress.toCollection().first();
  await db.fieldVisitsProgress.put({ ...(progress ? { id: progress.id } : {}), ...localAutoSaveVisit });
};

export const clearFieldVisitProgressWithDexie = async (): Promise<void> => {
  await db.fieldVisitsProgress.clear();
};

// FieldVisitNoteObjStore
export const getAllFieldVisitsNotesWithDexie = async (): Promise<FieldVisitNoteObjStore[]> => {
  return await db.fieldVisitNotes.toArray();
};

export const createFieldVisitNoteWithDexie = async (payload: CreateFieldVisitNotePayload): Promise<number | undefined> => {
  return await db.fieldVisitNotes.add({
    ...payload,
    updatedAt: moment().toISOString(),
    createdAt: moment().toISOString()
  });
};

export const deleteFieldVisitNoteWithDexie = async (id: number): Promise<void> => {
  return await db.fieldVisitNotes.delete(id);
};

// FieldVisitPlanObjStore
export const getAllFieldVisitPlansWithDexie = async (): Promise<FieldVisitPlanObjStore[]> => {
  return await db.fieldVisitPlans.toArray();
};

export const getFieldVisitPlanWithDexie = async (id: number): Promise<FieldVisitPlanObjStore | undefined> => {
  return await db.fieldVisitPlans.get(id);
};

export const createFieldVisitPlanWithDexie = async (payload: CreateFieldVisitPlanPayload): Promise<number | undefined> => {
  return await db.fieldVisitPlans.add({
    ...payload,
    updatedAt: moment().toISOString(),
    createdAt: moment().toISOString()
  });
};

export const updateFieldVisitsPlanWithDexie = async (id: number, payload: UpdateFieldVisitPlanPayload): Promise<number | undefined> => {
  return await db.fieldVisitPlans.update(id, {
    ...payload,
    updatedAt: moment().toISOString()
  });
};

export const deleteFieldVisitsPlanWithDexie = async (id: number): Promise<void> => {
  const plan = await getFieldVisitPlanWithDexie(Number(id));
  if (!plan) return;

  for (let i = 0; i < plan.fields.length; i++) {
    const field = plan.fields[i];
    const fieldObj = await getFieldByProtocolWithDexie(field.fieldId, field.protocolId);
    if (!fieldObj) continue;

    const fieldObjPlans = fieldObj.plans?.filter((p: any) => Number(p.id) !== id);
    if (fieldObjPlans?.length) {
      await updateFieldWithDexie(fieldObj.id, { plans: fieldObjPlans });
    } else {
      await deleteFieldWithDexie(fieldObj.id);
    }
  }

  return await db.fieldVisitPlans.delete(id);
};

// FieldObjStore
export const getAllFieldsWithDexie = async (): Promise<FieldObjStore[]> => {
  return await db.fields.toArray();
};

export const getFieldByProtocolWithDexie = async (fieldId: string, protocolId: string): Promise<FieldObjStore | undefined> => {
  try {
    const field = await db.fields
      .where('fieldId')
      .equals(fieldId)
      .and(item => item.protocolId === protocolId)
      .first();
    return field;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getFieldByGrowerWithDexie = async (growerId: string): Promise<FieldObjStore[]> => {
  try {
    const field = await db.fields.where('growerId').equals(growerId).toArray();
    return field;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getFieldWithDexie = async (id: number): Promise<FieldObjStore | undefined> => {
  return await db.fields.get(id);
};

export const addFieldWithDexie = async (payload: AddFieldPayload): Promise<void> => {
  const field = await getFieldByProtocolWithDexie(payload.fieldId, payload.protocolId);
  if (field) {
    const newPlans = field.plans.some((item: any) => item.id === payload.planId)
      ? field.plans
      : [...field.plans, { id: payload.planId, name: payload.planName }];
    await updateFieldWithDexie(field.id, { ...payload, plans: newPlans });
  } else {
    await db.fields.add({
      ...payload,
      plans: [{ id: payload.planId, name: payload.planName }],
      updatedAt: moment().toISOString(),
      createdAt: moment().toISOString()
    });
  }
};

export const updateFieldWithDexie = async (id: number, payload: UpdateFieldPayload): Promise<number | undefined> => {
  return await db.fields.update(id, {
    ...payload,
    updatedAt: moment().toISOString()
  });
};

export const deleteFieldWithDexie = async (id: number): Promise<void> => {
  return await db.fields.delete(id);
};
