/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { RootState } from '@state-mgmt/store';
import { setShowAppNavigator, setSyncEnabled } from '@state-mgmt/slices/appSlice';
import { styles } from './styles';

export interface Props {
  onClose: () => void;
  onError?: () => void;
}

const StopDownloadingFieldsModal = ({ onClose, onError }: Props) => {
  const { t } = useTranslation();
  const { themeMode } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('stop-downloading-fields.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('stop-downloading-fields.text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('general.cancel')}</Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(setSyncEnabled(false));
            dispatch(setShowAppNavigator(true));
            // @ts-ignore
            window.syncStoped = true;
            onClose();
            navigate('/splash?redirectTo=/planner');
          }}
          autoFocus
        >
          {t('stop-downloading-fields.confirm-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default StopDownloadingFieldsModal;
