import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
  `,
  clearBtnContainer: css`
    display: flex;
    justify-content: right;
  `,
  clearBtn: css`
    width: 100px;
  `
};
