import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  card: (theme: Theme) => css`
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  cardHeader: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
    border-bottom: 1px solid #35363a;
  `,
  cardContent: (theme: Theme) => css`
    text-indent: ${theme.spacing(2)};
    padding: ${theme.spacing(1)} 0;
  `,
  cardActions: css`
    justify-content: end;
  `
};
