import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropUndropFieldModal } from '@components/DropUndropFieldDialog';
import { FIELD_ACTION_MODE } from '@components/DropGrowerModal';
import { ToastType } from '@components/ui/Toast';
import { useToast } from '@hooks/app/useToast';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useDropUndropFieldModal = () => {
  const modalKey = 'drop-undrop-field-modal';
  const dispatch = useDispatch();
  const showToast = useToast();
  const [isDropped, setIsDropped] = useState<boolean>(false);

  const onClose = useCallback(
    (confirmed: boolean) => {
      setIsDropped(confirmed);
      dispatch(removeModal({ key: modalKey }));
    },
    [dispatch]
  );

  const onError = useCallback(() => showToast({ type: ToastType.ERROR, children: `Something went wrong.` }), []);

  const displayModal = useCallback(
    (field: any, mode: FIELD_ACTION_MODE) => {
      setIsDropped(false);
      const children = <DropUndropFieldModal onClose={onClose} onError={onError} field={field} mode={mode} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return {
    displayModal,
    isDropped
  };
};
