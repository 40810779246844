import { useCallback } from 'react';

import { useAsyncCallback } from '@utils/useAsyncCallback';
import { getFieldNotesApi, submitFieldNotesUploadApi } from '@services/protocol';
import { useDispatch } from 'react-redux';
import { setCurrentNote } from '@state-mgmt/slices/notesSlice';

export const useSubmitFieldNoteUpload = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(
      async ({
        files,
        trialId,
        protocolId,
        upload_type,
        field_note_id,
        longitude,
        latitude,
        fieldId
      }: {
        files: any[];
        trialId: string;
        protocolId: string;
        upload_type: string;
        field_note_id: string;
        longitude?: number;
        latitude?: number;
        fieldId: string;
      }) => {
        try {
          await Promise.all(
            files.map(async file => {
              return await submitFieldNotesUploadApi({
                fieldId,
                field_note_id,
                file,
                latitude,
                longitude,
                protocolId,
                trialId,
                upload_type
              });
            })
          );

          // Reload current note to get new upload urls
          const { notes } = (await getFieldNotesApi({ trialId, protocolId: protocolId, fieldId })) || {};
          if (notes) {
            const note = notes.find(note => note.id === field_note_id);
            if (note) dispatch(setCurrentNote(note));
          }
        } catch (error) {
          throw new Error('Something went wrong');
        }
      },
      []
    )
  );
};
