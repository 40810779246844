import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
  `,
  switchBtn: css`
    padding: 0;
    border-color: #fff;
    color: #fff;
    font-weight: 100;
    font-size: 12px;
  `,
  mapContainer: css`
    display: flex;
    flex-direction: column;
    min-height: calc((var(--vh, 1vh) * 100) / 3);
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `,
  mapFooter: css`
    display: flex;
    align-items: center;
    padding: 8px;
  `,
  accuracy: css`
    display: flex;
    align-items: center;
    gap: 4px;
  `,
  mapOptionBtn: css`
    align-self: flex-end;
    padding: 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    padding: 0 ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  characteristicContainer: css`
    flex-grow: 2;
  `,
  downloadBanner: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    font-weight: 100;
    font-size: 12px;
    background-color: #567952;
  `,
  sectionTitleContainer: css`
    margin-top: 20px;
  `,
  sectionTitle: css`
    display: flex;
    align-items: center;
  `,
  productBullet: (theme: Theme) => css`
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 ${theme.spacing(1)};
    border-radius: 50%;
  `,
  actionsContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(2)};
  `,
  actionsBtn: css`
    width: 100px;
  `,
  dialogText: css`
    font-weight: 500;
  `
};
