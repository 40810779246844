import axios from 'axios';

import {
  ForgotPasswordRequestPayload,
  ForgotPasswordResponsePayload,
  CheckUserRegisteredRequestPayload,
  CheckUserRegisteredResponsePayload,
  RegisterUserRequestPayload,
  RegisterUserResponsePayload,
  HelpdeskUploadResponsePayload,
  HelpdeskUploadRequestPayload,
  FavoriteClientRequestPayload,
  FavoriteClientResponsePayload
} from '@mytypes/user';
import { validateToken } from '@utils/isTokenExpired';
import config from '../config';

export const forgotPasswordApi = async (payload: ForgotPasswordRequestPayload): Promise<ForgotPasswordResponsePayload | undefined> => {
  try {
    const { data } = await axios({
      url: `${config.REACT_APP_USER_SERVICE_URL}/password/forgot`,
      method: 'POST',
      data: { email: payload.email }
    });
    return data as ForgotPasswordResponsePayload;
  } catch (error) {
    console.log(error);
    // @ts-ignore
    if (error.response.status === 401) {
      return { error: true };
    }
  }
};

export const checkUserRegisteredApi = async (payload: CheckUserRegisteredRequestPayload): Promise<CheckUserRegisteredResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_USER_SERVICE_URL}/v2/registered?client_id=${payload.clientId}&email=${payload.email}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as CheckUserRegisteredResponsePayload;
  } catch (error) {
    console.log('Something went wrong ', error);
  }
};

export const registerUserApi = async (payload: RegisterUserRequestPayload): Promise<RegisterUserResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_USER_SERVICE_URL}/register/multiple`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload
    });

    return data as RegisterUserResponsePayload;
  } catch (error) {
    console.log(error);
  }
};

export const helpdeskUploadApi = async (payload: HelpdeskUploadRequestPayload): Promise<HelpdeskUploadResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('file', payload.file);

    const { data } = await axios({
      url: `${config.REACT_APP_USER_SERVICE_URL}/helpdesk/upload`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });

    return data as HelpdeskUploadResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const favoriteClientApi = async (payload: FavoriteClientRequestPayload): Promise<FavoriteClientResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_USER_SERVICE_URL}/v2/favorite-client`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { favorite_client_id: payload.favoriteClientId }
    });

    return data as FavoriteClientResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};
