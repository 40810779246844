import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (theme: Theme, themeMode: THEME_MODE) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100vw - 64px);
    border-radius: ${theme.spacing(0.5)};
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    transform: translate(-50%, -50%);
  `,
  title: css`
    text-align: center;
  `,
  content: css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 16px;
    padding: 0;
  `,
  iconContainer: css`
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    color: '#dbdbdb';
  `,
  icon: css`
    width: 60px;
    height: 60px;
  `,
  text: css`
    font-size: 18px;
  `
};
