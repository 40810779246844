/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useForm } from 'react-hook-form';
import { TextField, Button, Paper, InputAdornment, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import logoDark from '@assets/images/logo-dark.png';
import logoLight from '@assets/images/logo-light.png';
import { useSignIn } from '@hooks/useSignIn';
import { useAppTheme } from '@hooks/useAppTheme';
import { THEME_MODE, setIsAunthetificated } from '@state-mgmt/slices/appSlice';
import { setUserInfo } from '@state-mgmt/slices/appSlice';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type FormData = {
  username: string;
  password: string;
};

const SignIn = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    setError
  } = useForm<FormData>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [signInState, signIn] = useSignIn();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = async ({ username, password }: FormData) => {
    signIn(username, password);
  };

  const validateEmail = (value: string) => {
    return !!value.match(emailRegex) || t('signin.email-not-valid-message');
  };

  const validateRequired = (value: string) => {
    return value.trim() !== '' || t('signin.email-required-message');
  };

  useEffect(() => {
    if (signInState.data) {
      const { accessToken, refreshToken, intentUser, error } = signInState.data;
      if (error) {
        setError('root', { type: 'custom', message: t('signin.invalid-credentials') });
        return;
      }
      if (accessToken && refreshToken && intentUser) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        dispatch(
          setUserInfo({
            clientId: undefined,
            trialId: undefined
          })
        );
        dispatch(setIsAunthetificated(true));

        navigate('/splash?caller=signin');
      }
    }
  }, [signInState.data]);

  return (
    <LoadingOverlay spinner active={signInState.isLoading} text={t('signin.signing-message')}>
      <Paper elevation={2} css={styles.container(theme)}>
        <div css={styles.logoContainer}>
          <img src={themeMode === THEME_MODE.DARK ? logoDark : logoLight} alt="logo" css={styles.logo} />
        </div>

        <form onSubmit={handleSubmit(onSubmitHandler)} css={styles.form}>
          <TextField
            id="email"
            label={t('signin.email-label')}
            margin="normal"
            fullWidth
            error={!!errors['username']}
            helperText={errors['username'] ? errors['username'].message : ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{ className: 'intentInput' }}
            {...register('username', {
              validate: {
                required: validateRequired,
                valid: validateEmail
              }
            })}
          />

          <TextField
            id="password"
            label={t('signin.password-label')}
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            error={!!errors['password']}
            helperText={errors['password'] ? errors['password'].message : ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              className: 'intentInput',
              endAdornment: (
                <InputAdornment position="end" variant="standard">
                  <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((show: any) => !show)} css={styles.showPasswordIcon}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...register('password', {
              validate: {
                required: validateRequired
              }
            })}
          />
          <Button type="submit" variant="contained" color="primary" size="large" css={styles.loginBtn} disabled={!isValid}>
            {t('signin.login-button')}
          </Button>
        </form>

        <Typography color="text.primary" css={styles.question(theme)}>
          {t('signin.forgot-password-text')}
        </Typography>

        <Button variant="contained" color="primary" size="large" css={styles.verifyBtn} onClick={() => navigate('/password/reset')}>
          {t('signin.verify-email-button')}
        </Button>
        {errors['root'] && <h3 css={styles.errorMessage}>{errors['root'].message}</h3>}
      </Paper>
    </LoadingOverlay>
  );
};

export default SignIn;
