import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addModal, removeModal } from '@state-mgmt/slices/appSlice';
import { OnSiteModal } from '@components/OnSiteModal';

export enum SITE_MODE {
  ON = 'on',
  OFF = 'off'
}

export const useOnSiteModal = () => {
  const dispatch = useDispatch();
  const modalKey = 'on-site-modal';
  const [siteMode, setSiteMode] = useState<SITE_MODE | undefined>();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const onConfirmOffSite = useCallback(() => {
    setSiteMode(SITE_MODE.OFF);
    onClose();
  }, [onClose]);

  const onConfirmOnSite = useCallback(() => {
    setSiteMode(SITE_MODE.ON);
    onClose();
  }, [onClose]);

  const displayModal = useCallback(() => {
    const children = <OnSiteModal onConfirmOffSite={onConfirmOffSite} onConfirmOnSite={onConfirmOnSite} />;
    dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
  }, [dispatch, onClose, onConfirmOffSite, onConfirmOnSite]);

  return { displayModal, siteMode };
};
