/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Divider, List, ListItem, ListItemText, ListItemIcon, Typography, CircularProgress, Paper, Button, LinearProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetAllFieldData } from '@hooks/useGetAllFieldData';
import { useStopDownloadingFieldsModal } from '@hooks/modals/useStopDownloadingFieldsModal';
import { FieldVisitPlan } from '@mytypes/local';
import { addFieldWithDexie, getFieldVisitPlanWithDexie, updateFieldVisitsPlanWithDexie } from '@services/local';
import { setShowAppNavigator } from '@state-mgmt/slices/appSlice';
import { clearAllFields } from '@state-mgmt/slices/downloader-slice';
import { styles } from './styles';

export enum UPDATE_PROGRESS_STATE {
  ERROR = 'error',
  ON_HOLD = 'on-hold',
  DOWNLOADED = 'downloaded',
  DOWNLOADING = 'downloading'
}

const DownloadFieldVisitPlan = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planId } = useParams();
  const [progress, setProgress] = useState<any[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [message, setMessage] = useState('');
  const [plan, setPlan] = useState<FieldVisitPlan>();
  const [, getAllFieldData] = useGetAllFieldData();
  const displayModal = useStopDownloadingFieldsModal();

  useEffect(() => {
    if (planId) {
      dispatch(setShowAppNavigator(false));
      setMessage(t('planner.downloader.getting-message'));
      getFieldVisitPlanWithDexie(Number(planId)).then(result => setPlan(result));
    }
  }, [planId]);

  useEffect(() => {
    if (plan) {
      setProgress(plan.fields.map(({ fieldId, protocolId }) => ({ fieldId, protocolId, state: UPDATE_PROGRESS_STATE.ON_HOLD })));
    }
  }, [plan]);

  useEffect(() => {
    if (progress.length > 0 && !isDownloading && !isFinished) {
      setIsDownloading(true);

      initSync();
    }
  }, [progress, isDownloading]);

  const markProgress = async (fieldId: string, protocolId: string, state: UPDATE_PROGRESS_STATE) =>
    setProgress(prevState => prevState.map(ps => (ps.fieldId === fieldId && ps.protocolId === protocolId ? { ...ps, state } : ps)));

  const checkIfSyncIsStoped = () => {
    // @ts-ignore
    if (window.syncStoped) {
      throw new Error(t('planner.downloader.stopped-message'));
    }
  };

  const initSync = async () => {
    if (planId && plan) {
      let fieldsNotDownloaded = 0;

      // @ts-ignore
      window.syncStoped = false;

      for (let i = 0; i < plan.fields.length; i++) {
        checkIfSyncIsStoped();

        const field = plan.fields[i];
        markProgress(field.fieldId, field.protocolId, UPDATE_PROGRESS_STATE.DOWNLOADING);

        try {
          setMessage(t('planner.downloader.fetching-message', { fieldName: field.fieldName }));
          // @ts-ignore
          const { boundaries, visits } = await getAllFieldData(field.trialId, field.protocolId, field.fieldId);
          await updateFieldVisitsPlanWithDexie(Number(planId), {});

          await addFieldWithDexie({
            clientId: field.clientId,
            clientName: field.clientName,
            trialId: field.trialId,
            trialName: field.trialName,
            protocolName: field.protocolName,
            protocolId: field.protocolId,
            fieldId: field.fieldId,
            fieldName: field.fieldName,
            growerId: field.growerId,
            visits,
            boundaries,
            planId,
            planName: plan.name
          });
          markProgress(field.fieldId, field.protocolId, UPDATE_PROGRESS_STATE.DOWNLOADED);
        } catch (error) {
          fieldsNotDownloaded++;
          markProgress(field.fieldId, field.protocolId, UPDATE_PROGRESS_STATE.ERROR);
        }
      }

      setIsFinished(true);
      setIsDownloading(false);
      if (fieldsNotDownloaded > 0) {
        setMessage(t('planner.downloader.partial-success-message', { fieldsNotDownloaded }));
      } else {
        setMessage(t('planner.downloader.success-message'));
      }
    }
  };

  const getProgressIcon = (fieldId: string, protocolId: string) => {
    const fieldProgress = progress.find(item => item.fieldId === fieldId && item.protocolId === protocolId);
    if (fieldProgress && fieldProgress.state === UPDATE_PROGRESS_STATE.ON_HOLD) {
      return <HourglassBottomIcon />;
    }
    if (fieldProgress && fieldProgress.state === UPDATE_PROGRESS_STATE.DOWNLOADING) {
      return <CircularProgress size={20} />;
    }
    if (fieldProgress && fieldProgress.state === UPDATE_PROGRESS_STATE.DOWNLOADED) {
      return <DoneIcon color="success" />;
    }
    if (fieldProgress && fieldProgress.state === UPDATE_PROGRESS_STATE.ERROR) {
      return <ErrorIcon color="error" />;
    }
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={plan?.name || 'no name'} hideGoBackBtn={true} />

      <div css={styles.scrollableArea(theme)}>
        <List css={styles.list(theme)}>
          {plan?.fields.slice().map(({ fieldId, fieldName, growerName, protocolId, protocolName, trialName }) => (
            <div key={`${fieldId}-${protocolId}`}>
              <ListItem disableGutters key={fieldId}>
                <ListItemIcon css={styles.iconProgress}>{getProgressIcon(fieldId, protocolId)}</ListItemIcon>
                <ListItemText
                  primary={fieldName}
                  secondary={
                    <>
                      <Typography color="text.primary">
                        {trialName} - {protocolName}
                      </Typography>
                      <Typography color="text.primary" css={styles.growerName}>
                        {growerName}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
        </List>
      </div>

      <Paper elevation={0} css={styles.footer(theme)}>
        <div id="map-cache-container" />
        {isDownloading && <LinearProgress />}

        <Typography css={styles.footerMessage(theme)} fontSize={14} color="text.primary" dangerouslySetInnerHTML={{ __html: message }} />

        {isDownloading && (
          <Button variant="text" onClick={() => displayModal()}>
            {t('planner.downloader.stop-downloading-button')}
          </Button>
        )}

        {isFinished && (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(clearAllFields());
              dispatch(setShowAppNavigator(true));
              navigate('/splash?redirectTo=/planner');
            }}
          >
            {t('planner.downloader.return-button')}
          </Button>
        )}
      </Paper>
    </Paper>
  );
};

export default DownloadFieldVisitPlan;
