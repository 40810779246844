/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Typography } from '@mui/material';
import Dayjs from 'dayjs';

import MapView from '@components/MapView/MapView';
import { useAppTheme } from '@hooks/useAppTheme';
import { QUESTION_TYPE } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';

import { styles } from './styles';

export interface Props {
  markerInfo: any;
  onClose: () => void;
}

const MarkerDetailsModal = ({ markerInfo, onClose }: Props) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);
  const { fieldBoundary } = useSelector((state: RootState) => state.visit);
  const [tab, setTab] = useState(0);

  const markers = [
    {
      draggable: false,
      focus: true,
      latitude: markerInfo.latitude,
      longitude: markerInfo.longitude
    }
  ];

  const parsedValue = useMemo(() => {
    switch (markerInfo.valueType) {
      case QUESTION_TYPE.TEXT:
        return markerInfo.value;
      case QUESTION_TYPE.INTEGER:
        return markerInfo.value;
      case QUESTION_TYPE.DECIMAL:
        return markerInfo.value;
      case QUESTION_TYPE.SELECTION:
        return markerInfo.value;
      case QUESTION_TYPE.MULTI_SELECTION:
        return markerInfo.value.join(', ');
      case QUESTION_TYPE.FLAG:
        return markerInfo.value ? 'yes' : 'no';
      case QUESTION_TYPE.DATE:
        return Dayjs(markerInfo.value).format('MM/DD/YYYY');
      case QUESTION_TYPE.UPLOAD:
        return markerInfo.value;
      default:
        return undefined;
    }
  }, [markerInfo]);

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('marker-details.title')}</DialogTitle>
      <DialogContent>
        <Typography css={styles.message}>
          <span css={styles.label}>{t('marker-details.label')}:</span> {markerInfo.label}
        </Typography>
        {markerInfo.valueType !== QUESTION_TYPE.UPLOAD && (
          <>
            <Typography css={styles.message}>
              <span css={styles.label}>{t('marker-details.value')}:</span> {parsedValue}
            </Typography>
            <div css={styles.mapContainer}>
              <MapView markers={markers} fieldBoundary={fieldBoundary} disableFlyToAnimation={true} />
            </div>
          </>
        )}

        {markerInfo.valueType === QUESTION_TYPE.UPLOAD && (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tab}
                onChange={(event: React.SyntheticEvent, value: number) => {
                  setTab(value);
                }}
              >
                <Tab label="Image" sx={{ flex: 1 }} />
                <Tab label="Location" sx={{ flex: 1 }} />
              </Tabs>
            </Box>

            {tab === 0 && (
              <div
                style={{
                  margin: '12px 0',
                  backgroundImage: `url(${markerInfo.value}`,
                  width: '100%',
                  height: '300px',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }}
              />
            )}
            {tab === 1 && (
              <div css={styles.mapContainer}>
                <MapView markers={markers} fieldBoundary={fieldBoundary} disableFlyToAnimation={true} />
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions css={styles.actionsContainer(theme)}>
        <Button variant="contained" onClick={async () => onClose()}>
          {t('general.ok')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default MarkerDetailsModal;
