import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: calc(var(--vw, 1vw) * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    overflow-y: scroll;
    background: ${themeMode === THEME_MODE.DARK ? '#1F2128' : '#fff'};
  `,
  content: css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  geoCoder: (theme: Theme) => css`
    height: 85px;
    padding: ${theme.spacing(2)};
    z-index: 100;
  `,
  mapContainer: css`
    flex-grow: 2;
  `,
  centeredText: css`
    text-align: center;
  `,
  legendContainer: (theme: Theme) => css`
    padding-top: ${theme.spacing(1)};
  `,
  actionsContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(2)};
  `,
  actionBtn: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    width: 49%;
  `,
  modeBtn: css`
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 9999;
    width: 170px;
  `,
  loadingBtn: css`
    min-width: 100px;
  `,
  contentDialog: (theme: Theme) => css`
    padding-top: ${theme.spacing(2)} !important;
  `
};
