import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { FeatureCollection } from '@turf/turf';

import { Muname } from '@mytypes/soil';
import { getSsurgoLegendApi } from '@services/soild';

export const useGetSsurgoMunames = () => {
  return useAsyncCallback(
    useCallback(async (geojson: FeatureCollection): Promise<Muname[] | undefined> => {
      try {
        if (geojson && geojson.features && Array.isArray(geojson.features)) {
          let mukeys = [] as string[];

          geojson.features.forEach(feature => {
            if (feature.properties?.mukey) {
              mukeys.push(`${feature.properties.mukey}`);
            }
          });

          if (!mukeys.length) {
            return [];
          }

          const data = await getSsurgoLegendApi(mukeys);
          const munames = data?.map((value: string[]) => ({
            id: value[0] || '',
            legend: value[1] || ''
          }));

          return munames;
        }
      } catch {
        return [];
      }
    }, [])
  );
};
