import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: 100%;
    padding: 0 ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
  `,
  title: () => css`
    font-size: 22px;
  `,
  form: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
  `
};
