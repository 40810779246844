/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { parse } from 'wellknown';
import { useTranslation } from 'react-i18next';

export interface Props {
  centerPoint?: string;
  disabled?: boolean;
  fieldId: string;
}

const GOOGLE_MAPS_API_DIR_URL = 'https://www.google.com/maps/dir/?api=1';

const NavigateButton = ({ disabled = false, centerPoint }: Props) => {
  const { t } = useTranslation();

  const [href, setHref] = useState<string | undefined>();

  useEffect(() => {
    if (centerPoint) {
      // @ts-ignore
      const { coordinates } = parse(centerPoint);
      if (coordinates && coordinates.length === 2) {
        setHref(`${GOOGLE_MAPS_API_DIR_URL}&destination=${coordinates[1]},${coordinates[0]}`);
      }
    }
  }, [centerPoint]);

  return (
    <Button href={href || '_'} target="_blank" size="small" disabled={!href || disabled}>
      {t('general.navigate')}
    </Button>
  );
};

export default NavigateButton;
