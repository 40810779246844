import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: 100%;
    padding: ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  clientDivider: css`
    border-color: #fff;
    padding-top: 4px;
  `,
  trialContainer: (theme: Theme) => css`
    padding: ${theme.spacing(2)} 0;
  `,
  listItemIcon: css`
    min-width: 40px;
  `,
  fieldVisitTitle: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(1)};
  `,
  footer: (theme: Theme) => css`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(2)};
    margin: 0;
    border-radius: 0;
  `,
  footerMessage: (theme: Theme) => css`
    font-weight: normal;
    padding-bottom: ${theme.spacing(2)};
  `
};
