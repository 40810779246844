import { useCallback } from 'react';

import { ToastType } from '@components/ui/Toast';
import { useToast } from '@hooks/app/useToast';
import { getAllFieldVisitsNotesWithDexie, getAllFieldVisitsWithDexie } from '@services/local';

export const useExportLocalData = () => {
  const showToast = useToast();

  return useCallback(async () => {
    try {
      const visits = await getAllFieldVisitsWithDexie();
      const notes = await getAllFieldVisitsNotesWithDexie();
      const payload = { visits, notes };
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(payload))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = 'local-unsync-data.json';
      link.click();
      showToast({ type: ToastType.SUCCESS, children: `Data exported successfully.` });
    } catch (error) {
      showToast({ type: ToastType.ERROR, children: `Ups! Data couldn't be exported. Please, try again.` });
      console.log(error);
    }
  }, [showToast]);
};
