import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: calc(100vw - 64px);
    max-height: 90vh;
    border-radius: 4px;
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    transform: translate(-50%, -50%);
  `,
  scrollableArea: (theme: Theme) => css`
    flex-grow: 1;
    padding: 0 ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  card: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(1)};
  `,
  contentGroup: css`
    margin-bottom: 16px;
  `,
  treatmentZonesContainer: css`
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
  `,
  switch: css`
    gap: 8px;
    display: flex;
    justify-content: space-between;
    margin: 0;
  `,
  treatmentZoneHeader: css`
    display: flex;
    justify-content: end;
  `,
  treatmentZone: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  treatmentZoneBullet: (color: string) => css`
    z-index: 1000;
    width: 10px;
    height: 10px;
    padding: 8px;
    background-color: ${color};
    box-shadow: inset 0 0 10px black;
    transform: rotate(45deg);
  `,
  treatmentZoneLabel: css`
    flex-grow: 1;
    margin-left: 8px;
    font-size: 14px;
  `
};
