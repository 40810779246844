import { TrialField } from '@mytypes/trial';

export const parseTrialFields = (protocolFields: Record<string, TrialField[]>): TrialField[] => {
  const protocolIdKeys = Object.keys(protocolFields);

  const result = protocolIdKeys.reduce((acc, protocolId) => {
    const list = protocolFields[protocolId];
    return [
      ...acc,
      ...list.map(
        (item: any) =>
          ({
            ...item,
            protocol_id: protocolId
          } as TrialField)
      )
    ];
  }, [] as TrialField[]);
  return result;
};
