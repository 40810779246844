import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UnableAccessDeviceLocationModal } from '@components/UnableAccessDeviceLocationModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useUnableAccessDeviceLocationModal = () => {
  const modalKey = 'unable-access-device-location-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (mode: string, onContinueAnyway?: () => void) => {
      const children = (
        <UnableAccessDeviceLocationModal
          mode={mode}
          onClose={onClose}
          onContinueAnyway={() => {
            onClose();
            onContinueAnyway && onContinueAnyway();
          }}
        />
      );
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
