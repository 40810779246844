/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { FormControl, Paper, TextField, Typography } from '@mui/material';
import { parse } from 'wellknown';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dayjs from 'dayjs';

import { useAppTheme } from '@hooks/useAppTheme';
import { ViewHeader } from '@components/ViewHeader';
import { selectCurrentNote } from '@state-mgmt/slices/notesSlice';
import MapView from '@components/MapView/MapView';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { styles } from './styles';
import { RootState } from '@state-mgmt/store';

const ViewNote = () => {
  const { t } = useTranslation();
  const { growerId, fieldId, protocolId } = useParams();
  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const selectedNote = useSelector(selectCurrentNote);
  const theme = useAppTheme({});
  const navigate = useNavigate();

  const [markers, setMarkers] = useState<any[]>([]);
  const [fieldBoundary, setFieldBoundary] = useState<any | null>(null);

  const [getFieldTablesState, getFieldTables] = useGetFieldTables();

  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  const handleGoBack = () => navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}`);

  useEffect(() => {
    if (clientId && trialId && protocolId && fieldId) {
      getFieldTables(trialId, protocolId, fieldId, true);
    }
  }, [fieldId]);

  useEffect(() => {
    if (selectedNote) {
      let point: { type: string; coordinates: number[] } | null = parse(selectedNote.wkt_point) as { type: string; coordinates: number[] };

      if (point && point.coordinates) {
        setLatitude(point.coordinates[1]);
        setLongitude(point.coordinates[0]);
      }
    } else {
      navigate('/');
    }
  }, [selectedNote]);

  useEffect(() => {
    if (latitude && longitude) {
      setMarkers([{ latitude, longitude, draggable: false }]);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (getFieldTablesState.data) {
      if (getFieldTablesState.data.field) {
        const data = getFieldTablesState.data.field;
        setFieldBoundary(data);
      }
    }
  }, [getFieldTablesState.data]);

  if (!selectedNote) return null;

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('view-field-note.title')} onGoBackBtnPress={handleGoBack} />
      <div css={styles.mapContainer}>
        <LoadingOverlayWrapper
          spinner
          active={getFieldTablesState.isLoading}
          styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
          text={t('view-field-note.loading-message')}
        >
          <MapView fieldBoundary={fieldBoundary} markers={markers} />
        </LoadingOverlayWrapper>
      </div>

      <div css={styles.viewNoteScrollableArea(theme)}>
        <FormControl fullWidth>
          <TextField label={t('view-field-note.note-label')} value={selectedNote.note} disabled={true} type="text" multiline rows={4} />
        </FormControl>
        <div css={styles.infoRow}>
          <Typography color="text.primary" fontSize={14}>
            {t('view-field-note.completed-date-label')}: {Dayjs(selectedNote.date).format('MM/DD/YYYY')}
          </Typography>
        </div>

        {selectedNote && Array.isArray(selectedNote.media) && selectedNote.media.length > 0 ? (
          <div css={styles.photos}>
            {selectedNote.media.map((media: any, i: number) => (
              <div key={i} style={{ backgroundImage: `url(${media.bucket_location})` }} css={styles.thumbnail} />
            ))}
          </div>
        ) : null}
      </div>
    </Paper>
  );
};

export default ViewNote;
