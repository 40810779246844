import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPosition, setWatchId } from '@state-mgmt/slices/appSlice';

export const useGetCurrentLocation = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    const watchId = navigator.geolocation.watchPosition(
      ({ coords }) => {
        dispatch(
          setCurrentPosition({
            latitude: coords.latitude,
            longitude: coords.longitude
          })
        );
      },
      async err => console.error(`Error watching the position: ${err.message}`),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
    dispatch(setWatchId(watchId));
  }, []);
};
