import { QUESTION_TYPE } from '@mytypes/protocol';
import { getLocationValueFromWktPoint } from '@utils/getLocationValueFromWktPoint';

export const parseCharacterizationValueReceived = (values: any, valueType: QUESTION_TYPE) => {
  if (!values.length) {
    return undefined;
  }
  switch (valueType) {
    case QUESTION_TYPE.TEXT:
      return values.at(0).value;
    case QUESTION_TYPE.INTEGER:
      return values.at(0).value.toString();
    case QUESTION_TYPE.DECIMAL:
      return values.at(0).value.toString();
    case QUESTION_TYPE.SELECTION:
      return values.at(0).value;
    case QUESTION_TYPE.MULTI_SELECTION:
      return values.map((val: any) => val.value);
    case QUESTION_TYPE.FLAG:
      return values.at(0).value ? 'yes' : 'no';
    case QUESTION_TYPE.DATE:
      return values.at(0).value;
    case QUESTION_TYPE.UPLOAD:
      return values.map((val: any) => ({ url: val.value, valueId: val.id, remove: false, ...(val.wkt_point && getLocationValueFromWktPoint(val.wkt_point)) }));
    default:
      return undefined;
  }
};
