import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';

import { Protocol, STEP_TYPE } from '@mytypes/protocol';
import { NearbyField, TrialField } from '@mytypes/trial';
import { RootState } from '@state-mgmt/store';
import { setNearbyFields } from '@state-mgmt/slices/trial-slice';
import { getLocationValueFromWktPoint } from '@utils/getLocationValueFromWktPoint';
import { useGetGrower } from './useGetGrower';

type Coordinate = {
  latitude: number;
  longitude: number;
};

const calculateDistance = (coord1: Coordinate, coord2: Coordinate): { km: number; mi: number } => {
  const from = turf.point([coord1.longitude, coord1.latitude]);
  const to = turf.point([coord2.longitude, coord2.latitude]);

  const distanceKm = turf.distance(from, to, { units: 'kilometers' });
  const distanceMi = turf.distance(from, to, { units: 'miles' });

  return { km: distanceKm, mi: distanceMi };
};

const sortCoordinatesByDistance = (nearbyFields: NearbyField[], currentLocation: Coordinate): NearbyField[] => {
  return nearbyFields
    .map(nearbyField => {
      const { km, mi } = calculateDistance(currentLocation, { latitude: nearbyField.latitude, longitude: nearbyField.longitude });
      return { ...nearbyField, distanceKm: km, distanceMi: mi };
    })
    .sort((a, b) => a.distanceKm - b.distanceKm);
};

export const useGetNearbyFields = () => {
  const dispatch = useDispatch();
  const getGrower = useGetGrower();
  const { crops } = useSelector((state: RootState) => state.crop);

  return useCallback(
    (fields: TrialField[], protocols: Protocol[]): any | undefined => {
      const protocolsWithVisitStep = protocols.filter(protocol => protocol.steps.some(step => step.type === STEP_TYPE.FIELD_VISIT)).map(item => item.id);

      const fieldsIds: string[] = [];
      const parsedFields: NearbyField[] = [];
      const filteredFields = fields.filter(({ dropout, protocol_id }) => !dropout && protocolsWithVisitStep.includes(protocol_id));
      filteredFields.forEach(({ field_id, name, owner_id, wkt_point, protocol_id }) => {
        if (!fieldsIds.includes(field_id)) {
          fieldsIds.push(field_id);

          const protocol = protocols.find(({ id }) => id === protocol_id);
          let crop;
          if (protocol) {
            crop = crops.find(({ id }) => id === protocol.crop_id);
          }

          const grower = getGrower(owner_id);
          const { latitude, longitude } = getLocationValueFromWktPoint(wkt_point);
          parsedFields.push({
            cropName: crop?.name,
            fieldId: field_id,
            growerId: owner_id,
            growerName: grower ? `${grower?.first_name} ${grower?.last_name}` : '',
            latitude,
            longitude,
            name
          });
        }
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const currentPosition = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            if (parsedFields) {
              const nearbyFields = sortCoordinatesByDistance(parsedFields, currentPosition);
              dispatch(setNearbyFields(nearbyFields));
            }
          },
          error => {
            console.error('Error al obtener la ubicación: ', error);
            return;
          }
        );
      }
    },
    [crops, dispatch, getGrower]
  );
};
