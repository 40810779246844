import { Trial } from '@mytypes/trial';

export type TrialsGropedByYear = {
  year: Trial[];
};

export const groupTrialsByYear = (trials: Trial[]): TrialsGropedByYear => {
  return trials.reduce((acc: any, trial) => {
    const year = trial.year;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(trial);
    return acc;
  }, {});
};
