import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    border-radius: 0;
  `,
  actionsContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)};
  `,
  actionsBtn: css`
    width: 100px;
  `
};
