import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  draggableContainer: (theme: Theme) => css`
    position: absolute;
    font-size: 13px;
    font-weight: 100;
    padding: 6px ${theme.spacing(2)};
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    text-align: center;
  `
};
