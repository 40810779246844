import { css } from '@emotion/react';

export const styles = {
  actionsContainer: css`
    display: flex;
    position: absolute;
    position: absolute;
    bottom: 0;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  `
};
