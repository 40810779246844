import jwt_decode from 'jwt-decode';

type UserInfo = {
  name: string;
  role: string;
};

export const getUserInfo = (): UserInfo | undefined => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const { data } = jwt_decode(token) as any;
    return { name: data.name, role: data.role };
  }

  return undefined;
};
