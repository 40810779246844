import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { signInApi } from '@services/fdc';

export const useSignIn = () =>
  useAsyncCallback(
    useCallback(async (username: string, password: string) => {
      try {
        const data = await signInApi({ username, password });
        return data;
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
