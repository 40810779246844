/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

import { setSelectedMarker } from '@state-mgmt/slices/visitSlice';
import { SelectionCharacteristicType } from '@components/FieldVisitWizard/SelectionCharacteristicType';
import { NUMBER_TYPE, TextCharacteristicType } from '@components/FieldVisitWizard/TextCharacteristicType';
import { DateCharacteristicType } from '@components/FieldVisitWizard/DateCharacteristicType';
import { FlagCharacteristicType } from '@components/FieldVisitWizard/FlagCharacteristicType';
import { MultipleSelectionCharacteristicType } from '@components/FieldVisitWizard/MultipleSelectionCharacteristicType';
import { UploadCharacteristicType } from '@components/FieldVisitWizard/UploadCharacteristicType';
import { QUESTION_TYPE, Question } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  questions: Question[];
  onAnswersChange: (characterizationId: string, value: any, valueType: QUESTION_TYPE) => void;
  onQuestionExpanded: (characterizationId: string | undefined) => void;
  onReCenterLocationEnabled?: (characterizationId: string) => void;
  onAddLocation?: (characterizationId: string) => void;
}

const Characteristic = ({ questions, onAnswersChange, onQuestionExpanded, onReCenterLocationEnabled, onAddLocation }: Props) => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const [questionsOrdered, setQuestionsOrdered] = useState<Question[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { selectedMarker } = useSelector((state: RootState) => state.visit);
  const questionsRefs = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    if (selectedMarker && questions.find(q => q.characterization_id === selectedMarker)) {
      setExpanded(selectedMarker);
      dispatch(setSelectedMarker(undefined));
      const index = questionsRefs.current.findIndex(ref => ref && ref.id === selectedMarker);
      if (index !== -1) {
        scrollToItem(index);
      }
    }
  }, [selectedMarker]);

  useEffect(() => {
    const copy = [...questions];
    setQuestionsOrdered(copy.sort((a: Question, b: Question) => a.order.toString().localeCompare(b.order.toString(), undefined, { numeric: true })));
  }, [questions]);

  const scrollToItem = (index: number) => {
    try {
      if (questionsRefs.current[index]) {
        questionsRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleChange = (characterizationId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    try {
      onQuestionExpanded(isExpanded ? characterizationId : undefined);
      setExpanded(isExpanded ? characterizationId : false);
    } catch (error) {
      showBoundary(error);
    }
  };

  const checkIfHasAnswers = (value: any, valueType: QUESTION_TYPE) => {
    try {
      if (valueType === QUESTION_TYPE.UPLOAD) {
        if (!value?.length) {
          return false;
        }
        if (Array.isArray(value) && value.every((v: any) => v.remove)) {
          return false;
        }
        return true;
      }
      return !!value;
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={styles.container}>
      {questionsOrdered.map(({ characterization_id, label, valueType, required, value, options, longitude, latitude }, index) => {
        return (
          <Accordion
            id={characterization_id}
            key={characterization_id}
            expanded={expanded === characterization_id}
            onChange={handleChange(characterization_id)}
            ref={element => {
              // @ts-ignore
              questionsRefs.current[index] = element;
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} css={styles.header}>
              {checkIfHasAnswers(value, valueType) && <CheckIcon css={styles.headerIndicator} color="success" fontSize="small" />}
              {!value && required && <span css={styles.headerIndicator}>*</span>}
              {(!value || value.length === 0) && !required && <span css={styles.headerIndicator} />}
              <Typography css={styles.headerText}>{label}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {valueType === QUESTION_TYPE.TEXT && (
                <TextCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.INTEGER && (
                <TextCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  number={true}
                  numberType={NUMBER_TYPE.INTEGER}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.DECIMAL && (
                <TextCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  number={true}
                  numberType={NUMBER_TYPE.DECIMAL}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.DATE && (
                <DateCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.FLAG && (
                <FlagCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.MULTI_SELECTION && (
                <MultipleSelectionCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  options={options || []}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onReCenterLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.SELECTION && (
                <SelectionCharacteristicType
                  hasLocation={!!longitude && !!latitude}
                  name={characterization_id}
                  options={options || []}
                  position={!!longitude && !!latitude ? { longitude, latitude } : undefined}
                  value={value}
                  onAddLocation={() => onAddLocation && onAddLocation(characterization_id)}
                  onChange={answer => onAnswersChange(characterization_id, answer, valueType)}
                  onEditLocationEnabled={() => onReCenterLocationEnabled && onReCenterLocationEnabled(characterization_id)}
                />
              )}

              {valueType === QUESTION_TYPE.UPLOAD && (
                <UploadCharacteristicType value={value} onChange={answer => onAnswersChange(characterization_id, answer, valueType)} />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Characteristic;
