/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Paper, TextField, Button } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { v4 as uuidv4 } from 'uuid';

import { ToastType } from '@components/ui/Toast';
import { ScreenshootViewModal } from '@components/ScreenshootViewModal';
import { ViewHeader } from '@components/ViewHeader';
import { useCreateHelpDeskTicket } from '@hooks/useCreateHelpDeskTicket';
import { useToast } from '@hooks/app/useToast';
import { useAppTheme } from '@hooks/useAppTheme';
import { resizeImage } from '@utils/resizeImage';
import { styles } from './styles';

const CreateHelpDeskTicket = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const message = searchParams.get('message');
  const [description, setDescription] = useState<string | undefined>(message || undefined);
  const [thumbs, setThumbs] = useState<any[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedScreenshot, setSelectedScreenshot] = useState<any>();

  const [createHelpDeskTicketState, createHelpDeskTicket] = useCreateHelpDeskTicket();
  const showToast = useToast();
  const galleryInputRef = useRef<HTMLInputElement>(null);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (createHelpDeskTicketState.data && createHelpDeskTicketState.data.success) {
      showToast({ type: ToastType.SUCCESS, children: t('create-help-desk-ticket.success-message') });
      if (message) {
        navigate('/');
      } else {
        navigate('/profile');
      }
    }
  }, [createHelpDeskTicketState.data]);

  const onSubmitBtnClicked = () => {
    if (description) {
      createHelpDeskTicket(description, files);
    }
  };

  const addSrc = async (e: any) => {
    try {
      const rawFiles: File[] = [];
      const images: any = [];

      for (const file of e.target.files) {
        if (file) {
          const uuid = uuidv4();
          const newFileName = `${uuid}.${file.name.split('.').pop()}`;

          const renamedFile = new File([file], newFileName, {
            type: file.type,
            lastModified: file.lastModified
          });

          file['id'] = uuid;
          rawFiles.push(renamedFile);

          const image = await resizeImage(file, '720p');
          images.push({ image, id: uuid });
        }
      }
      setFiles([...files, ...rawFiles]);
      setThumbs([...thumbs, ...images]);
    } catch (error) {
      showBoundary(error);
    }
  };

  const onScreenshootDeleted = (id: string) => {
    setThumbs(thumbs.filter(thumb => thumb.id !== id));
    setFiles(files.filter(file => !file.name.includes(id)));
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('create-help-desk-ticket.title')} onGoBackBtnPress={() => (message ? navigate('/') : navigate('/profile'))} />

      <LoadingOverlay
        spinner
        active={createHelpDeskTicketState.isLoading}
        text={t('create-help-desk-ticket.submiting-message')}
        styles={{ wrapper: styles.loadingOverlayContent }}
      >
        <div css={styles.content}>
          <TextField
            rows={4}
            value={description}
            label={t('create-help-desk-ticket.description-label')}
            multiline
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={evt => setDescription(evt.target.value)}
            helperText={t('create-help-desk-ticket.description-help')}
          />
          <div>
            <Button variant="outlined" onClick={() => galleryInputRef.current?.click()} css={styles.uploadBtn}>
              {t('create-help-desk-ticket.upload-button')}
            </Button>
            <input ref={galleryInputRef} type="file" accept="image/*" id="gallery" multiple css={styles.inputget} onChange={addSrc} />

            <div css={styles.gallery}>
              {thumbs.map(({ image, id }) => (
                <div key={id} style={{ backgroundImage: `url(${image})` }} css={[styles.thumbnail]} onClick={() => setSelectedScreenshot({ id, src: image })} />
              ))}
            </div>
          </div>
        </div>
        <div css={styles.actionsContainer}>
          <Button variant="contained" onClick={onSubmitBtnClicked} disabled={!description}>
            {t('create-help-desk-ticket.upload-button')}
          </Button>
        </div>
      </LoadingOverlay>

      <ScreenshootViewModal
        open={!!selectedScreenshot}
        image={selectedScreenshot}
        onClose={() => setSelectedScreenshot(undefined)}
        onScreenshootDeleted={onScreenshootDeleted}
      />
    </Paper>
  );
};

export default CreateHelpDeskTicket;
