import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background-color: #567952;
    padding: 4px 6px;
    font-size: 12px;
    font-weight: 100;
  `,
  switchBtn: css`
    padding: 0;
    font-size: 12px;
    color: #fff;
    border-color: #fff;
    font-weight: 100;
  `
};
