import { Page } from '@mytypes/protocol';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@state-mgmt/store';

export type VisitState = {
  activeZones: any[];
  changedCharacteristic: string | undefined;
  fieldBoundary: any;
  pages: Page[];
  previousVisitsSelection: any[];
  selectedMarker: string | undefined;
};

const initialState: VisitState = {
  activeZones: [],
  changedCharacteristic: undefined,
  fieldBoundary: undefined,
  pages: [],
  previousVisitsSelection: [],
  selectedMarker: undefined
};

export const visitSlice = createSlice({
  name: 'visit',
  initialState,
  reducers: {
    setActiveZones(state: VisitState, action: PayloadAction<any>) {
      state.activeZones = action.payload;
    },
    setFieldBoundary(state: VisitState, action: PayloadAction<any>) {
      state.fieldBoundary = action.payload;
    },
    setChangedCharacteristic(state: VisitState, action: PayloadAction<string | undefined>) {
      state.changedCharacteristic = action.payload;
    },
    setPages(state: VisitState, action: PayloadAction<Page[]>) {
      state.pages = action.payload;
    },
    setPreviousVisitsSelection(state: VisitState, action: PayloadAction<any>) {
      state.previousVisitsSelection = action.payload;
    },
    setSelectedMarker(state: VisitState, action: PayloadAction<any>) {
      state.selectedMarker = action.payload;
    }
  }
});

// Actions
export const { setActiveZones, setFieldBoundary, setChangedCharacteristic, setPages, setPreviousVisitsSelection, setSelectedMarker } = visitSlice.actions;

// Selectors
export const selectActiveZones = (state: RootState) => state.visit.activeZones;
export const selectFieldBoundary = (state: RootState) => state.visit.fieldBoundary;
export const selectChangedCharacteristic = (state: RootState) => state.visit.changedCharacteristic;
export const selectPages = (state: RootState) => state.visit.pages;
export const selectPreviousVisitsSelection = (state: RootState) => state.visit.previousVisitsSelection;
export const selectSelectedMarker = (state: RootState) => state.visit.selectedMarker;

export default visitSlice.reducer;
