import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  // container: (theme: Theme) => css`
  container: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  `,

  header: css`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 16px;

    padding: 8px 16px 0 16px;
  `,
  number: css`
    font-size: 28px;
    overflow: scroll;
  `,
  doneBtn: css`
    background-color: #06b7ff;
    color: #161616;
    border: none;
    padding: 8px 16px;
    font-size: 18px;
    height: 43px;
  `,
  numbs: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 12px 16px 30px 16px;
    grid-gap: 8px;
    border-radius: 2px;
    overflow: hidden;
  `,
  button: css`
    background-color: #949494;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 18px;
    height: 43px;
  `,
  extraButton: css`
    margin-top: 16px;
  `,
  hidden: css`
    display: none;
  `
};
