/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { TextField, Button, Paper, Typography } from '@mui/material';

import logoDark from '@assets/images/logo-dark.png';
import logoLight from '@assets/images/logo-light.png';
import { useForgotPassword } from '@hooks/useForgotPassword';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import { styles } from './styles';
import { useForm } from 'react-hook-form';

let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type FormData = {
  email: string;
};

const VerifyEmail = () => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [forgotPasswordState, forgotPassword] = useForgotPassword();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setError
  } = useForm<FormData>();

  const onSubmitHandler = async ({ email }: FormData) => {
    forgotPassword(email);
  };

  const validateEmail = (value: string) => {
    return !!value.match(emailRegex) || t('verify-email.email-not-valid-message');
  };

  const validateRequired = (value: string) => {
    return value.trim() !== '' || t('verify-email.email-required-message');
  };

  useEffect(() => {
    if (forgotPasswordState.data) {
      if (forgotPasswordState.data?.error) {
        setError('root', { type: 'custom', message: t('verify-email.email-not-found-message') });
        setSuccess(false);
      }
      if (forgotPasswordState.data?.result === 'Success') {
        setSuccess(true);
      }
    }
  }, [forgotPasswordState.data]);

  return (
    <LoadingOverlay spinner active={forgotPasswordState.isLoading} text={t('verify-email.loading')}>
      <Paper elevation={2} css={styles.container(theme)}>
        <div css={styles.logoContainer}>
          <img src={themeMode === THEME_MODE.DARK ? logoDark : logoLight} alt="logo" css={styles.logo} />
        </div>
        <form onSubmit={handleSubmit(onSubmitHandler)} css={styles.form}>
          <Typography color="text.primary" css={styles.title}>
            {t('verify-email.title')}
          </Typography>

          <Typography color="text.primary" css={styles.subtitle}>
            {t('verify-email.subtitle')}
          </Typography>

          <TextField
            id="email"
            label={t('verify-email.email-label')}
            margin="normal"
            fullWidth
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{ className: 'intentInput' }}
            {...register('email', {
              validate: {
                required: validateRequired,
                valid: validateEmail
              }
            })}
          />

          <Button type="submit" variant="contained" color="primary" size="large" disabled={!isValid} css={styles.sendBtn}>
            {t('verify-email.instructions-button')}
          </Button>

          <Button type="submit" variant="text" onClick={() => navigate('/signin')}>
            {t('verify-email.return-button')}
          </Button>

          {errors['root'] && <h3 css={styles.errorMessage}>{errors['root'].message}</h3>}

          {success && <h3 css={styles.sucessMessage}> {t('verify-email.success-message')}</h3>}
        </form>
      </Paper>
    </LoadingOverlay>
  );
};

export default VerifyEmail;
