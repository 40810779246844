import * as turf from '@turf/turf';
// @ts-ignore
import randomPointsOnPolygon from 'random-points-on-polygon';

import { Position } from '@mytypes/map';

const getRandomFactor = () => {
  const factor = Math.floor(Math.random() * 9) + 1;
  const sign = Math.random() < 0.5 ? -1 : 1;
  return sign * (factor / 10000);
};

export const generateFakeCoords = (polygon: turf.Polygon, numberOfCoords: number = 0): Position[] => {
  const polygonObject = turf.polygon(polygon.coordinates);
  const points = randomPointsOnPolygon(numberOfCoords, polygonObject);

  const result = points.reduce((acc: Position[], curr: any) => {
    const { coordinates } = curr.geometry;
    const [longitude, latitude] = coordinates;
    acc.push({ longitude: longitude + getRandomFactor(), latitude: latitude + getRandomFactor() });
    return acc;
  }, []);

  return result;
};
