/** @jsxImportSource @emotion/react */
import { Button, ButtonGroup } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import { styles } from './styles';

export interface Props {
  disablePreviusBtn: boolean;
  disableNextBtn: boolean;
  onPreviusBtnClicked: () => void;
  onNextBtnClicked: () => void;
}

const GpsSimulationControls = ({ disablePreviusBtn, disableNextBtn, onPreviusBtnClicked, onNextBtnClicked }: Props) => {
  return (
    <div css={styles.container}>
      <ButtonGroup variant="contained" aria-label="gps simulation controls">
        <Button disabled={disablePreviusBtn} onClick={onPreviusBtnClicked}>
          <SkipPreviousIcon />
        </Button>
        <Button disabled={disableNextBtn} onClick={onNextBtnClicked}>
          <SkipNextIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default GpsSimulationControls;
