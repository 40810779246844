import { css } from '@emotion/react';

export const styles = {
  icon: () => css`
    width: 40px;
  `,
  noPadding: () => css`
    padding: 0;
  `
};
