/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogTitle } from '@mui/material';

import MapView from '@components/MapView/MapView';
import { Position } from '@mytypes/map';
import { RootState } from '@state-mgmt/store';
import { setChangedCharacteristic, setPages } from '@state-mgmt/slices/visitSlice';
import { styles } from './styles';

export interface Props {
  characterizationId: string;
  position?: Position;
  onClose: () => void;
}

const SetLocationModal = ({ characterizationId, position, onClose }: Props) => {
  const { t } = useTranslation();

  const { themeMode } = useSelector((state: RootState) => state.app);
  const { fieldBoundary } = useSelector((state: RootState) => state.visit);
  const [newPosition, setNewPosition] = useState<Position>();
  const { pages } = useSelector((state: RootState) => state.visit);
  const dispatch = useDispatch();

  const handleOnOkBtnClicked = () => {
    if (newPosition) {
      const newPages = pages.map(page => {
        let questions = page.questions;
        questions = questions.map(question =>
          question.characterization_id === characterizationId ? { ...question, longitude: newPosition.longitude, latitude: newPosition.latitude } : question
        );
        return { ...page, questions };
      });
      dispatch(setChangedCharacteristic(characterizationId));
      dispatch(setPages(newPages));
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle sx={{ textAlign: 'center' }}>{t('set-location.title')}</DialogTitle>
      <div css={styles.content}>
        <div css={styles.mapContainer}>
          {fieldBoundary && (
            <MapView
              position={position}
              fieldBoundary={fieldBoundary}
              showCurrentPosition={true}
              showCenterPin={true}
              disableFlyToAnimation={true}
              disableZoom={true}
              initialZoom={16}
              onMapDrag={newPosition => setNewPosition(newPosition)}
            />
          )}
        </div>
      </div>
      <DialogActions css={styles.actionsContainer(themeMode)}>
        <Button variant="outlined" size="large" css={styles.actionButton} onClick={onClose}>
          {t('general.cancel')}
        </Button>
        <Button variant="contained" size="large" css={styles.actionButton} onClick={handleOnOkBtnClicked}>
          {t('general.ok')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default SetLocationModal;
