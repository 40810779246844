import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getFieldVisitSteps } from '@utils/getFieldVisitSteps';

export const useGetFieldVisitsSteps = () => {
  return useAsyncCallback(
    useCallback(async (trialId: string, protocolId: string, fieldId: string) => {
      try {
        return await getFieldVisitSteps(trialId, protocolId, fieldId);
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
};
