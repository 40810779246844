import { css } from '@emotion/react';

import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const COLORS = {
  light: {
    navigator: {
      background: '#f1f1f1',
      syncEnabled: '#fe9d10',
      button: {
        textDiabled: '#b3b3b3'
      }
    }
  },
  dark: {
    navigator: {
      background: '#1f2128',
      syncEnabled: '#ffa726',
      button: {
        text: '#bdbdc0',
        textDiabled: '#9e9e9e'
      }
    }
  }
};

export const styles = (themeMode: THEME_MODE) => css`
  .intentInput {
    background-color: ${themeMode === THEME_MODE.DARK ? 'rgba(25, 26, 31, 0.5)' : 'rgba(25, 26, 31, 0.1)'};
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 60px ${themeMode === THEME_MODE.DARK ? '#1f1f25' : '#d3d3d3'} inset !important;
      background-color: ${themeMode === THEME_MODE.DARK ? '#1f1f25' : '#d3d3d3'} !important;
      background-clip: content-box !important;
      color: ${themeMode === THEME_MODE.DARK ? '#fff' : '#000'} !important;
    }
  }
`;
