/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Card, CardActions, CardContent, List, MenuItem, Paper, TextField, Typography } from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import MapIcon from '@mui/icons-material/Map';
import WarningIcon from '@mui/icons-material/Warning';

import { ViewHeader } from '@components/ViewHeader';
import { GrowerFieldsModal } from '@components/GrowerFieldsModal';
import { useGetGrower } from '@hooks/useGetGrower';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetClientUsers } from '@hooks/useGetClientUsers';
import { GrowerSelectOption } from '@mytypes/grower';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const Enrollment = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { clientId } = useSelector((state: RootState) => state.app);
  const { fields } = useSelector((state: RootState) => state.trial);
  const { currentProtocol } = useSelector((state: RootState) => state.protocol);
  const [selectedGrower, setSelectedGrower] = useState<GrowerSelectOption | undefined>();
  const [openGrowerFieldsModal, setOpenGrowerFieldsModal] = useState<boolean>(false);
  const [showBy, setShowBy] = useState<string>('enrolled');
  const getGrower = useGetGrower();
  const [data, setData] = useState<any[]>();
  const [getClientMembersState, getClientMembers] = useGetClientUsers();
  const [growerId, setGrowerId] = useState<string | undefined>();

  useEffect(() => {
    if (!clientId) return;
    getClientMembers(clientId);
  }, []);

  useEffect(() => {
    if (getClientMembersState.data) {
      const processData = getClientMembersState.data.map(row => {
        const { status, fieldsEnrolled } = getGrowerStatus(row.user_id);
        if (showBy === 'enrolled' && ['enrolled', 'enroll-not-complete'].includes(status)) {
          return { ...row, fullname: `${row.first} ${row.last}`, status, fieldsEnrolled };
        }
        if (showBy === 'unenrolled' && ['enroll', 're-enroll'].includes(status)) {
          return { ...row, fullname: `${row.first} ${row.last}`, status };
        }
        if (showBy === 'all') {
          return { ...row, fullname: `${row.first} ${row.last}`, status };
        }
      });
      processData.sort((a: any, b: any) => a.fullname.localeCompare(b.fullname));
      setData(processData.filter((row: any) => row));
    }
  }, [getClientMembersState.isSuccess, showBy]);

  const getGrowerStatus = (growerId: string) => {
    let growerProtocols = getGrower(growerId)?.protocol_ids;
    if (growerProtocols?.length) {
      if (currentProtocol) {
        growerProtocols = growerProtocols?.filter(gp => gp.protocol_id === currentProtocol);
      }

      if (growerProtocols.every(gp => gp.dropout)) {
        return { status: 're-enroll' };
      }

      let fieldsEnrolled = fields.filter(field => field.owner_id === growerId);
      if (fieldsEnrolled.length) {
        return { status: 'enrolled', fieldsEnrolled: fieldsEnrolled.length };
      } else {
        return { status: 'enroll-not-complete' };
      }
    } else {
      return { status: 'enroll' };
    }
  };

  const onShowByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setShowBy(value);
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('enrollment.title')} hideGoBackBtn={true} showRefreshBtn={true} />
      <div css={styles.scrollableArea(theme)}>
        {/* Status Filter */}
        <TextField select fullWidth label={t('enrollment.status-filter-label')} value={showBy} onChange={onShowByChange} css={styles.filter(theme)}>
          <MenuItem key="Enrolled" value="enrolled">
            {t('enrollment.enrolled-status-label')}
          </MenuItem>
          <MenuItem key="unenrolled" value="unenrolled">
            {t('enrollment.unenrolled-status-label')}
          </MenuItem>
          <MenuItem key="all" value="all">
            {t('general.all')}
          </MenuItem>
        </TextField>

        {/* Grower Filter */}
        <Autocomplete
          options={data?.map(({ user_id, first, last }) => ({ id: user_id, label: first + ' ' + last })) || []}
          renderInput={params => <TextField {...params} label={t('enrollment.grower-filter-label')} />}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          onChange={(event: any, newValue) => setSelectedGrower(newValue ? newValue : undefined)}
          isOptionEqualToValue={option => option.id === selectedGrower?.id}
          css={styles.growerFilter(theme)}
        />

        <LoadingOverlay
          spinner
          active={getClientMembersState.isLoading}
          text={t('enrollment.loading-message')}
          styles={{ wrapper: styles.listLoadingOverlayContent }}
        >
          <List>
            {data?.map(({ user_id, first, last, email, status, fieldsEnrolled }) => {
              return !selectedGrower || (selectedGrower && selectedGrower.id === user_id) ? (
                <Card key={user_id} elevation={0} css={styles.userCard(theme)}>
                  <div css={styles.cardHeader(theme)}>
                    <Typography color="text.primary">{first + ' ' + last}</Typography>
                  </div>

                  <CardContent css={styles.cardContent}>
                    <Typography color="text.secondary" css={styles.email}>
                      {email}
                    </Typography>
                  </CardContent>
                  <CardActions css={styles.cardActions}>
                    {status === 'enroll' && (
                      <Button size="small" onClick={() => navigate(`/enrollment/new?grower_id=${user_id}`)}>
                        {t('enrollment.enroll-button')}
                      </Button>
                    )}
                    {status === 're-enroll' && (
                      <Button
                        size="small"
                        onClick={() => {
                          setGrowerId(user_id);
                          setOpenGrowerFieldsModal(true);
                        }}
                      >
                        {t('enrollment.re-enroll-button')}
                      </Button>
                    )}
                    {status === 'enrolled' && (
                      <Button
                        size="small"
                        startIcon={<MapIcon />}
                        onClick={() => {
                          setGrowerId(user_id);
                          setOpenGrowerFieldsModal(true);
                        }}
                      >
                        {t('enrollment.view-enrolled-fields-button', { fields: fieldsEnrolled })}
                      </Button>
                    )}
                    {status === 'enroll-not-complete' && (
                      <Button
                        size="small"
                        startIcon={<WarningIcon />}
                        onClick={() => {
                          setGrowerId(user_id);
                          setOpenGrowerFieldsModal(true);
                        }}
                      >
                        {t('enrollment.enrolled-not-complete-status-button')}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              ) : (
                <></>
              );
            })}
          </List>
        </LoadingOverlay>
      </div>
      <Button css={styles.enrollBtn(theme)} variant="outlined" startIcon={<PersonAddAlt1OutlinedIcon />} onClick={() => navigate('/enrollment/new')}>
        {t('enrollment.enroll-a-new-grower-button')}
      </Button>

      <GrowerFieldsModal
        growerId={growerId}
        open={openGrowerFieldsModal}
        onClose={needToRefresh => {
          setOpenGrowerFieldsModal(false);
          if (needToRefresh) {
            navigate('/splash?redirectTo=/enrollment');
          }
        }}
      />
    </Paper>
  );
};

export default Enrollment;
