import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: 100%;
    padding: 0 ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  scrollableArea: (theme: Theme) => css`
    width: 100%;
    height: 100%;
    padding: 0 ${theme.spacing(2)};
  `,
  loadingOverlayContent: css`
    position: relative;
    height: 100%;
  `,
  title: () => css`
    margin-bottom: 6px;
  `,
  form: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
  `
};
