/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Card,
  CardActions,
  CardContent,
  Typography,
  Slider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  MenuItem
} from '@mui/material';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { useExportLocalData } from '@hooks/app/useExportLocalData';
import { useSwitchClientModal } from '@hooks/modals/useSwitchClientModal';
import { RootState } from '@state-mgmt/store';
import { setNearbyFieldsRatioDistance, setNearbyFieldsRatioUOM, setOffSite, setSimulateGPS, setThemeMode, THEME_MODE } from '@state-mgmt/slices/appSlice';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { getUserInitialsName } from '@utils/getUserInitialsName';
import { clearAppData } from '@utils/clearAppData';
import { getUserInfo } from '@utils/getUserInfo';
import { styles } from './styles';
import config from '../../../config';

const minRatio = 10;
const maxRatio = 100;

const UserProfile = () => {
  const { i18n, t } = useTranslation();

  const theme = useAppTheme({});
  const appVersion = config.REACT_APP_APP_VERSION;
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();
  const exportLocalData = useExportLocalData();
  const displaySwitchClientModal = useSwitchClientModal();

  const [username, setUsername] = useState<string>();
  const [userRole, setUserRole] = useState<string>();

  const { clientId, nearbyFieldsRatioDistance, nearbyFieldsRatioUOM, offSite, simulateGPS, themeMode } = useSelector((state: RootState) => state.app);
  const { clients } = useSelector((state: RootState) => state.client);
  const [sliderValue, setSliderValue] = useState<number>(nearbyFieldsRatioDistance);

  const userClient = useMemo(() => clients.find(client => client.id === clientId), [clients]);

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = getUserInfo();
    if (userInfo) {
      setUsername(userInfo.name);
      setUserRole(userInfo.role);
    }
  }, []);

  const userInitials = useMemo(() => username && getUserInitialsName(username), [username]);

  const handleLogoutBtnClicked = () => {
    localStorage.removeItem('clientId');
    localStorage.removeItem('trialId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/signin');
  };

  const handleOnHardRefreshBtnClicked = async () => {
    try {
      await clearAppData();
      window.location.href = `/?nocache=${Math.random()}`;
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnExploreBtnClicked = () => {
    try {
      window.open(config.REACT_APP_INVISION_LEARNING_GUIDE_URL, '_blank');
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnSubmitBtnClicked = () => {
    try {
      navigate('/support');
    } catch (error) {
      showBoundary(error);
    }
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('profile.title')} hideGoBackBtn={true} showRefreshBtn={false} />

      <div css={styles.scrollableArea(theme)}>
        <div css={styles.avatarContainer}>
          <Avatar css={styles.avatar}>
            <Typography variant="h3">{userInitials}</Typography>
          </Avatar>
          <h2 css={styles.username}>{username || ''}</h2>
        </div>

        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent css={styles.cardContent}>
            <Typography variant="h6" component="div">
              {userClient?.name}
            </Typography>
            <Typography css={styles.userrole(theme)}>{userRole || ''}</Typography>
          </CardContent>
          <CardActions css={styles.cardActions}>
            <Button size="small" onClick={() => displaySwitchClientModal()}>
              {t('profile.switch-client-button')}
            </Button>
          </CardActions>
        </Card>

        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent>
            <Typography variant="h6" component="div">
              {t('profile.settings-section.title')}
            </Typography>

            <FormControlLabel
              css={styles.switch}
              control={
                <Switch
                  checked={themeMode === THEME_MODE.LIGHT}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    localStorage.setItem('themeMode', event.target.checked ? '1' : '0');
                    dispatch(setThemeMode(event.target.checked ? THEME_MODE.LIGHT : THEME_MODE.DARK));
                  }}
                />
              }
              label={
                <Typography color="text.secondary" css={styles.switchLabel(theme)}>
                  {t('profile.settings-section.theme-light-mode-switch-label')}
                </Typography>
              }
              labelPlacement="start"
            />

            <FormControlLabel
              css={styles.switch}
              control={
                <Switch
                  checked={simulateGPS}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    localStorage.setItem('simulateGPS', event.target.checked ? '1' : '0');
                    dispatch(setSimulateGPS(event.target.checked));
                  }}
                />
              }
              label={
                <Typography color="text.secondary" css={styles.switchLabel(theme)}>
                  {t('profile.settings-section.simulate-gps-switch-label')}
                </Typography>
              }
              labelPlacement="start"
            />

            <FormControlLabel
              css={styles.switch}
              control={
                <Switch
                  checked={offSite}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    localStorage.setItem('offsite', event.target.checked ? '1' : '0');
                    dispatch(setOffSite(event.target.checked));
                  }}
                />
              }
              label={
                <Typography color="text.secondary" css={styles.switchLabel(theme)}>
                  {t('profile.settings-section.offsite-switch-label')}
                </Typography>
              }
              labelPlacement="start"
            />

            <TextField
              select
              fullWidth
              label={t('profile.settings-section.language-switch-label')}
              onChange={evt => {
                localStorage.setItem('language', evt.target.value);
                changeLanguage(evt.target.value);
              }}
              value={localStorage.getItem('language') || 'en'}
              sx={{ marginTop: '16px' }}
            >
              <MenuItem value="en" defaultChecked>
                English
              </MenuItem>
              <MenuItem value="pt" defaultChecked>
                Portuguese
              </MenuItem>
              <MenuItem value="es" defaultChecked>
                Español
              </MenuItem>
            </TextField>
          </CardContent>
        </Card>

        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent>
            <Typography variant="h6" component="div">
              {t('profile.nearby-fields-ratio.title')}
            </Typography>

            <div style={{ padding: '20px', marginTop: '8px' }}>
              <Slider
                defaultValue={25}
                min={minRatio}
                max={maxRatio}
                marks={[
                  { value: minRatio, label: '10' },
                  {
                    value: maxRatio,
                    label: '100'
                  }
                ]}
                valueLabelDisplay="on"
                color="secondary"
                value={sliderValue}
                onChange={(evt, value) => setSliderValue(value as number)}
                onChangeCommitted={(evt, value) => {
                  localStorage.setItem('nearbyFieldsRatioDistance', sliderValue.toString());
                  dispatch(setNearbyFieldsRatioDistance(sliderValue));
                }}
              />
            </div>

            <FormControl fullWidth>
              <FormLabel> {t('profile.nearby-fields-ratio.unit-label')}</FormLabel>
              <RadioGroup
                row
                name="controlled-radio-buttons-group"
                value={nearbyFieldsRatioUOM}
                onChange={(evt, value) => {
                  localStorage.setItem('nearbyFieldsRatioUOM', value);
                  dispatch(setNearbyFieldsRatioUOM(value));
                }}
                sx={{ justifyContent: 'space-evenly' }}
              >
                <FormControlLabel value="miles" control={<Radio />} label={t('profile.nearby-fields-ratio.unit-miles')} />
                <FormControlLabel value="kilometers" control={<Radio />} label={t('profile.nearby-fields-ratio.unit-kilometers')} />
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Card>

        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent css={styles.cardContent}>
            <Typography variant="h6" component="div">
              {t('profile.quick-help-section.title')}
            </Typography>

            <div css={styles.cardSubContent(theme)}>
              <Typography component="div" css={styles.cardSubContentTitle}>
                {t('profile.quick-help-section.knowledge-base.title')}
              </Typography>
              <Typography component="p" css={styles.cardSubContentText}>
                {t('profile.quick-help-section.knowledge-base.text')}
              </Typography>
              <div css={styles.cardSubContentRightBtn}>
                <Button variant="contained" size="small" onClick={handleOnExploreBtnClicked}>
                  {t('profile.quick-help-section.knowledge-base.button')}
                </Button>
              </div>
            </div>

            <div css={styles.cardSubContent(theme)}>
              <Typography component="div" css={styles.cardSubContentTitle}>
                {t('profile.quick-help-section.submit-ticket.title')}
              </Typography>
              <Typography component="p" css={styles.cardSubContentText}>
                {t('profile.quick-help-section.submit-ticket.text')}
              </Typography>
              <div css={styles.cardSubContentRightBtn}>
                <Button variant="contained" size="small" onClick={handleOnSubmitBtnClicked}>
                  {t('profile.quick-help-section.submit-ticket.button')}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent css={styles.cardContent}>
            <Typography variant="h6" component="div">
              {t('profile.app-version-section.title')}: {appVersion}
            </Typography>
          </CardContent>

          <CardActions css={styles.cardActions}>
            <Button size="small" onClick={() => exportLocalData()}>
              {t('profile.app-version-section.export-local-data-button')}
            </Button>

            <Button size="small" onClick={handleOnHardRefreshBtnClicked}>
              {t('profile.app-version-section.hard-refresh-button')}
            </Button>
          </CardActions>
        </Card>
      </div>

      <Button variant="contained" css={styles.logoutBtn(theme)} onClick={handleLogoutBtnClicked}>
        {t('profile.logout-button')}
      </Button>
    </Paper>
  );
};

export default UserProfile;
