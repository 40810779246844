import { css } from '@emotion/react';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    display: flex;
    flex-direction: column;
  `,
  content: css`
    flex-grow: 2;
  `,
  mapContainer: css`
    height: 100%;
  `,
  actionsContainer: (themeMode: THEME_MODE) => css`
    ${themeMode === THEME_MODE.DARK ? '#1F2128' : '#fff'};
    padding: 16px;
    justify-content: space-between;
  `,
  actionButton: css`
    width: 40%;
  `
};
