import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';
import '@fontsource/plus-jakarta-sans';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import './i18n';
import { AppRouter } from '@components/AppRouter';
import { useOnlineStatus } from '@hooks/useOnlineStatus';
import store from '@state-mgmt/store';
import { setOnline } from '@state-mgmt/slices/appSlice';
import config from './config';
import { useTranslation } from 'react-i18next';

Sentry.init({
  dsn: 'https://f49ea1d158e94b7c89328542bb5742e5@o450992.ingest.sentry.io/4505199549022208',

  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes)
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

Sentry.setTag('app_version', config.REACT_APP_APP_VERSION);

const App = () => {
  const onlineStatus = useOnlineStatus();
  const { i18n } = useTranslation();

  useEffect(() => {
    store.dispatch(setOnline(onlineStatus));
  }, [onlineStatus]);

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
