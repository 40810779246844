import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FieldVisitWIPModal } from '@components/FieldVisitWIPModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';
import { LocalVisitProgress } from '@mytypes/local';

export const useFieldVisitWIPModal = () => {
  const modalKey = 'field-visit-wip-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (LocalVisitProgress: LocalVisitProgress) => {
      const children = <FieldVisitWIPModal onClose={onClose} visitProgress={LocalVisitProgress} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
