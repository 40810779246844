import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SyncVisitsModal } from '@components/SyncVisitsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useSyncVisitsModal = () => {
  const modalKey = 'sync-visits-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(() => {
    const children = <SyncVisitsModal onClose={onClose} />;
    dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
  }, [dispatch, onClose]);

  return { displayModal };
};
