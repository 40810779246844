import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(2)};
    height: 100vh;
  `,
  logoContainer: css`
    display: flex;
    width: 80%;
    padding: 20px 0;
  `,
  logo: css`
    width: 100%;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    width: 80%;
  `,
  showPasswordIcon: css`
    margin-left: 3px;
  `,
  loginBtn: css`
    margin: '20px 0';
  `,
  errorMessage: css`
    color: #ec6363;
    font-size: 14px;
    font-weight: 100;
  `,
  question: (theme: Theme) => css`
    font-size: 12px;
    margin-top: ${theme.spacing(2)};
  `,
  verifyBtn: css`
    margin: 10px 0;
    width: 80%;
  `
};
