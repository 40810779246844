/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { clearFieldVisitProgressWithDexie } from '@services/local';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onCancel: () => void;
  onClose: () => void;
}

const LostChangesVisitModal = ({ onCancel, onClose }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const { themeMode } = useSelector((state: RootState) => state.app);

  const onCancelAndLoseProgressBtnClicked = async () => {
    try {
      await clearFieldVisitProgressWithDexie();
      onClose();
      onCancel();
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('lost-changes-visit.title')}</DialogTitle>

      <DialogContent>
        <Typography css={styles.title}>{t('lost-changes-visit.text')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancelAndLoseProgressBtnClicked}>{t('lost-changes-visit.cancel-button')}</Button>
        <Button onClick={() => onClose()} variant="contained" autoFocus>
          {t('general.continue')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default LostChangesVisitModal;
