const regexOnlyNumber = /^[\d.]+$/;

const skipedKeys = ['Backspace', 'Tab', 'Shift', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Delete'];

export const integerInputHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const value = (event.target as HTMLInputElement).value as string;
  if (skipedKeys.includes(event.key)) {
    return;
  }
  if (event.key === '-' && (event.target as HTMLInputElement).selectionStart === 0) {
    return;
  }
  if (!regexOnlyNumber.test(event.key)) {
    event.preventDefault();
  }
  if (['e', '.'].includes(event.key)) {
    event.preventDefault();
  }
};

export const decimalInputHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const value = (event.target as HTMLInputElement).value as string;
  const hasdecimalSeparator = value.indexOf('.') > -1;
  if (skipedKeys.includes(event.key)) {
    return;
  }
  if (event.key === '-' && (event.target as HTMLInputElement).selectionStart === 0) {
    return;
  }
  if (!regexOnlyNumber.test(event.key)) {
    event.preventDefault();
  }
  if (['e'].includes(event.key)) {
    event.preventDefault();
  }
  if (event.key === ',') {
    event.preventDefault();
    if (!hasdecimalSeparator) {
      (event.target as HTMLInputElement).value += '.';
    }
  }
  if (event.key === '.') {
    if (hasdecimalSeparator) {
      event.preventDefault();
    }
  }
};
