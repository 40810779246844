/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, MenuItem, TextField } from '@mui/material';

import { useIsMount } from '@hooks/useIsMount';
import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';
import { Position } from '@mytypes/map';
import { styles } from './styles';

export interface Props {
  hasLocation: boolean;
  name: string;
  position?: Position;
  value: string | undefined;
  onAddLocation: () => void;
  onChange: (value: string | undefined) => void;
  onReCenterLocationEnabled: () => void;
}

const FlagCharacteristicType = ({ hasLocation, name, position, value, onAddLocation, onChange, onReCenterLocationEnabled }: Props) => {
  const { t } = useTranslation();

  const isMount = useIsMount();
  const { showBoundary } = useErrorBoundary();

  const displayModal = useNewLocationModal();
  const [controlledValue, setControlledValue] = useState<string | undefined>(value);

  useEffect(() => {
    if (isMount) {
      onChange(controlledValue);
    }
  }, [controlledValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e.target.value as string;
      setControlledValue(value);
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div>
      <FormControl css={styles.container}>
        <TextField
          select
          name={name}
          value={controlledValue || ''}
          onChange={handleChange}
          placeholder={t('caracteristics.type-answer-placeholder')}
          autoComplete="off"
        >
          <MenuItem key={'yes'} value="yes">
            {t('general.yes')}
          </MenuItem>
          <MenuItem key={'no'} value="no">
            {t('general.no')}
          </MenuItem>
        </TextField>
      </FormControl>

      <div css={styles.actionsContainer}>
        <Button variant="text" size="small" onClick={() => displayModal(name, position)}>
          {t('caracteristics.set-location-button')}
        </Button>
        {hasLocation ? (
          <Button variant="text" size="small" onClick={() => onReCenterLocationEnabled()}>
            {t('caracteristics.re-center-location-button')}
          </Button>
        ) : (
          <Button variant="text" size="small" onClick={() => onAddLocation()}>
            {t('caracteristics.add-location-button')}
          </Button>
        )}
        <Button variant="text" size="small" css={styles.clearBtn} onClick={() => (value ? setControlledValue(undefined) : onChange(undefined))}>
          {t('caracteristics.clear-button')}
        </Button>
      </div>
    </div>
  );
};

export default FlagCharacteristicType;
