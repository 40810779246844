import { css } from '@emotion/react';

export const styles = {
  btn: css`
    position: absolute;
    right: 20;
    top: 20;
    background-color: rgba(255, 255, 255, 0.09);
    color: #fff;
  `
};
