/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, Paper, Typography } from '@mui/material';

import { ViewHeader } from '@components/ViewHeader';
import { FieldCard } from '@components/FieldCard';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetGrower } from '@hooks/useGetGrower';
import { TrialField } from '@mytypes/trial';
import { getAllFieldVisitPlansWithDexie, getFieldByGrowerWithDexie } from '@services/local';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const GrowerDetail = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { memberId } = useParams();
  const [searchParams] = useSearchParams();
  const [downloadedFields, setDownloadedFields] = useState<any[]>([]);
  const [fieldVisitPlans, setFieldVisitPlans] = useState<any[]>([]);
  const { fields } = useSelector((state: RootState) => state.trial);
  const { currentProtocol } = useSelector((state: RootState) => state.protocol);

  const getGrower = useGetGrower();

  const growerFields = useMemo(
    () =>
      fields
        .filter(field => field.owner_id === memberId && !field.dropout && (currentProtocol ? field.protocol_id === currentProtocol : true))
        .sort((a: TrialField, b: TrialField) => a.name.localeCompare(b.name)),
    [fields]
  );

  const member = useMemo(() => {
    if (memberId) {
      return getGrower(memberId);
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId) {
      getFieldByGrowerWithDexie(memberId).then(result => {
        setDownloadedFields(result);
      });
      getAllFieldVisitPlansWithDexie().then(result => {
        setFieldVisitPlans(result);
      });
    }
  }, [growerFields]);

  const handleOnGoBackBtnPress = () => {
    const operativeParam = searchParams.get('operative');
    const backToParam = searchParams.get('backTo');
    if (operativeParam) {
      navigate(`/operative/${searchParams.get('operative')}`);
    } else {
      if (backToParam) {
        navigate(`${backToParam}`);
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Paper css={styles.container(theme)}>
      {member && (
        <>
          <ViewHeader title={`${member.first_name} ${member.last_name}`} onGoBackBtnPress={handleOnGoBackBtnPress} />

          {growerFields.length === 0 && (
            <Typography color="text.secondary" css={styles.emptyStateMessage(theme)}>
              {t('grower-detail.empty-state-text')}
            </Typography>
          )}

          <div css={styles.scrollableArea(theme)}>
            <List>
              {growerFields.map(field => (
                <FieldCard
                  key={`${field.protocol_id}-${field.field_id}`}
                  field={field}
                  downloadedFields={downloadedFields}
                  member={member}
                  fieldVisitPlans={fieldVisitPlans}
                  operative={searchParams.get('operative') || undefined}
                />
              ))}
            </List>
          </div>
        </>
      )}
    </Paper>
  );
};

export default GrowerDetail;
