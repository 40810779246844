/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Badge, Button, ButtonGroup, Paper } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';

import { ViewHeader } from '@components/ViewHeader';
import { NewPlanWizardFirstStep } from '@components/Planner/NewPlanWizardFirstStep';
import { NewPlanWizardSecondStep } from '@components/Planner/NewPlanWizardSecondStep';
import { useAppTheme } from '@hooks/useAppTheme';
import { useSelectedFieldsModal } from '@hooks/modals/useSelectedFieldsModal';
import { FieldVisitPlan } from '@mytypes/local';
import {
  createFieldVisitPlanWithDexie,
  deleteFieldWithDexie,
  getFieldByProtocolWithDexie,
  getFieldVisitPlanWithDexie,
  updateFieldVisitsPlanWithDexie,
  updateFieldWithDexie
} from '@services/local';
import { setShowAppNavigator } from '@state-mgmt/slices/appSlice';
import { RootState } from '@state-mgmt/store';
import { clearAllFields } from '@state-mgmt/slices/downloader-slice';
import { setFields } from '@state-mgmt/slices/downloader-slice';

import { styles } from './styles';

const NewPlanWizard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planId } = useParams();
  const theme = useAppTheme({});
  const { fields } = useSelector((state: RootState) => state.downloader);
  const [step, setStep] = useState<number>(0);
  const [planName, setPlanName] = useState<string>();
  const [submit, setSubmit] = useState<boolean>(false);
  const [plan, setPlan] = useState<FieldVisitPlan>();
  const displaySelectedFieldsModal = useSelectedFieldsModal();

  useEffect(() => {
    dispatch(setShowAppNavigator(false));
  }, []);

  useEffect(() => {
    if (planId) {
      getFieldVisitPlanWithDexie(Number(planId)).then(plan => setPlan(plan));
    }
  }, [planId]);

  useEffect(() => {
    if (plan) {
      setPlanName(plan.name);
      dispatch(setFields(plan.fields));
    }
  }, [plan]);

  const onSubmitComplete = (name: string) => {
    setPlanName(name);
    setStep(1);
  };

  const onCancelBtnClicked = () => {
    // display confirmation dialog here
    dispatch(clearAllFields());
    dispatch(setShowAppNavigator(true));
    navigate('/planner');
  };

  const onPreviousBtnClicked = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const onNextBtnClicked = async () => {
    if (step === 0) {
      setSubmit(true);
    }
    if (step === 1) {
      const payload = {
        name: planName || 'no-name',
        date: moment().toISOString(),
        fields: fields.map(field => ({
          clientId: field.clientId,
          clientName: field.clientName,
          fieldId: field.fieldId,
          fieldName: field.fieldName,
          growerId: field.growerId,
          growerName: field.growerName,
          protocolId: field.protocolId,
          protocolName: field.protocolName,
          trialId: field.trialId,
          trialName: field.trialName
        }))
      };

      if (plan) {
        // update the field plans list
        for (const field of plan.fields) {
          const itWas = payload.fields.some(f => f.fieldId === field.fieldId && f.protocolId === field.protocolId);
          if (!itWas) {
            const fieldObject = await getFieldByProtocolWithDexie(field.fieldId, field.protocolId);
            if (fieldObject) {
              if (fieldObject.plans.length === 1) {
                await deleteFieldWithDexie(fieldObject.id);
              } else {
                const newPlans = fieldObject.plans?.filter((p: any) => p.id !== planId);
                await updateFieldWithDexie(fieldObject.id, { plans: newPlans });
              }
            }
          }
        }
      }

      let result = undefined;
      if (plan) {
        result = await updateFieldVisitsPlanWithDexie(Number(planId), payload);
      } else {
        result = await createFieldVisitPlanWithDexie(payload);
      }

      if (fields.length > 0) {
        navigate(`/planner/${result}/download`);
        return;
      }

      dispatch(clearAllFields());
      dispatch(setShowAppNavigator(true));
      navigate('/planner');
    }
  };

  const fieldSelectedtBtn = (
    <div onClick={() => fields.length && displaySelectedFieldsModal()}>
      <Badge badgeContent={fields.length} color="primary">
        <MapIcon color="action" />
      </Badge>
    </div>
  );

  return (
    <Paper css={styles.container(theme)}>
      {planName && step > 0 && <ViewHeader title={planName} hideGoBackBtn={true} rightBtn={fieldSelectedtBtn} />}

      {/* wizard steps */}
      {step === 0 && <NewPlanWizardFirstStep value={planName} submit={submit} onSubmit={() => setSubmit(false)} onSubmitComplete={onSubmitComplete} />}
      {step === 1 && <NewPlanWizardSecondStep />}

      <div css={styles.actionsContainer(theme)}>
        <Button variant="text" onClick={onCancelBtnClicked} sx={{ textTransform: 'uppercase' }}>
          {t('general.cancel')}
        </Button>

        <ButtonGroup variant="contained">
          <Button css={styles.actionsBtn} onClick={onPreviousBtnClicked} disabled={step === 0} sx={{ textTransform: 'uppercase' }}>
            {t('general.previous')}
          </Button>
          <Button
            css={styles.actionsBtn}
            onClick={onNextBtnClicked}
            disabled={step === 1 && fields.length === 0 && !planId}
            sx={{ textTransform: 'uppercase' }}
          >
            {t('general.next')}
          </Button>
        </ButtonGroup>
      </div>
    </Paper>
  );
};

export default NewPlanWizard;
