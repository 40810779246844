import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { countryNames } from '@constants/countries';
import { RootState } from '@state-mgmt/store';

export const SSURGO_LABEL_US = 'SSURGO';
export const SSURGO_LABEL_CAN = 'CanSIS';

export default function useSsurgoLabel() {
  const { trialId } = useSelector((state: RootState) => state.app);
  const { trials } = useSelector((state: RootState) => state.trial);

  const activeTrial = useMemo(() => trials.find(t => t.id === trialId), [trialId, trials]);

  const label = useMemo(() => {
    if (!activeTrial) {
      return SSURGO_LABEL_US;
    } else if (activeTrial.country === countryNames.CANADA) {
      return SSURGO_LABEL_CAN;
    } else {
      return SSURGO_LABEL_US;
    }
  }, [activeTrial]);

  return label;
}
