import ReactDOM from 'react-dom/client';
import config from './config';
import * as Sentry from '@sentry/react';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './db';

// import reportWebVitals from "./reportWebVitals";
// import swDev from "./swDev";

if (config.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https:yourserver.io/api/']
      })
      // new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// hack to calculate correctly the view port on mobile browser https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const getVh = () => window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${getVh()}px`);
// window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${getVh}px`));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// swDev();
