import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: css`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
    padding: 20px;
  `,

  image: css`
    width: 100%;
  `,
  text: (theme: Theme) => css`
    text-align: center;
    margin-left: ${theme.spacing(10)};
    margin-right: ${theme.spacing(10)};
  `
};
