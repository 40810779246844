import axios from 'axios';

import {
  GetProtocolsResponsePayload,
  GetProtocolStatusRequestPayload,
  GetProtocolStatusResponsePayload,
  GetStepCharacterizationsRequestPayload,
  GetProtocolStatusFieldRequestPayload,
  GetProtocolStatusFieldResponsePayload,
  GetFieldVisitValuesRequestPayload,
  GetFieldVisitValuesResponsePayload,
  SubmitCharacteristicsValuesRequestPayload,
  SubmitCharacteristicsValuesResponsePayload,
  DeleteCharacteristicValueApiRequestPayload,
  DeleteCharacteristicValueApiResponsePayload,
  SubmitsCharacteristicUploadRequestPayload,
  SubmitsCharacteristicUploadResponsePayload,
  CompleteStepRequestPayload,
  CompleteStepResponsetPayload,
  GetFieldNotesRequestPayload,
  GetFieldNotesResponsePayload,
  UpdateFieldNoteRequestPayload,
  UpdateFieldNoteResponsePayload,
  SubmitFieldNotesUploadRequestPayload,
  SubmitFieldNotesUploadResponsePayload,
  DeleteFieldNotesUploadRequestPayload,
  DeleteFieldNotesUploadResponsePayload,
  CreateFieldNoteRequestPayload,
  CreateFieldNoteResponsePayload,
  EnrollFieldRequestPayload,
  EnrollFieldResponsePayload,
  DropoutFieldRequestPayload,
  DropoutFieldResponsePayload,
  UnDropoutFieldRequestPayload,
  UnDropoutFieldResponsePayload,
  DropoutGrowerRequestPayload,
  DropoutGrowerResponsePayload,
  GetStepCharacterizationsResponsePayload,
  GetFieldUploadsRequestPayload,
  GetFieldUploadsResponsePayload,
  GetProtocolCropsListResponsePayload
} from '@mytypes/protocol';
import { validateToken } from '@utils/isTokenExpired';
import config from '../config';

export const getProtocolsListApi = async (trialId: string): Promise<GetProtocolsResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/list?trial_id=${trialId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data as GetProtocolsResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const getProtocolStatusApi = async ({
  trialId,
  protocolId,
  stepType
}: GetProtocolStatusRequestPayload): Promise<GetProtocolStatusResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/status?trial_id=${trialId}&protocol_id=${protocolId}&step_type=${stepType}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetProtocolStatusResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const getStepCharacterizationsApi = async ({
  trialId,
  protocolId,
  stepId
}: GetStepCharacterizationsRequestPayload): Promise<GetStepCharacterizationsResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/steps/characterizations?trial_id=${trialId}&protocol_id=${protocolId}&step_id=${stepId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data as GetStepCharacterizationsResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const getProtocolStatusFieldApi = async ({
  trialId,
  protocolId,
  fieldId
}: GetProtocolStatusFieldRequestPayload): Promise<GetProtocolStatusFieldResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/status/field?trial_id=${trialId}&protocol_id=${protocolId}&field_id=${fieldId}`,

      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetProtocolStatusFieldResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const getFieldVisitValuesApi = async ({
  trialId,
  protocolId,
  fieldId
}: GetFieldVisitValuesRequestPayload): Promise<GetFieldVisitValuesResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/values/field?trial_id=${trialId}&protocol_id=${protocolId}&field_id=${fieldId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data as GetFieldVisitValuesResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const submitCharacteristicsValuesApi = async (
  payload: SubmitCharacteristicsValuesRequestPayload
): Promise<SubmitCharacteristicsValuesResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/steps/characterizations/field/submit`,

      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        characterizations: payload.characterizations,
        complete: true,
        field_id: payload.fieldId,
        protocol_id: payload.protocolId,
        step_id: payload.stepId,
        trial_id: payload.trialId
      }
    });

    return data as SubmitCharacteristicsValuesResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const deleteCharacteristicValueApi = async (
  payload: DeleteCharacteristicValueApiRequestPayload
): Promise<DeleteCharacteristicValueApiResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/steps/characterizations/field/value`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        protocol_id: payload.protocolId,
        trial_id: payload.trialId,
        field_id: payload.fieldId,
        step_id: payload.stepId,
        value_id: payload.valueId,
        characterization_id: payload.characterizationId
      }
    });

    return data as DeleteCharacteristicValueApiResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const submitsCharacteristicUploadApi = async (
  payload: SubmitsCharacteristicUploadRequestPayload
): Promise<SubmitsCharacteristicUploadResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const res = await fetch(payload.value);
    const file = await res.blob();

    let formData = new FormData();
    formData.append('characterization_id', payload.characterizationId);
    formData.append('field_id', payload.fieldId);
    formData.append('file', file, 'blob.jpeg');
    formData.append('protocol_id', payload.protocolId);
    formData.append('step_id', payload.stepId);
    formData.append('trial_id', payload.trialId);

    if (payload.latitude) {
      formData.append('latitude', payload.latitude);
    }
    if (payload.longitude) {
      formData.append('longitude', payload.longitude);
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/steps/characterizations/field/upload`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });

    return data as SubmitsCharacteristicUploadResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const completeStepApi = async (payload: CompleteStepRequestPayload): Promise<CompleteStepResponsetPayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/steps/complete`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        client_id: payload.clientId,
        field_id: payload.fieldId,
        protocol_id: payload.protocolId,
        step_id: payload.stepId,
        trial_id: payload.trialId
      }
    });

    return data as CompleteStepResponsetPayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const getGrowersByOperativeUserApi = async (trialId: string, userId: string): Promise<any | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/operative?trial_id=${trialId}&user_id=${userId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getFieldUploadsApi = async ({
  trialId,
  protocolId,
  fieldId
}: GetFieldUploadsRequestPayload): Promise<GetFieldUploadsResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }
    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/uploads/field?trial_id=${trialId}&protocol_id=${protocolId}&field_id=${fieldId}`,

      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data as GetFieldUploadsResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const getProtocolCropsListApi = async (): Promise<GetProtocolCropsListResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/crops/list`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data as GetProtocolCropsListResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

// field notes

export const getFieldNotesApi = async ({ trialId, protocolId, fieldId }: GetFieldNotesRequestPayload): Promise<GetFieldNotesResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/notes?trial_id=${trialId}&protocol_id=${protocolId}&field_id=${fieldId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetFieldNotesResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const updateFieldNoteApi = async (payload: UpdateFieldNoteRequestPayload): Promise<UpdateFieldNoteResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/notes/update`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        date: payload.date,
        field_id: payload.fieldId,
        field_note_id: payload.field_note_id,
        latitude: payload.latitude,
        longitude: payload.longitude,
        note: payload.note,
        protocol_id: payload.protocolId,
        trial_id: payload.trialId,
        zone: payload.zone_id
      }
    });

    return data;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const submitFieldNotesUploadApi = async (payload: SubmitFieldNotesUploadRequestPayload): Promise<SubmitFieldNotesUploadResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }
    const requestBody = new FormData();
    requestBody.append('field_id', payload.fieldId);
    requestBody.append('field_note_id', payload.field_note_id);
    requestBody.append('file', payload.file);
    requestBody.append('protocol_id', payload.protocolId);
    requestBody.append('trial_id', payload.trialId);
    requestBody.append('upload_type', payload.upload_type);
    if (payload.longitude) {
      requestBody.append('longitude', `${payload.longitude}`);
    }
    if (payload.latitude) {
      requestBody.append('latitude', `${payload.latitude}`);
    }

    const { data } = await axios({
      method: 'POST',
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/notes/media/upload`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'content-type': 'multipart/form-data'
      },
      data: requestBody
    });

    return data as SubmitFieldNotesUploadResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const deleteFieldNotesUploadApi = async (payload: DeleteFieldNotesUploadRequestPayload): Promise<DeleteFieldNotesUploadResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }
    const { data } = await axios({
      method: 'DELETE',
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/notes/media/delete`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'content-type': 'application/json'
      },
      data: {
        field_id: payload.fieldId,
        field_note_id: payload.noteId,
        protocol_id: payload.protocolId,
        trial_id: payload.trialId,
        upload_id: payload.uploadId
      }
    });

    return data as DeleteFieldNotesUploadResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const createFieldNoteApi = async (payload: CreateFieldNoteRequestPayload): Promise<CreateFieldNoteResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/notes/add`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        date: payload.date,
        field_id: payload.fieldId,
        note_id: payload.noteId,
        latitude: payload.latitude,
        longitude: payload.longitude,
        note: payload.note,
        protocol_id: payload.protocolId,
        trial_id: payload.trialId,
        zone_id: payload.zone_id
      }
    });

    return data as CreateFieldNoteResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

// enrollment

export const enrollFieldApi = async (payload: EnrollFieldRequestPayload): Promise<EnrollFieldResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/fields/enroll`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        client_id: payload.clientId,
        field_id: payload.fieldId,
        protocol_id: payload.protocolId,
        trial_id: payload.trialId
      }
    });

    return data as EnrollFieldResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const dropoutFieldApi = async (payload: DropoutFieldRequestPayload): Promise<DropoutFieldResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }
    console.log('dropoutFieldApi >>>>>>>>>>', payload);
    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/dropout/field`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        trial_id: payload.trialId,
        protocol_id: payload.protocolId,
        field_id: payload.fieldId
      }
    });
    return data as DropoutFieldResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const undropoutFieldApi = async (payload: UnDropoutFieldRequestPayload): Promise<UnDropoutFieldResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/undropout/field`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        protocol_id: payload.protocolId,
        field_id: payload.fieldId
      }
    });
    return data as UnDropoutFieldResponsePayload;
  } catch (error) {
    console.error(error);
    throw new Error('something went wrong');
  }
};

export const dropoutGrowerApi = async (payload: DropoutGrowerRequestPayload): Promise<DropoutGrowerResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_PROTOCOL_SERVICE_URL}/v2/dropout`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        protocol_id: payload.protocolId,
        trial_id: payload.trialId,
        user_id: payload.userId
      }
    });
    return data as DropoutGrowerResponsePayload;
  } catch (error) {
    console.log(error);
    throw new Error('something went wrong');
  }
};
