import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import * as turf from '@turf/turf';

import { getCLUsApi } from '@services/mdi';

export const useGetCLUs = () =>
  useAsyncCallback(
    useCallback(async (bbox: turf.BBox, limit: number, country?: string): Promise<any | undefined> => {
      const data = await getCLUsApi({ bbox, country, limit });
      return data;
    }, [])
  );
