import axios from 'axios';

import { validateToken } from '@utils/isTokenExpired';
import {
  ExportTableRequestPayload,
  GetBoundariesRequestPayload,
  GetBoundariesResponsePayload,
  createTableRequestPayload,
  getCLUsRequestPayload
} from '@mytypes/mdi';
import config from '../config';

export const getBoundariesApi = async (payload: GetBoundariesRequestPayload): Promise<GetBoundariesResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('field_id', payload.fieldId);
    formData.append('protocol_id', payload.protocolId);
    const { data } = await axios({
      url: `${config.REACT_APP_MDI_SERVICE_URL}/boundaries`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
    return data as GetBoundariesResponsePayload;
  } catch (error) {
    console.error(error);
  }
};

export const exportTableApi = async (payload: ExportTableRequestPayload): Promise<string | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('table_guid', payload.tableGuid);
    formData.append('format', 'json');
    const { data } = await axios({
      url: `${config.REACT_APP_MDI_SERVICE_URL}/export`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    return data as string;
  } catch (error) {
    console.error(error);
  }
};

export const createTableApi = async (payload: createTableRequestPayload): Promise<string | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('json', btoa(payload.geometry));
    formData.append('trial_id', payload.trialId);
    formData.append('field_id', payload.fieldId);
    formData.append('operation', 'boundary');
    const { data } = await axios({
      url: `${config.REACT_APP_MDI_SERVICE_URL}/create`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    return data as string;
  } catch (error) {
    console.error(error);
  }
};

export const getCLUsApi = async (payload: getCLUsRequestPayload): Promise<string | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('limit', `${payload.limit}`);
    formData.append('xmin', `${payload.bbox[0]}`);
    formData.append('ymin', `${payload.bbox[1]}`);
    formData.append('xmax', `${payload.bbox[2]}`);
    formData.append('ymax', `${payload.bbox[3]}`);

    if (payload.country) {
      formData.append('country', payload.country);
    }

    const { data } = await axios({
      url: `${config.REACT_APP_MDI_SERVICE_URL}/clu`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    return data as string;
  } catch (error) {
    console.error(error);
  }
};
