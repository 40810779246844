/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Menu, MenuItem } from '@mui/material';
// import { LongPressEventType, useLongPress } from 'use-long-press';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { CameraModal } from '@components/CameraModal';
import { PhotoViewModal } from '@components/PhotoViewModal';
import { useIsMount } from '@hooks/useIsMount';
import { RootState } from '@state-mgmt/store';
import { resizeImage } from '@utils/resizeImage';
import { isMobile } from '@utils/isMobile';
import { styles } from './styles';
import { useUploadOptionsModal } from '@hooks/modals/useUploadOptionsModal';
import { UPLOAD_OPTION } from '@components/UploadOptionsModal/UploadOptionsModal';
import { detectIOS } from '@utils/detectIOS';

export interface Props {
  value: string[] | undefined;
  onChange: (values: any[] | undefined) => void;
}

const UploadCharacteristicType = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const { currentPosition } = useSelector((state: RootState) => state.app);
  const cameraInputRef = useRef<HTMLInputElement>(null);
  const galleryInputRef = useRef<HTMLInputElement>(null);
  const isMount = useIsMount();
  const { displayModal: displayUploadOptionsModal } = useUploadOptionsModal();

  const [controlledValue, setControlledValue] = useState<any[] | undefined>(value || []);
  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const [photo, setPhoto] = useState<any>();
  // const [selectedPhoto, setSelectedPhoto] = useState<any>();
  const [photoPermissionsAlertOpen, setPhotoPermissionsAlertOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const bind = useLongPress(
  //   (event, meta) => {
  //     // @ts-ignore
  //     setSelectedPhoto(meta.context?.id);
  //     // @ts-ignore
  //     setAnchorEl(event.target);
  //   },
  //   {
  //     // onCancel: (event, meta) => {
  //     //   console.log('set Photo', meta);
  //     // },
  //     filterEvents: event => true, // All events can potentially trigger long press
  //     threshold: 1000,
  //     captureEvent: true,
  //     cancelOnMovement: false,
  //     cancelOutsideElement: true,
  //     detect: LongPressEventType.Pointer
  //   }
  // );
  useEffect(() => {
    if (isMount && controlledValue) {
      onChange(controlledValue);
    }
  }, [controlledValue]);

  const onConfirmPhoto = (image: string) => {
    try {
      const prevValues = controlledValue ? [...controlledValue] : [];
      setControlledValue([...prevValues, { image, id: uuidv4(), ...currentPosition }]);
      setCameraModalOpen(false);
    } catch (error) {
      showBoundary(error);
    }
  };

  const addSrc = async (e: any) => {
    try {
      const images: any = [];
      for (const file of e.target.files) {
        if (file) {
          const image = await resizeImage(file, '1080p');
          images.push({ image, id: uuidv4(), ...currentPosition });
        }
      }
      const prevValues = controlledValue ? [...controlledValue] : [];
      setControlledValue([...prevValues, ...images]);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleAddPhoto = async () => {
    try {
      if (isMobile()) {
        if (detectIOS()) {
          galleryInputRef.current?.click();
        } else {
          displayUploadOptionsModal(option => {
            if (option === UPLOAD_OPTION.CAMERA) {
              cameraInputRef.current?.click();
            } else {
              galleryInputRef.current?.click();
            }
          });
        }
      } else {
        try {
          await navigator.mediaDevices.getUserMedia({
            video: true
          });
          setCameraModalOpen(true);
        } catch (error) {
          setPhotoPermissionsAlertOpen(true);
          console.error(error);
        }
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const onDelete = (id: string | undefined, valueId: any) => {
    if (valueId) {
      const uploadToDelete = { ...controlledValue?.find((cv: any) => cv.valueId && cv.valueId === valueId) };
      uploadToDelete['remove'] = true;
      const result = controlledValue?.filter(cv => (cv.valueId && cv.valueId !== valueId) || cv.image);
      setControlledValue([...(result || []), uploadToDelete]);
    } else {
      setControlledValue(controlledValue?.filter(v => v.id !== id));
    }
  };

  // const isSelected = (id: string | undefined, valueId: string | undefined) => {
  //   if (id) {
  //     return selectedPhoto === id;
  //   }
  //   if (valueId) {
  //     return selectedPhoto === valueId;
  //   }
  // };

  return (
    <>
      <div css={styles.container}>
        {controlledValue?.map(({ image, id, url, valueId, remove }) => (
          // <button
          //   {...bind({ id: id || valueId })}
          //   onClick={() => {
          //     setPhoto({ id, valueId, src: image || url });
          //   }}
          //   css={styles.photoWrapper}
          // >
          <div
            key={id || valueId}
            style={{ backgroundImage: `url(${image || url})` }}
            // css={[styles.thumbnail, remove && styles.hidden, isSelected(id, valueId) && styles.selected]}
            css={[styles.thumbnail, remove && styles.hidden]}
            onClick={() => {
              setPhoto({ id, valueId, src: image || url });
            }}
          />
          // </button>
        ))}
        <div css={styles.addPhotoBtn}>
          <input ref={cameraInputRef} type="file" accept="image/*" capture="environment" css={styles.inputget} onChange={addSrc} />
          <input ref={galleryInputRef} type="file" accept="image/*" id="gallery" multiple css={styles.inputget} onChange={addSrc} />
          <IconButton css={styles.addPhotoBtnIconContainer} onClick={handleAddPhoto}>
            <AddAPhotoIcon css={styles.addPhotoBtnIcon} />
          </IconButton>
        </div>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          {t('general.delete')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          {t('caracteristics.re-center-location-button')}
        </MenuItem>
      </Menu>

      <PhotoViewModal open={!!photo} photo={photo} onClose={() => setPhoto(undefined)} onPhotoDeleted={(id, valueId) => onDelete(id, valueId)} />

      <CameraModal open={cameraModalOpen} onClose={() => setCameraModalOpen(false)} onConfirm={onConfirmPhoto} />

      <Dialog open={photoPermissionsAlertOpen} onClose={() => setPhotoPermissionsAlertOpen(true)}>
        <DialogTitle> {t('camera-permissions.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText css={styles.dialogText}>{t('camera-permissions.text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPhotoPermissionsAlertOpen(false)} variant="contained" autoFocus>
            {t('general.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadCharacteristicType;
