import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { LocalFieldVisit } from '@mytypes/local';
import { getAllFieldVisitsWithDexie } from '@services/local';

export const useGetLocalFieldVisits = () => {
  return useAsyncCallback(
    useCallback(async (): Promise<LocalFieldVisit[]> => {
      const localVisits = await getAllFieldVisitsWithDexie();
      return localVisits;
    }, [])
  );
};
