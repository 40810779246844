import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getFieldTables } from '@utils/getFieldTables';
import mapboxgl from 'mapbox-gl';
import config from '../config';

import { useGetFieldVisitsSteps } from './useGetFieldVisitsSteps';

export const useGetAllFieldData = () => {
  const [, getFieldVisitsSteps] = useGetFieldVisitsSteps();

  return useAsyncCallback(
    useCallback(async (trialId: string, protocolId: string | undefined, fieldId: string, skipBoundaries: boolean = false) => {
      try {
        // get visits
        const visits = protocolId ? await getFieldVisitsSteps(trialId, protocolId, fieldId) : [];

        // get boundaries
        let boundaries = undefined;
        if (!skipBoundaries && protocolId) {
          boundaries = await getFieldTables(trialId, protocolId, fieldId, true, true);
        }

        try {
          new mapboxgl.Map({
            accessToken: config.REACT_APP_MAPBOX_ACCESS_TOKEN,
            container: 'map-cache-container',
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            zoom: 4
          });
        } catch (error) {
          console.error(error);
        }
        return {
          boundaries,
          visits
        };
      } catch (error) {
        return new Error('Something went wrong');
      }
    }, [])
  );
};
