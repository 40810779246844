import colorString from 'color-string';

export const convertHexToArray = (hexColor: string, opacity?: number) => {
  const rgbColor = colorString.get.rgb(hexColor);
  const result = new Uint8Array(rgbColor.slice(0, 3));

  if (opacity && opacity > 1) {
    throw new Error('opacity cannot be greater than 1');
  } else if (opacity && opacity < 0) {
    throw new Error('opacity cannot be less than 0');
  }

  // @ts-ignore
  return [...result, opacity || opacity === 0 ? Math.round(255 * opacity) : 255];
};

export const convertRgbToMapColorArray = (color: string) => {
  const colorRgb = colorString.get.rgb(color);
  if (colorRgb) return new Uint8Array(colorRgb.slice(0, 3));

  return new Uint8Array([255, 255, 255]);
};
