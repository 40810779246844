/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

import { NavigateButton } from '@components/NavigateButton';
import { DownloadFieldVisitsButton } from '@components/Planner/DownloadFieldVisitsButton';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetProtocolName } from '@hooks/useGetProtocolName';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  downloadedFields: any;
  field: any;
  fieldVisitPlans: any[];
  member: any;
  operative?: string;
}

const FieldCard = ({ downloadedFields, field, fieldVisitPlans, member, operative }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();

  const { online } = useSelector((state: RootState) => state.app);

  const getProtocolName = useGetProtocolName();

  const name = useMemo(() => `${member.first_name} ${member.last_name}`, [member]);

  const getField = useCallback(
    (fieldId: string, protocolId: string) =>
      downloadedFields?.find((downloadedField: any) => downloadedField.fieldId === fieldId && downloadedField.protocolId === protocolId),
    [downloadedFields]
  );

  return (
    <Card key={`${field.field_id}-${field.protocol_id}`} elevation={0} css={styles.card(theme)}>
      <div css={styles.cardHeader}>
        <Typography color="text.primary" css={styles.cardHeaderTitle(theme)}>
          {field.name} ({name})
        </Typography>
        <DownloadFieldVisitsButton
          fieldId={field.field_id}
          protocolId={field.protocol_id}
          downloadedFields={field.downloadedFields}
          fieldVisitPlans={fieldVisitPlans}
        />
      </div>

      <CardContent css={styles.cardContent(theme)}>
        <Typography color="text.primary" fontSize={14}>
          {t('general.protocol')}: {getProtocolName(field.protocol_id) || ''}
        </Typography>
      </CardContent>

      <CardActions css={styles.cardActions}>
        <NavigateButton fieldId={field.field_id} disabled={!online} centerPoint={field.wkt_point} />

        <Button size="small" onClick={() => navigate(`/field-map/${field.field_id}/${field.protocol_id}`)} disabled={!online}>
          {t('field-card.field-map-button')}
        </Button>

        <Button size="small" onClick={() => navigate(`/field-notes/${field.field_id}/${field.protocol_id}/${field.owner_id}`)} disabled={!online}>
          {t('field-card.field-notes-button')}
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={() => {
            let url = `/field-visits/${field.field_id}/${field.protocol_id}`;
            const fieldFound = getField(field.field_id, field.protocol_id);
            if (fieldFound) url += `/${fieldFound.id}`;
            url += `?grower_id=${member.user_id}`;
            if (operative) {
              url += `&operative=${operative}`;
            }
            navigate(url);
          }}
          disabled={!getField(field.field_id, field.protocol_id)?.id && !online}
        >
          {t('field-card.visits-button')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default FieldCard;
