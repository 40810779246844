import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme, isSaving: boolean) => css`
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
    font-size: 12px;
    color: ${isSaving ? '#8c8526' : '#618761'};
    border: 1px solid ${isSaving ? '#8c8526' : '#618761'};
    border-radius: ${theme.spacing(0.5)};
    display: flex;
    align-items: center;
    line-height: normal;
  `,
  textContainer: css`
    width: 65px;
  `,
  iconContainer: css`
    width: 20px;
  `,
  checkIcon: css`
    font-size: 18px;
    color: #618761;
  `,
  emptyContainer: css`
    width: 70px;
    margin-right: 4px;
  `
};
