/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import Dayjs from 'dayjs';
import { Button, Card, CardActions, CardContent, IconButton, List, Paper, Typography } from '@mui/material';
import { ViewHeader } from '@components/ViewHeader';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useAppTheme } from '@hooks/useAppTheme';
import { useGetFieldNotes } from '@hooks/useGetFieldNotes';
import { useCreateFieldNote } from '@hooks/useCreateFieldNote';
import { RootState } from '@state-mgmt/store';
import { deleteFieldVisitNoteWithDexie, getAllFieldVisitsNotesWithDexie } from '@services/local';
import { setCurrentNote } from '@state-mgmt/slices/notesSlice';
import { styles } from './styles';

const FieldNotes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fieldId, protocolId, growerId } = useParams();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { online, trialId } = useSelector((state: RootState) => state.app);
  const [localNotes, setLocalNotes] = useState<any[]>([]);

  const [getFieldNotesState, getFieldNotes] = useGetFieldNotes();
  const [createFieldNoteState, createFieldNote] = useCreateFieldNote();
  const [syncedNoteId, setSyncedNoteId] = useState<any>(null);

  const onEditFieldNote = (id: string) => () => {
    const selectedNote = (getFieldNotesState.data || []).find((note: any) => note.id === id);
    dispatch(setCurrentNote(selectedNote));
    navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}/edit/${id}`);
  };

  const onCreateFieldNote = () => {
    navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}/create`);
  };

  const onViewFieldNote = (id: string) => () => {
    const selectedNote = (getFieldNotesState.data || []).find((note: any) => note.id === id);
    dispatch(setCurrentNote(selectedNote));
    navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}/view/${id}`);
  };

  const onSyncBtnPress = (note: any) => {
    if (!trialId) return;

    setSyncedNoteId(note.id);
    createFieldNote({
      date: note.date,
      fieldId: note.fieldId,
      files: note.files,
      latitude: note.latitude,
      longitude: note.longitude,
      note: note.note,
      protocolId: note.protocolId,
      trialId
    });
  };

  useEffect(() => {
    if (trialId && protocolId && fieldId) {
      getFieldNotes(trialId, protocolId, fieldId);
    }
  }, [fieldId, protocolId]);

  useEffect(() => {
    const fetchOffline = async () => {
      if (!fieldId) return;

      const notes = await getAllFieldVisitsNotesWithDexie();
      setLocalNotes(notes.filter(note => note.fieldId === fieldId && note.protocolId === protocolId));
    };

    fetchOffline();
  }, [protocolId, fieldId]);

  const handleOnGoBackBtnPress = () => {
    if (growerId) {
      navigate(`/grower/${growerId}`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!trialId) return;

    const refreshOfflineNotes = async () => {
      if (createFieldNoteState.isSuccess) {
        await deleteFieldVisitNoteWithDexie(syncedNoteId);

        const notes = await getAllFieldVisitsNotesWithDexie();
        setLocalNotes(notes.filter(note => note.fieldId === fieldId && note.protocolId === protocolId));

        if (protocolId && fieldId) {
          getFieldNotes(trialId, protocolId, fieldId);
        }
      }
    };

    refreshOfflineNotes();
  }, [createFieldNoteState.isSuccess, syncedNoteId, fieldId, protocolId]);

  const handleDeleteLocalNote = async (noteId: string) => {
    await deleteFieldVisitNoteWithDexie(Number(noteId));
    setLocalNotes(localNotes.filter(note => note.id !== noteId));
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title="Field Notes" onGoBackBtnPress={handleOnGoBackBtnPress} />
      <div css={styles.scrollableArea(theme)}>
        <LoadingOverlay spinner active={getFieldNotesState.isLoading} text="loading field notes..." styles={{ wrapper: styles.listLoadingOverlayContent }}>
          {getFieldNotesState.data && !getFieldNotesState.data.length ? (
            <div css={styles.emptyContainer(theme)}>
              <EditNoteIcon fontSize="large" />
              <Typography css={styles.title}>{t('field-notes.empty-state.title')}</Typography>
              <Typography css={styles.subTitle}>{t('field-notes.empty-state.subtitle')}</Typography>
              <Typography css={styles.subTitle}>{t('field-notes.empty-state.text')}</Typography>
            </div>
          ) : null}
          <div css={styles.createNoteBtnContainer}>
            <Button variant="contained" fullWidth onClick={onCreateFieldNote} css={styles.createNoteBtn}>
              {t('field-notes.empty-state.button')}
            </Button>
          </div>
          <List>
            {getFieldNotesState.data?.map((note: any) => (
              <Card key={note.id} elevation={0} css={styles.notesCard(theme)}>
                <div css={styles.notesCardHeader}>
                  <Typography color="text.primary" css={styles.notesCardHeaderTitle(theme)}>
                    {note.note}
                  </Typography>
                  <div css={styles.notesCardActionsHeader(theme)}>
                    <IconButton size="small" onClick={onEditFieldNote(note.id)} disabled={!online}>
                      <EditIcon />
                    </IconButton>
                    <CheckCircleOutlineIcon color="success" css={styles.reportIcon} />
                  </div>
                </div>
                <CardContent css={styles.notesCardContent(theme)}>
                  <Typography color="text.primary" fontSize={14}>
                    {t('field-notes.completed-date-label')}: {Dayjs(note.date).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography color="text.primary" fontSize={14}>
                    {t('field-notes.completed-by-label')}: {note?.created_by_first} {note?.created_by_last}
                  </Typography>
                  <Typography color="text.primary" fontSize={14}>
                    {t('field-notes.last-update-label')}: {note?.updated_at ? Dayjs(note.date).format('MM/DD/YYYY') : ''}
                  </Typography>
                </CardContent>
                <CardActions css={styles.notesCardActions}>
                  <Button size="small" variant="contained" onClick={onViewFieldNote(note.id)} disabled={!online}>
                    {t('general.view')}
                  </Button>
                </CardActions>
              </Card>
            ))}

            {localNotes.map((note: any) => (
              <Card key={note.id} elevation={0} css={styles.notesCard(theme)}>
                <div css={styles.notesCardHeader}>
                  <Typography color="text.primary" css={styles.notesCardHeaderTitle(theme)}>
                    {note.note}
                  </Typography>
                  <div css={styles.notesCardActionsHeader(theme)}>
                    <ReportGmailerrorredIcon color="warning" css={styles.reportIcon} />
                  </div>
                </div>
                <CardContent css={styles.notesCardContent(theme)}>
                  <Typography color="text.primary" fontSize={14}>
                    {t('field-notes.completed-date-label')}: {Dayjs(note.date).format('MM/DD/YYYY')}
                  </Typography>
                </CardContent>
                <CardActions css={styles.notesCardActions}>
                  <Button size="small" variant="text" color="error" onClick={() => handleDeleteLocalNote(note.id)}>
                    {t('general.delete')}
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={() => onSyncBtnPress(note)}
                    disabled={createFieldNoteState.isLoading || !online}
                  >
                    {createFieldNoteState.isLoading ? t('general.syncing') : t('general.sync')}
                  </Button>
                </CardActions>
              </Card>
            ))}
          </List>
        </LoadingOverlay>
      </div>
    </Paper>
  );
};

export default FieldNotes;
