import * as turf from '@turf/turf';
import { Position } from '@mytypes/map';

export const getFieldCenterPoint = (fieldBoundary: any): Position | undefined => {
  if (fieldBoundary) {
    const geojsonPolugon = fieldBoundary.features.at(0).geometry;
    const polygon = turf.polygon(geojsonPolugon.coordinates);
    const centroid = turf.centroid(polygon);
    if (centroid) {
      return {
        latitude: centroid.geometry.coordinates[1],
        longitude: centroid.geometry.coordinates[0]
      };
    }
  }
  return undefined;
};
