import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAsyncCallback } from '@utils/useAsyncCallback';
import { createFieldNoteApi, submitFieldNotesUploadApi } from '@services/protocol';

export const useCreateFieldNote = () => {
  return useAsyncCallback(
    useCallback(
      async ({
        date,
        fieldId,
        files,
        latitude,
        longitude,
        note,
        protocolId,
        trialId
      }: {
        date: string;
        fieldId: string;
        files: any[];
        latitude: number;
        longitude: number;
        note: string;
        protocolId: string;
        trialId: string;
      }) => {
        try {
          const noteId = uuidv4();

          await createFieldNoteApi({
            date,
            fieldId,
            noteId,
            latitude,
            longitude,
            note,
            protocolId,
            trialId,
            zone_id: null
          });

          await Promise.all(
            files.map(async file => {
              return await submitFieldNotesUploadApi({
                fieldId,
                field_note_id: noteId,
                file,
                latitude,
                longitude,
                protocolId,
                trialId,
                upload_type: 'PHOTO'
              });
            })
          );
          return { noteId };
        } catch (error) {
          throw new Error('Something went wrong');
        }
      },
      []
    )
  );
};
