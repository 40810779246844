import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc((var(--vh, 1vh) * 100) - 56px);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border-radius: 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
    flex-grow: 2;

  `,
  listLoadingOverlayContent: css`
    position: relative;
    height: 100%;
  `,
  avatarContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    justify-content: center;
    flex-grow: 2;
  `,
  avatar: css`
    background-color: #06b7ff;
    width: 90px;
    height: 90px;
  `,
  username: css`
    margin: 0;
    padding: 15px 0;
  `,
  userrole: (theme: Theme) => css`
    color: ${theme.palette.secondary.light};
    font-size: 14px;
    font-weight: 400;
  `,
  card: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(1)};
  `,
  warningCard: () => css`
    border-color: #f44336;
  `,
  cardContent: css`
    padding-bottom: 0;
  `,
  cardSubContent: (theme: Theme) => css`
    margin: ${theme.spacing(2)} 0;
    padding: ${theme.spacing(2)};
    border: 1px solid rgba(255, 255, 255, 0.12);
  `,
  cardSubContentTitle: css`
    padding-bottom: 8px;
  `,
  cardSubContentText: css`
    font-weight: normal;
  `,
  cardSubContentRightBtn: css`
    display: flex;
    justify-content: end;
  `,
  cardActions: css`
    justify-content: end;
  `,
  switch: css`
    margin: 0;
    display: flex;
    justify-content: space-between;
  `,
  switchLabel: (theme: Theme) => css`
    color: ${theme.palette.secondary.light};
    font-size: 14px;
    font-weight: 400;
  `,
  resetBtn: (themeMode: THEME_MODE) => css`
    background-color: ${themeMode === THEME_MODE.DARK ? 'rgb(53, 54, 58)' : 'transparent'};

    border: 1px solid ${themeMode === THEME_MODE.DARK ? 'transparent' : '#f44336'};
    color: #f44336;
  `,
  logoutBtn: (theme: Theme) => css`
    margin: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
  `,
  popup: css`
    z-index: 11;
    top: -24px;
    & .mapboxgl-popup-content {
      padding: 18px;
      border-radius: 4px;
    }
    & .mapboxgl-popup-close-button {
      font-size: 30px;
      padding: 6px 12px;
    }
  `
};
