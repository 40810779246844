import { FieldVisitStep } from '@mytypes/protocol';

export const getStepSavedValues = (fieldVisitStep: FieldVisitStep) => {
  const savedValues = fieldVisitStep.pages.reduce((acum: any, page: any) => {
    acum.push(
      ...page.questions.map((question: any) => ({
        characterization_id: question.characterization_id,
        value: question.value,
        location: !!question.latitude && !!question.longitude,
        latitude: question.latitude,
        longitude: question.longitude
      }))
    );
    return acum;
  }, []);
  return savedValues;
};
