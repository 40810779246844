/** @jsxImportSource @emotion/react */
import { ButtonGroup, Modal, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles.css';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  image: any;
  open: boolean;
  onClose: () => void;
  onScreenshootDeleted: (id: string) => void;
}

const ScreenshootViewModal = ({ open, image, onClose, onScreenshootDeleted }: Props) => {
  const { t } = useTranslation();

  return image ? (
    <Modal open={open} onClose={onClose}>
      <div className="photo-view-container">
        <img src={image.src} className="photo" alt="some place" />

        <ButtonGroup variant="text" aria-label="outlined primary button group" css={styles.actionsContainer}>
          <Button variant="contained" onClick={onClose}>
            {t('general.close')}
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => {
              onScreenshootDeleted(image.id);
              onClose();
            }}
          >
            {t('general.delete')}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default ScreenshootViewModal;
