import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { StopDownloadingFieldsModal } from '@components/StopDownloadingFieldsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useStopDownloadingFieldsModal = () => {
  const modalKey = 'stop-download-fields-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(() => {
    const children = <StopDownloadingFieldsModal onClose={onClose} />;
    dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
  }, [dispatch, onClose]);

  return displayModal;
};
