/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, TextField, MenuItem, FormControl } from '@mui/material';

import { useIsMount } from '@hooks/useIsMount';
import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';
import { Position } from '@mytypes/map';
import { styles } from './styles';

export interface Props {
  hasLocation: boolean;
  name: string;
  options: string[];
  position?: Position;
  value: string | undefined;
  onAddLocation: () => void;
  onChange: (value: string | undefined) => void;
  onEditLocationEnabled: () => void;
}

const SelectionCharacteristicType = ({ hasLocation, name, options, position, value, onAddLocation, onChange, onEditLocationEnabled }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const isMount = useIsMount();
  const displayModal = useNewLocationModal();
  const [controlledValue, setControlledValue] = useState<string | undefined>(value);

  useEffect(() => {
    if (isMount) {
      onChange(controlledValue === '' ? undefined : controlledValue);
    }
  }, [controlledValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e.target.value as string;
      setControlledValue(value);
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField select name={name} label={t('caracteristics.answer-label')} value={controlledValue || ''} onChange={handleChange} autoComplete="off">
        {options.map(option => (
          <MenuItem key={option} value={option} css={styles.option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <div css={styles.clearBtnContainer}>
        <Button variant="text" size="small" onClick={() => displayModal(name, position)}>
          {t('caracteristics.set-location-button')}
        </Button>
        {hasLocation ? (
          <Button variant="text" size="small" onClick={() => onEditLocationEnabled()}>
            {t('caracteristics.re-center-location-button')}
          </Button>
        ) : (
          <Button variant="text" size="small" onClick={() => onAddLocation()}>
            {t('caracteristics.add-location-button')}
          </Button>
        )}
        <Button variant="text" size="small" css={styles.clearBtn} onClick={() => (value ? setControlledValue(undefined) : onChange(undefined))}>
          {t('caracteristics.clear-button')}
        </Button>
      </div>
    </FormControl>
  );
};

export default SelectionCharacteristicType;
