import axios from 'axios';

import config from '../config';
import { validateToken } from '@utils/isTokenExpired';
import { GetTableSoilInfoRequestPayload, GetSsurgoLegendsResponsePayload } from '@mytypes/soil';

export const getSoilTableApi = async (payload: GetTableSoilInfoRequestPayload): Promise<string | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('table_guid', payload.tableGuid);
    formData.append('format', 'json');
    const { data } = await axios({
      url: `${config.REACT_APP_SOILS_SERVICE_URL}/export`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    return data as string;
  } catch (error) {
    console.log(error);
  }
};

export const getSsurgoLegendApi = async (mukeys: string[]): Promise<GetSsurgoLegendsResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    let formData = new FormData();
    formData.append('mukeys', mukeys.join(','));
    formData.append('format', 'json');
    const { data } = await axios({
      url: `${config.REACT_APP_SOILS_SERVICE_URL}/legend`,
      method: 'post',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    return data as GetSsurgoLegendsResponsePayload;
  } catch (error) {
    console.log(error);
  }
};
