import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import { blue } from '@mui/material/colors';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: calc(100vw - 64px);
    height: calc(100vh - 128px);
    position: absolute;
    top: 64px;
    left: 32px;
    overflow-y: scroll;
    border-radius: 4px;
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
  `,
  filter: css`
    padding: 0 12px 6px 12px;
  `,
  list: css`
    padding-top: 0;
  `,
  listItem: css`
    padding: 0;
  `,
  selectedItem: (theme: Theme) => css`
    backgroundColor: id === clientId ? theme.palette.primary.main : 'initial', padding: 0
  `,
  avatar: css`
    background-color: ${blue[100]};
    color: ${blue[600]};
  `
};
