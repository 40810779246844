import { css } from '@emotion/react';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import { Theme } from '@mui/material';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: calc(100vw - 64px);
    height: calc(100vh - 175px);
    border-radius: 4px;
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    transform: translate(-50%, -50%);
  `,
  scrollableArea: (theme: Theme) => css`
    flex-grow: 1;
    padding: 0 ${theme.spacing(2)};
    overflow-y: scroll;
  `,
  content: css`
    padding: 16px 24px;
  `,
  alert: css`
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
  `
};
