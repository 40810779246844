import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { getFieldVisitsByFieldIdWithDexie, getFieldWithDexie } from '@services/local';

export const useGetLocalFieldVisitsSteps = () => {
  return useAsyncCallback(
    useCallback(async (downloadId: number) => {
      try {
        const fieldLocalData = await getFieldWithDexie(downloadId);
        if (!fieldLocalData) return;

        const localVisits = await getFieldVisitsByFieldIdWithDexie(fieldLocalData.fieldId);
        let visits = [];
        if (fieldLocalData.visits) {
          for (const visit of fieldLocalData.visits) {
            const localVisit = localVisits.find(({ stepId }) => stepId === visit.stepId);
            visits.push({
              ...visit,
              ...(localVisit && { localId: localVisit.id })
            });
          }
        }
        return {
          createdAt: fieldLocalData.createdAt,
          growerId: fieldLocalData.growerId,
          planId: fieldLocalData.planId,
          updatedAt: fieldLocalData.updatedAt,
          visits
        };
      } catch (error) {
        console.log(error);
      }
    }, [])
  );
};
