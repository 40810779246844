/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  mode: string;
  onClose: () => void;
  onContinueAnyway: () => void;
}

const UnableAccessDeviceLocationModal = ({ mode, onClose, onContinueAnyway }: Props) => {
  const { t } = useTranslation();
  const { themeMode } = useSelector((state: RootState) => state.app);

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('location-not-available.title')}</DialogTitle>

      <DialogContent>
        <Typography css={styles.title}>{t('location-not-available.text')}</Typography>
      </DialogContent>

      <DialogActions>
        {mode === 'submit' ? (
          <>
            <Button onClick={onClose}>{t('location-not-available.cancel-button')}</Button>
            <Button onClick={() => onContinueAnyway()} variant="contained" autoFocus>
              {t('location-not-available.continue-button')}
            </Button>
          </>
        ) : (
          <Button onClick={onClose} variant="contained" autoFocus>
            {t('location-not-available.ok-button')}
          </Button>
        )}
      </DialogActions>
    </div>
  );
};

export default UnableAccessDeviceLocationModal;
