/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Button, Divider } from '@mui/material';

import { fieldCard } from '@components/Planner/FieldsList/FieldsList';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { setFields } from '@state-mgmt/slices/downloader-slice';
import { styles } from './styles';

export interface Props {
  onClose: () => void;
}

const SelectedFieldsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);
  const { fields: selectedFields } = useSelector((state: RootState) => state.downloader);
  const dispatch = useDispatch();

  const handleRemoveField = (field: fieldCard) => {
    const newFields = selectedFields.filter(
      ({ trialId, protocolId, fieldId }) => !(trialId === field.trialId && protocolId === field.protocolId && fieldId === field.fieldId)
    );
    dispatch(setFields(newFields));
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle css={styles.title(theme)}> {t('planner.create.select-fields-dialog-title')}</DialogTitle>
      {selectedFields.length > 0 && (
        <List css={styles.list(theme)}>
          {selectedFields
            .slice()
            .sort((a: fieldCard, b: fieldCard) => a.fieldName.localeCompare(b.fieldName))
            .map(field => (
              <>
                <ListItem disableGutters key={`${field.protocolId}-${field.fieldId}`}>
                  <ListItemText
                    primary={field.fieldName}
                    secondary={
                      <>
                        <Typography color="text.primary">
                          {field.trialName} - {field.protocolName}
                        </Typography>
                        <Typography color="text.primary" css={styles.growerName}>
                          {field.growerName}
                        </Typography>
                        <div css={styles.removeBtn}>
                          <Button variant="outlined" size="small" color="error" onClick={() => handleRemoveField(field)}>
                            {t('general.remove')}
                          </Button>
                        </div>
                      </>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))}
        </List>
      )}
    </div>
  );
};

export default SelectedFieldsModal;
