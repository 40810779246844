import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UploadOptionsModal } from '@components/UploadOptionsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';
import { UPLOAD_OPTION } from '@components/UploadOptionsModal/UploadOptionsModal';

export const useUploadOptionsModal = () => {
  const modalKey = 'upload-options-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (onSelect: (option: UPLOAD_OPTION) => void) => {
      const children = <UploadOptionsModal onSelect={onSelect} onClose={onClose} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
