/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useAppTheme } from '@hooks/useAppTheme';

import { styles } from './styles';

type FormData = { name: string };

export interface Props {
  value: string | undefined;
  submit: boolean;
  onSubmit: () => void;
  onSubmitComplete: (name: string) => void;
}

const NewPlanWizardFirstStep = ({ submit, value, onSubmit, onSubmitComplete }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const fromRef = useRef<HTMLFormElement>();

  const validateRequired = (value: string) => {
    return value.trim() !== '' || t('planner.create.name-required-message');
  };

  const validateLength = (value: string) => {
    return value.trim().length < 124 || t('planner.create.name-length-message');
  };
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue
  } = useForm<FormData>({ defaultValues: { name: value } });

  useEffect(() => {
    if (submit) {
      onSubmit();
      fromRef.current?.requestSubmit();
    }
  }, [submit]);

  useEffect(() => {
    if (value) {
      setValue('name', value);
    }
  }, [value]);

  const onSubmitHandler = async ({ name }: FormData) => {
    onSubmitComplete(name);
  };

  return (
    <div css={styles.container(theme)}>
      <Box ref={fromRef} css={styles.form(theme)} component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
        <Typography css={styles.title}>{t('planner.create.name-label')}</Typography>
        <FormControl fullWidth>
          <TextField
            autoFocus
            label=""
            autoComplete="off"
            type={'text'}
            error={!!errors['name']}
            helperText={errors['name'] ? errors['name'].message : ''}
            InputLabelProps={{ shrink: true }}
            {...register('name', {
              validate: {
                required: validateRequired,
                length: validateLength
              }
            })}
          />
        </FormControl>
      </Box>
    </div>
  );
};

export default NewPlanWizardFirstStep;
