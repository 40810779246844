/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CollectionsIcon from '@mui/icons-material/Collections';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onSelect: (option: UPLOAD_OPTION) => void;
  onClose: () => void;
}

export enum UPLOAD_OPTION {
  CAMERA = 'camera',
  GALLERY = 'gallery'
}

const UploadOptionsModal = ({ onSelect, onClose }: Props) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);

  const handleOnSelectOption = (option: UPLOAD_OPTION) => {
    onSelect(option);
    onClose();
  };

  return (
    <div css={styles.container(theme, themeMode)}>
      <DialogTitle>{t('upload-options.title')}</DialogTitle>

      <DialogContent css={styles.content}>
        <IconButton css={styles.iconContainer} onClick={() => handleOnSelectOption(UPLOAD_OPTION.CAMERA)}>
          <CameraAltIcon css={styles.icon} />
          <span css={styles.text}>{t('upload-options.camera-button')}</span>
        </IconButton>
        <IconButton css={styles.iconContainer} onClick={() => handleOnSelectOption(UPLOAD_OPTION.GALLERY)}>
          <CollectionsIcon css={styles.icon} />
          <span css={styles.text}>{t('upload-options.gallery-button')}</span>
        </IconButton>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('general.cancel')}</Button>
      </DialogActions>
    </div>
  );
};

export default UploadOptionsModal;
