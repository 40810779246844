import { css } from '@emotion/react';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: calc(100vw - 64px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    border-radius: 4px;
  `,
  title: css`
    text-align: center;
  `,
  loadingBtn: css`
    width: 165px;
  `
};
