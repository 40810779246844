import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MarkerDetailsModal } from '@components/MarkerDetailsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useMarkerDetailsModal = () => {
  const modalKey = 'marker-details-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (markerInfo: any) => {
      const children = <MarkerDetailsModal markerInfo={markerInfo} onClose={onClose} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
