import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (theme: Theme, themeMode: THEME_MODE) => css`
    row-gap: 24px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 100px ${theme.spacing(2)} 0 ${theme.spacing(2)};
    border-radius: 0;
    overflow: hidden;
    color: ${themeMode === THEME_MODE.DARK ? '#fff' : '#000'};
    background-color: ${themeMode === THEME_MODE.DARK ? '#1f2128' : '#f1f1f1'};
  `,
  icon: () => css`
    font-size: 75px;
  `,
  title: () => css`
    font-size: 22px;
  `,
  subTitle: () => css`
    font-size: 18px;
    text-align: center;
  `,
  detailsContainer: () => css`
    width: 90%;
  `,
  detailsButtonsContainer: () => css`
    display: flex;
    justify-content: end;
    padding-bottom: 8px;
  `,
  sendEmailBtn: () => css`
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    background: none;
  `,
  details: () => css`
    width: 100%;
    max-height: 200px;
    padding: 16px;
  `
};
