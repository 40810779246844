/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MenuItem, TextField } from '@mui/material';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onChange: (protocolId: string) => void;
}

const ProtocolFilter = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { currentProtocol, protocols } = useSelector((state: RootState) => state.protocol);
  const [selectedProtocol, setSelectedProtocol] = useState<string>(currentProtocol ? currentProtocol : 'all');

  useEffect(() => onChange(selectedProtocol), [selectedProtocol]);

  const protocolsDropdownValues = useMemo(() => {
    if (protocols && protocols.length) {
      return protocols.map(p => ({ id: p.id, name: p.name })).sort((a: any, b: any) => a.name.localeCompare(b.name));
    }
    return [];
  }, [protocols]);

  const onProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setSelectedProtocol(value);
  };

  return (
    <TextField
      select
      label={t('general.protocol')}
      fullWidth
      css={styles.container(theme)}
      defaultValue="all"
      onChange={onProtocolChange}
      value={selectedProtocol}
    >
      <MenuItem key="all" value="all" defaultChecked>
        All
      </MenuItem>
      {protocolsDropdownValues.map(protocol => (
        <MenuItem key={protocol.id} value={protocol.id}>
          {protocol.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ProtocolFilter;
