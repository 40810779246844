import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { Crop } from '@mytypes/protocol';
import { getProtocolCropsListApi } from '@services/protocol';
import { setCrops } from '@state-mgmt/slices/crop-slice';

export const useGetCrops = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async () => {
      try {
        const { crops } = (await getProtocolCropsListApi()) || {};
        if (!crops) return [];

        dispatch(setCrops(crops));
        localStorage.setItem('crops', JSON.stringify(crops));
        return crops as Crop[];
      } catch {
        const crops = JSON.parse(localStorage.getItem('crops') || '[]');
        dispatch(setCrops(crops));
        return crops as Crop[];
      }
    }, [])
  );
};
