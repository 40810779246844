import { configureStore } from '@reduxjs/toolkit';

import appReducer, { AppState } from '@state-mgmt/slices/appSlice';
import trialReducer, { TrialState } from '@state-mgmt/slices/trial-slice';
import protocolReducer, { ProtocolState } from '@state-mgmt/slices/protocol-slice';
import visitReducer, { VisitState } from '@state-mgmt/slices/visitSlice';
import clientReducer, { ClientState } from '@state-mgmt/slices/client-slice';
import downloaderReducer, { DownloaderState } from '@state-mgmt/slices/downloader-slice';
import notesReducer, { NotesState } from './slices/notesSlice';
import cropReducer, { CropState } from './slices/crop-slice';

export type RootState = {
  app: AppState;
  client: ClientState;
  crop: CropState;
  downloader: DownloaderState;
  notes: NotesState;
  protocol: ProtocolState;
  trial: TrialState;
  visit: VisitState;
};

export default configureStore({
  reducer: {
    app: appReducer,
    client: clientReducer,
    crop: cropReducer,
    downloader: downloaderReducer,
    notes: notesReducer,
    protocol: protocolReducer,
    trial: trialReducer,
    visit: visitReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
