import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    border-radius: 0;
  `,
  mapContainer: css`
    min-height: calc((var(--vh, 1vh) * 100) / 3);
    display: flex;
    flex-direction: column;
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `,
  mapOptionBtn: css`
    align-self: flex-end;
  `,
  locationBtnContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: ${theme.spacing(2)};
  `,
  actionsContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)};
  `,
  subtitle: (theme: Theme) => css`
    padding: ${theme.spacing(2)} 0;
  `,
  photolistContainer: css`
    border: 1px solid red;
  `,
  photolist: css`
    border: 1px solid blue;
    display: flex;
  `,
  photoThumbnail: css`
    height: 50px;
  `,
  actionsBtn: css`
    width: 100px;
  `,
  dialogText: css`
    font-weight: 500;
  `,

  photos: (theme: Theme) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: ${theme.spacing(2)};
  `,
  photosActions: css`
    display: flex;
    justify-content: center;
    background: #4d4d4d;
  `,
  thumbnail: css`
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 150px;
  `,
  downloadBtn: css`
    color: #fff;
    text-decoration: none;
  `,
  uploadContainer: css`
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 8px 0;
  `,
  uploadContainerText: css`
    flex-grow: 2;
  `
};
