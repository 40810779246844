export const getLocationValueFromWktPoint = (wkt_point: string) => {
  const regex = /POINT\s?\((-?\d+\.\d+)\s+(-?\d+\.\d+)\)/;
  const match = wkt_point.match(regex);

  if (!match || match.length !== 3) {
    throw new Error('Invalid string format');
  }

  const longitude = parseFloat(match[1]);
  const latitude = parseFloat(match[2]);

  return {
    latitude: latitude,
    longitude: longitude
  };
};
