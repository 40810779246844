interface Resolution {
  width: number;
  height: number;
}

export enum resolution {
  '144p', // width: 256 x height: 144
  '240p', // width: 426 x height: 240
  '360p', // width: 640 x height: 360
  '480p', // width: 854 x height: 480
  '720p', // width: 1280 x height: 720
  '1080p' // width: 1920 x height: 1080
}

const resolutions: Record<string, Resolution> = {
  '144p': { width: 256, height: 144 },
  '240p': { width: 426, height: 240 },
  '360p': { width: 640, height: 360 },
  '480p': { width: 854, height: 480 },
  '720p': { width: 1280, height: 720 },
  '1080p': { width: 1920, height: 1080 }
};

export const resizeImage = (image: File, resolution: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const { height } = resolutions[resolution];

      const ratio = height / img.height;
      const newWidth = img.width * ratio;

      canvas.width = newWidth;
      canvas.height = height;

      // @ts-ignore
      ctx.drawImage(img, 0, 0, newWidth, height);
      resolve(canvas.toDataURL('image/jpeg')); // Convert the image to base64
    };

    img.onerror = reject;
    img.src = URL.createObjectURL(image);
  });
};
