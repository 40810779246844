import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: calc(100vw - 64px);
    height: calc(100vh - 128px);
    position: absolute;
    top: 64px;
    left: 32px;
    overflow-y: scroll;
    border-radius: 4px;
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
  `,
  title: (theme: Theme) => css`
    padding: ${theme.spacing(2)};
    text-align: center;
  `,
  list: (theme: Theme) => css`
    padding: ${theme.spacing(2)};
  `,
  growerName: css`
    flex-grow: 2;
  `,
  removeBtn: css`
    display: flex;
    justify-content: end;
  `
};
