/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button, DialogActions, DialogTitle, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAppTheme } from '@hooks/useAppTheme';
import { AlertQuestionsBySection } from '@mytypes/fieldVisitWizard';
import { RootState } from '@state-mgmt/store';
import { convertNumberToWord } from '@utils/convertNumberToWord';
import { styles } from './styles';

export interface Alerts {
  optionals: number;
  optionalsList: AlertQuestionsBySection[];
  required: number;
  requiredList: AlertQuestionsBySection[];
}

export interface Props {
  alerts: Alerts;
  onClose: (readyToSubmit: boolean) => void;
}

const FieldVisitAlertsModal = ({ alerts, onClose }: Props) => {
  const { t } = useTranslation();

  const { themeMode } = useSelector((state: RootState) => state.app);
  const theme = useAppTheme({});

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('field-visit-alerts.title', { alertsCount: convertNumberToWord(Object.values(alerts).length) })}</DialogTitle>
      <div css={styles.scrollableArea(theme)}>
        <div css={styles.content}>
          {alerts.required > 0 && (
            <div>
              <div css={styles.alert}>
                <DangerousIcon color="error" />
                <Typography fontSize={14}>
                  {alerts.required > 1
                    ? t('field-visit-alerts.many-question-required-not-completed-message', { questionsCount: alerts.required })
                    : t('field-visit-alerts.one-question-required-not-completed-message', { questionsCount: alerts.required })}
                </Typography>
              </div>
              <div style={{ margin: '16px 0 24px 0' }}>
                <div style={{ flexGrow: 2 }}>
                  {alerts.requiredList.map(v => (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{v.sectionLabel}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingTop: 0 }}>
                        <ul style={{ margin: 0 }}>
                          {v?.questions?.map(q => (
                            <li>{q}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          )}
          {alerts.optionals > 0 && (
            <div>
              <div css={styles.alert}>
                <WarningIcon color="warning" />
                <Typography fontSize={14}>
                  {alerts.optionals > 1
                    ? t('field-visit-alerts.many-question-optional-not-completed-message', { questionsCount: alerts.optionals })
                    : t('field-visit-alerts.one-question-optional-not-completed-message', { questionsCount: alerts.optionals })}
                </Typography>
              </div>
              <div style={{ margin: '16px 0 24px 0' }}>
                <div style={{ flexGrow: 2 }}>
                  {alerts.optionalsList.map(v => (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{v.sectionLabel}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingTop: 0 }}>
                        <ul style={{ margin: 0 }}>
                          {v?.questions?.map(q => (
                            <li>{q}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Revise Visit</Button>
        <Button variant="contained" disabled={alerts.required > 0} onClick={() => onClose(true)}>
          {alerts.required > 0 ? t('field-visit-alerts.submit-button') : t('field-visit-alerts.submit-anyway-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default FieldVisitAlertsModal;
