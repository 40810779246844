/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, Paper, Typography } from '@mui/material';
import List from '@mui/material/List';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { FieldPlan } from '@mytypes/local';
import { getFieldByProtocolWithDexie, getFieldVisitPlanWithDexie } from '@services/local';
import { styles } from './styles';

const PlanFieldVisits = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { planId } = useParams();
  const navigate = useNavigate();

  const [planName, setPlanName] = useState<string>();
  const [fieldVisits, setFieldVisits] = useState<FieldPlan[]>([]);

  useEffect(() => {
    if (planId) {
      getFieldVisitPlanWithDexie(Number(planId)).then(result => {
        if (result) {
          setPlanName(result.name);
          setFieldVisits(result.fields);
        }
      });
    }
  }, [planId]);

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={planName || 'no name'} onGoBackBtnPress={() => navigate(`/planner`)} />
      <div css={styles.scrollableArea(theme)}>
        <List>
          {fieldVisits.map(({ fieldId, fieldName, growerId, growerName, protocolId, protocolName, trialName }, index) => (
            <Card key={index} elevation={0} css={styles.card(theme)}>
              <Typography color="text.primary" css={styles.cardTitle(theme)}>
                {fieldName}
              </Typography>
              <CardContent css={styles.cardActions}>
                <Typography color="text.primary" fontSize={14}>
                  {t('general.trial')}: <span css={styles.cardActions}> {trialName}</span>
                </Typography>
                <Typography color="text.primary" fontSize={14}>
                  {t('general.protocol')}: <span css={styles.cardActions}>{protocolName}</span>
                </Typography>
                <Typography color="text.primary" fontSize={14}>
                  {t('general.grower')}: <span css={styles.cardActions}> {growerName}</span>
                </Typography>
              </CardContent>

              <CardActions css={styles.cardActions}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={async () => {
                    const field = await getFieldByProtocolWithDexie(fieldId, protocolId);
                    if (field) {
                      navigate(`/field-visits/${fieldId}/${protocolId}/${field.id}?plan_id=${planId}&grower_id=${growerId}`);
                    }
                  }}
                >
                  {t('general.go')}
                </Button>
              </CardActions>
            </Card>
          ))}
        </List>
      </div>
    </Paper>
  );
};

export default PlanFieldVisits;
