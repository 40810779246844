/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { Typography, Button, DialogActions, DialogTitle, FormControlLabel, Checkbox, Card, CardContent } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import CircleIcon from '@mui/icons-material/Circle';
import Dayjs from 'dayjs';

import { useIsMount } from '@hooks/useIsMount';
import useSsurgoLabel from '@hooks/useSsurgoLabel';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { setActiveZones, setPreviousVisitsSelection } from '@state-mgmt/slices/visitSlice';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

export enum MAP_LAYERS {
  TRIAL_AREA = 'trial-area',
  TREATMENT_ZONES = 'treatment-zones',
  SURGO = 'surgo'
}

export interface Props {
  handleMarkersView: boolean;
  treatmentZones: any[];
  selectedLayers: string[];
  onMapLayersChange: (mapLayers: string[]) => void;
  onClose: () => void;
}

const FieldMapOptionsModal = ({ handleMarkersView, treatmentZones, selectedLayers, onMapLayersChange, onClose }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const theme = useAppTheme({});
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const { themeMode } = useSelector((state: RootState) => state.app);
  const { activeZones, previousVisitsSelection } = useSelector((state: RootState) => state.visit);

  const ssurgoLabel = useSsurgoLabel();
  const [controlledValue, setControlledValue] = useState<string[]>(selectedLayers || []);
  const [controlledZones, setControlledZones] = useState<any[]>(activeZones || []);
  const [showAllMarkers, setShowAllMarkers] = useState<boolean>(activeZones.every(az => az.active));
  const [controlledPreviuosVisits, setControlledPreviuosVisits] = useState<any[]>(previousVisitsSelection);

  useEffect(() => {
    onMapLayersChange(controlledValue);
  }, [controlledValue]);

  useEffect(() => {
    if (isMount) {
      if (!showAllMarkers) {
        setControlledZones(controlledZones.map(cz => ({ ...cz, active: false })));
      } else {
        setControlledZones(controlledZones.map(cz => ({ ...cz, active: true })));
      }
    }
  }, [showAllMarkers]);

  const handleOnMapLayersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.value as string;
      controlledValue.includes(value)
        ? setControlledValue(controlledValue.filter((mapLayer: string) => mapLayer !== value))
        : setControlledValue([...controlledValue, value]);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnPreviousVisitSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.value as string;
      const result = controlledPreviuosVisits.map(previousVisit =>
        previousVisit.id === value ? { ...previousVisit, selected: !previousVisit.selected } : previousVisit
      );
      setControlledPreviuosVisits(result);
    } catch (error) {
      showBoundary(error);
    }
  };

  const onOkBtnClicked = () => {
    try {
      dispatch(setActiveZones(controlledZones));
      dispatch(setPreviousVisitsSelection(controlledPreviuosVisits));
      onClose();
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('map-options.title')}</DialogTitle>
      <div css={styles.scrollableArea(theme)}>
        <Card variant="outlined" css={styles.card(theme)}>
          <CardContent>
            <Typography variant="h6" component="div">
              {t('map-options.layers-title')}
            </Typography>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox value={MAP_LAYERS.TRIAL_AREA} checked={controlledValue.includes(MAP_LAYERS.TRIAL_AREA)} onChange={handleOnMapLayersChange} />
                }
                label={<Typography sx={{ fontSize: '14px' }}>{t('map-options.trial-area-label')}</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={MAP_LAYERS.TREATMENT_ZONES}
                    checked={controlledValue.includes(MAP_LAYERS.TREATMENT_ZONES)}
                    onChange={handleOnMapLayersChange}
                  />
                }
                label={<Typography sx={{ fontSize: '14px' }}>{t('map-options.treatment-zones-label')}</Typography>}
              />

              <FormControlLabel
                control={<Checkbox value="surgo" checked={controlledValue.includes('surgo')} onChange={handleOnMapLayersChange} />}
                label={<Typography sx={{ fontSize: '14px' }}>{ssurgoLabel}</Typography>}
              />

              {controlledPreviuosVisits.map(visit => (
                <FormControlLabel
                  control={<Checkbox value={visit.id} checked={visit.selected} onChange={handleOnPreviousVisitSelectionChange} />}
                  label={
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <CircleIcon
                        style={{
                          color: visit.color,
                          fontSize: '18px',
                          zIndex: 930
                        }}
                      />
                      <Typography sx={{ fontSize: '14px' }}>
                        {visit.label} ({Dayjs(visit.completedAt).format('MM/DD/YYYY')})
                      </Typography>
                    </div>
                  }
                />
              ))}
            </div>
          </CardContent>
        </Card>

        {treatmentZones.length > 0 && (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" component="div">
                {handleMarkersView ? t('map-options.treatment-zones-localtions-label') : t('map-options.treatment-zones-label')}
              </Typography>

              <div css={styles.treatmentZonesContainer}>
                {handleMarkersView && (
                  <div css={styles.treatmentZoneHeader}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={controlledZones.every(cz => cz.active)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowAllMarkers(!showAllMarkers)}
                        />
                      }
                      label={t('map-options.show-all-markers-button')}
                    />
                  </div>
                )}
                {handleMarkersView
                  ? controlledZones.map((treatmentZone: any) => (
                      <div css={styles.treatmentZone} key={treatmentZone.color}>
                        <SquareIcon css={styles.treatmentZoneBullet(treatmentZone.color)} />
                        <Typography css={styles.treatmentZoneLabel}>{treatmentZone.label}</Typography>
                        <Checkbox
                          edge="end"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setControlledZones(controlledZones.map(cz => (cz.zone_id === treatmentZone.zone_id ? { ...cz, active: !cz.active } : cz)));
                          }}
                          checked={treatmentZone.active}
                        />
                      </div>
                    ))
                  : treatmentZones.map((treatmentZone: any) => (
                      <div css={styles.treatmentZone} key={treatmentZone.color}>
                        <SquareIcon css={styles.treatmentZoneBullet(treatmentZone.color)} />
                        <Typography css={styles.treatmentZoneLabel}>{treatmentZone.label}</Typography>
                      </div>
                    ))}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      <DialogActions>
        <Button variant="contained" onClick={onOkBtnClicked}>
          {t('map-options.ok-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default FieldMapOptionsModal;
