import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { createHelpDeskTicket } from '@services/fdc';
import { useSelector } from 'react-redux';
import { RootState } from '@state-mgmt/store';
import { helpdeskUploadApi } from '@services/user';

export const useCreateHelpDeskTicket = () => {
  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const { clients } = useSelector((state: RootState) => state.client);
  const { trials } = useSelector((state: RootState) => state.trial);

  const client = useMemo(() => clients.find(client => client.id === clientId), [clients, clientId]);
  const trial = useMemo(() => trials.find(trial => trial.id === trialId), [trials, trialId]);

  return useAsyncCallback(
    useCallback(async (message: string, uploads: File[] = []) => {
      try {
        const uploadsPromises = uploads.map((upload: File) => new Promise(resolve => resolve(helpdeskUploadApi({ file: upload }))));
        const uploadsPromisesResult = await Promise.all(uploadsPromises);
        const uploadsUrls = uploadsPromisesResult.map((result: any) => result.url);

        const data = await createHelpDeskTicket({
          clientName: client?.name || '',
          trialName: trial?.name || '',
          message,
          uploadsUrls
        });
        return data;
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
};
