import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { TrialGroupUser } from '@mytypes/trial';
import { getTrialGrowersMembersApi } from '@services/trial';
import { setTrialGrowers, setTrialOperatives } from '@state-mgmt/slices/trial-slice';
import { USER_ROLE } from '@mytypes/user';

export const useGetTrialGrowerMembers = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (trialId: string) => {
      try {
        const { members: trialGrowers } = (await getTrialGrowersMembersApi(trialId, USER_ROLE.TRIAL_GROWER)) || {};

        if (!trialGrowers) return [];
        localStorage.setItem('members', JSON.stringify(trialGrowers));
        dispatch(setTrialGrowers(trialGrowers));

        const { members: trialOperatives } = (await getTrialGrowersMembersApi(trialId, USER_ROLE.TRIAL_OPERATIVE)) || {};

        if (!trialOperatives) return [];
        localStorage.setItem('trial-operatives', JSON.stringify(trialOperatives));
        dispatch(setTrialOperatives(trialOperatives));
      } catch {
        const trialGrowers = JSON.parse(localStorage.getItem('members') || '[]') as TrialGroupUser[];
        dispatch(setTrialGrowers(trialGrowers));

        const trialOperatives = JSON.parse(localStorage.getItem('trialOperatives') || '[]') as TrialGroupUser[];
        dispatch(setTrialGrowers(trialOperatives));
      }
    }, [])
  );
};
