import axios from 'axios';

import { GetFieldsByGrowerRequestPayload, GetFieldsByGrowerResponsePayload, CreateFieldRequestPayload, CreateFieldResponsePayload } from '@mytypes/field';
import { validateToken } from '@utils/isTokenExpired';
import config from '../config';

export const getFieldsByGrowerApi = async (payload: GetFieldsByGrowerRequestPayload): Promise<GetFieldsByGrowerResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }
    const { data } = await axios({
      url: `${config.REACT_APP_FIELD_SERVICE_URL}/v2/all?user_id=${payload.userId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data as GetFieldsByGrowerResponsePayload;
  } catch (error: any) {
    console.log(error);
  }
};

export const createFieldApi = async (payload: CreateFieldRequestPayload): Promise<CreateFieldResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_FIELD_SERVICE_URL}/v2/create`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload
    });
    return data as CreateFieldResponsePayload;
  } catch (error) {
    console.log(error);
    throw new Error('something went wrong');
  }
};
