/** @jsxImportSource @emotion/react */
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useAppTheme } from '@hooks/useAppTheme';

import { styles } from './styles';
import React from 'react';

export interface Props {
  hideGoBackBtn?: boolean;
  rightBtn?: React.ReactNode;
  showRefreshBtn?: boolean;
  showViewNearFieldsBtn?: boolean;
  title: string;
  subtitle?: string;
  onGoBackBtnPress?: () => void;
}

const ViewHeader = ({ hideGoBackBtn = false, rightBtn, showRefreshBtn = false, showViewNearFieldsBtn = false, title, subtitle, onGoBackBtnPress }: Props) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div css={styles.container}>
      <div css={[styles.claimSpace, styles.claimSpaceLeft]}>
        {!hideGoBackBtn && (
          <IconButton aria-label="delete" onClick={() => (onGoBackBtnPress ? onGoBackBtnPress() : navigate(-1))}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {hideGoBackBtn && (
          <div css={styles.logoContainer}>
            <img src="https://in10t-static.s3.us-east-2.amazonaws.com/INTENT_Favicon_Full-Color.svg" alt="logo" css={styles.logo} />
          </div>
        )}
      </div>

      <div>
        <Typography color="text.primary" css={styles.title(theme)}>
          {title}
        </Typography>
        {subtitle && (
          <Typography color="text.primary" css={styles.subtitle(theme)}>
            {subtitle}
            {/* {t('header.operative-role')} */}
          </Typography>
        )}
      </div>

      <div css={[styles.claimSpace, styles.claimSpaceRight]}>
        {rightBtn && <div css={styles.btnContainer}>{rightBtn}</div>}
        {showRefreshBtn && (
          <IconButton color="primary" onClick={() => navigate(`/splash?redirectTo=${location.pathname}`)}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ViewHeader;
