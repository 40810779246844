/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Map, { MapRef, Marker, Popup } from 'react-map-gl';
import * as turf from '@turf/turf';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useTranslation } from 'react-i18next';
import 'react-spring-bottom-sheet/dist/style.css';
import './customBottomSheet.css';
import { Button, Chip, Fab, List, ListItem, ListItemText, Paper } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import NearMeIcon from '@mui/icons-material/NearMe';

import { ViewHeader } from '@components/ViewHeader';
import GeojsonPolygonLayer from '@components/MapView/GeojsonPolygonLayer';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetCurrentLocation } from '@hooks/useGetCurrentLocation';
import { MapViewState } from '@mytypes/map';
import { RootState } from '@state-mgmt/store';
import { wait } from '@utils/wait';
import { convertHexToArray } from '@utils/colorUtility';
import config from '../../../config';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { NearbyField } from '@mytypes/trial';

const TrialFieldsMap = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const mapRef = useRef<MapRef>();

  const getCurrentLocation = useGetCurrentLocation();
  const [isGeolocated, setIsGeolocated] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const [selectedField, setSelectedField] = useState<NearbyField | null>(null);

  const defaultViewState: MapViewState = {
    longitude: -100,
    latitude: 40,
    zoom: 4
  };

  const positionZoom = 10;

  const { currentPosition } = useSelector((state: RootState) => state.app);
  const { nearbyFieldsRatioDistance, nearbyFieldsRatioUOM } = useSelector((state: RootState) => state.app);
  const { nearbyFields } = useSelector((state: RootState) => state.trial);
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (currentPosition && !isGeolocated) {
      // hack for waiting for the map initialized
      wait(0).then(() => {
        mapRef.current?.flyTo({
          center: [currentPosition.longitude, currentPosition.latitude],
          zoom: positionZoom,
          essential: true
        });
        setIsGeolocated(true);
      });
    }
  }, [currentPosition]);

  const circle = useMemo(() => {
    if (currentPosition && nearbyFieldsRatioDistance) {
      return turf.circle([currentPosition.longitude, currentPosition.latitude], nearbyFieldsRatioDistance, {
        steps: 50,
        units: nearbyFieldsRatioUOM === 'miles' ? 'miles' : 'kilometers',
        properties: { foo: 'bar' }
      });
    }
  }, [currentPosition, nearbyFieldsRatioDistance, nearbyFieldsRatioUOM]);

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('trial-field-map.title')} hideGoBackBtn={true} showRefreshBtn={false} />
      <Map
        attributionControl={false}
        initialViewState={defaultViewState}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken={config.REACT_APP_MAPBOX_ACCESS_TOKEN}
        // @ts-ignore
        ref={mapRef}
        onError={e => console.log('Map.onError ', e)}
      >
        {nearbyFields.map((nearbyField, index) => (
          <Marker
            anchor="center"
            key={`field-location-${index}`}
            longitude={nearbyField.longitude}
            latitude={nearbyField.latitude}
            onClick={() => {
              mapRef.current?.flyTo({
                center: [nearbyField.longitude, nearbyField.latitude],
                zoom: positionZoom,
                essential: true
              });
              setSelectedField(nearbyField);
            }}
          />
        ))}

        {currentPosition && (
          <Marker longitude={currentPosition.longitude} latitude={currentPosition.latitude} style={{ zIndex: 999 }}>
            <RadioButtonCheckedIcon />
          </Marker>
        )}

        {selectedField !== null && (
          <Popup
            anchor="bottom"
            latitude={selectedField.latitude}
            longitude={selectedField.longitude}
            onClose={() => setSelectedField(null)}
            closeOnClick={false}
            css={styles.popup}
          >
            <div style={{ color: 'black', display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
              <span style={{ fontSize: '20px', paddingBottom: '8px', textTransform: 'capitalize' }}>{selectedField.name}</span>
              <span style={{ fontSize: '16px' }}>
                <em style={{ fontWeight: 'bold', fontStyle: 'normal' }}>{t('general.grower')}:</em> {selectedField.growerName}
              </span>
              <span style={{ fontSize: '16px' }}>
                <em style={{ fontWeight: 'bold', fontStyle: 'normal' }}>{t('general.crop')}:</em> {selectedField.cropName}
              </span>
              <span style={{ fontSize: '16px' }}>
                <em style={{ fontWeight: 'bold', fontStyle: 'normal' }}>{t('general.distance')}:</em>{' '}
                {nearbyFieldsRatioUOM === 'miles' ? `${selectedField.distanceMi?.toFixed(2)} mi` : `${selectedField.distanceKm?.toFixed(2)} Km`}
              </span>
            </div>

            <Button
              focusRipple={false}
              variant="contained"
              onClick={() => navigate(`/grower/${selectedField.growerId}?backTo=${encodeURIComponent('/trial-fields-map')}`)}
            >
              {t('trial-field-map.grower-fields-button')}
            </Button>
          </Popup>
        )}

        {circle && (
          <GeojsonPolygonLayer
            geometry={circle}
            filled={true}
            lineWidthScale={0}
            lineWidth={0}
            getFillColor={() => convertHexToArray('#42f545', 0.1)}
            getLineColor={() => convertHexToArray('#42f545', 0.4)}
          />
        )}

        {currentPosition && (
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 10
            }}
            onClick={() => {
              mapRef.current?.flyTo({
                center: [currentPosition.longitude, currentPosition.latitude],
                zoom: positionZoom,
                essential: true
              });
            }}
          >
            <NearMeIcon />
          </Fab>
        )}

        <Button
          variant="contained"
          style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2' }}
          onClick={() => setOpen(true)}
        >
          {t('trial-field-map.nearby-fields-list-button')}
        </Button>
      </Map>

      <BottomSheet
        expandOnContentDrag={true}
        open={open}
        onDismiss={() => setOpen(false)}
        defaultSnap={({ maxHeight }) => maxHeight / 4}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
        style={{ background: 'red !important' }}
        className="custom-sheet-background"
      >
        <List>
          {nearbyFields.map(nearbyField => (
            <ListItem
              divider={true}
              key={nearbyField.fieldId}
              onClick={() => {
                setOpen(false);
                setSelectedField(nearbyField);
                mapRef.current?.flyTo({
                  center: [nearbyField.longitude, nearbyField.latitude],
                  zoom: positionZoom,
                  essential: true
                });
              }}
              secondaryAction={
                <Chip
                  sx={{ borderRadius: '4px', minWidth: '100px', padding: 0 }}
                  variant={
                    (nearbyFieldsRatioUOM === 'miles' && nearbyField.distanceMi && nearbyField.distanceMi < nearbyFieldsRatioDistance) ||
                    (nearbyFieldsRatioUOM === 'kilometers' && nearbyField.distanceKm && nearbyField.distanceKm < nearbyFieldsRatioDistance)
                      ? 'filled'
                      : 'outlined'
                  }
                  color={
                    (nearbyFieldsRatioUOM === 'miles' && nearbyField.distanceMi && nearbyField.distanceMi < nearbyFieldsRatioDistance) ||
                    (nearbyFieldsRatioUOM === 'kilometers' && nearbyField.distanceKm && nearbyField.distanceKm < nearbyFieldsRatioDistance)
                      ? 'success'
                      : 'warning'
                  }
                  label={nearbyFieldsRatioUOM === 'miles' ? `${nearbyField.distanceMi?.toFixed(2)} mi` : `${nearbyField.distanceKm?.toFixed(2)} Km`}
                />
              }
            >
              <ListItemText primary={`${nearbyField.name} - ${nearbyField.cropName}`} secondary={nearbyField.growerName} sx={{ marginRight: '75px' }} />
            </ListItem>
          ))}
        </List>
      </BottomSheet>
    </Paper>
  );
};

export default TrialFieldsMap;
