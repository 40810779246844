import { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

interface Props {
  mode?: PaletteMode;
}

export const useAppTheme = ({ mode = 'light' }: Props) => {
  return useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ['Plus Jakarta Sans'].join(','),
          fontWeightRegular: 600
        },
        palette: {
          mode: mode,
          primary: {
            main: '#06B7FF'
          },
          secondary: {
            main: '#84818A'
          },
          background: {
            paper: mode === THEME_MODE.DARK ? '#1F2128' : '#fff',
            default: mode === THEME_MODE.DARK ? '#1F2128' : '#fff'
          }
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none'
              }
            }
          },
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: mode === THEME_MODE.DARK ? '#1F2128' : '#fff',
                backgroundImage: 'none'
              }
            }
          }
        }
      }),
    [mode]
  );
};
