/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import Dayjs from 'dayjs';

import { Button, Card, CardActions, CardContent, IconButton, List, Paper, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import EditIcon from '@mui/icons-material/Edit';

import { ViewHeader } from '@components/ViewHeader';
import { ToastType } from '@components/ui/Toast';
import { DownloadFieldDataBanner } from '@components/DownloadFieldDataBanner';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetLocalFieldVisitsSteps } from '@hooks/useGetLocalFieldVisitsSteps';
import { useSubmitsFieldData } from '@hooks/useSubmitsFieldData';
import { useGetFieldVisitsSteps } from '@hooks/useGetFieldVisitsSteps';
import { useToast } from '@hooks/app/useToast';
import { STEP_STATUS } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

const FieldVisits = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const showToast = useToast();

  const navigate = useNavigate();
  const { fieldId, protocolId, downloadId } = useParams();
  const [searchParams] = useSearchParams();
  const { online, trialId } = useSelector((state: RootState) => state.app);
  const { fields } = useSelector((state: RootState) => state.trial);
  const [submitsFieldDataState, submitsFieldData] = useSubmitsFieldData();
  const [getFieldVisitsStepsState, getFieldVisitsSteps] = useGetFieldVisitsSteps();
  const [getLocalFieldVisitsStepsState, getLocalFieldVisitsSteps] = useGetLocalFieldVisitsSteps();
  const [downloadLastUpdate, setDownloadLastUpdate] = useState<any>();
  const [data, setData] = useState<any[]>();

  useEffect(() => {
    if (downloadId) {
      getLocalFieldVisitsSteps(Number(downloadId));
    } else {
      if (trialId && protocolId && fieldId) {
        const field = fields.find(field => field.field_id === fieldId && field.protocol_id === protocolId);
        if (field) {
          getFieldVisitsSteps(trialId, protocolId, fieldId);
        }
      }
    }
  }, [fieldId, protocolId, downloadId]);

  useEffect(() => {
    if (getFieldVisitsStepsState.data && getFieldVisitsStepsState.data.length) {
      setData(getFieldVisitsStepsState.data);
    }
  }, [getFieldVisitsStepsState]);

  useEffect(() => {
    if (getLocalFieldVisitsStepsState.data) {
      const { visits, updatedAt } = getLocalFieldVisitsStepsState.data;
      setData(visits);
      setDownloadLastUpdate(updatedAt);
    }
  }, [getLocalFieldVisitsStepsState]);

  useEffect(() => {
    if (trialId && protocolId && fieldId && submitsFieldDataState.isSuccess) {
      if (downloadId) {
        getLocalFieldVisitsSteps(Number(downloadId));
      } else {
        getFieldVisitsSteps(trialId, protocolId, fieldId);
      }
    }
  }, [fieldId, protocolId, submitsFieldDataState.isSuccess]);

  useEffect(() => {
    if (submitsFieldDataState.error) {
      showToast({ type: ToastType.ERROR, children: t('field-visits.error-message') });
    }
  }, [submitsFieldDataState.error]);

  const onSyncBtnPress = (localId: number) => {
    if (!fieldId || !protocolId) return;
    submitsFieldData(localId);
  };

  const onStartBtnPress = (stepId: string) => {
    let url = `/field-visits/${fieldId}/${protocolId}/start/${stepId}`;
    if (downloadId) {
      url += `/${downloadId}`;
    }
    url += `?grower_id=${searchParams.get('grower_id')}`;
    if (searchParams.get('plan_id')) {
      url += `&plan_id=${searchParams.get('plan_id')}`;
    }
    if (searchParams.get('operative')) {
      url += `&operative=${searchParams.get('operative')}`;
    }
    navigate(url);
  };

  const switchFromDownloadToOnline = () => {
    const redirectTo = encodeURIComponent(`/field-visits/${fieldId}/${protocolId}?grower_id=${searchParams.get('grower_id')}`);
    navigate(`/splash?redirectTo=${redirectTo}`);
  };

  const handleOnGoBackBtnPress = () => {
    const planId = searchParams.get('plan_id');
    if (downloadId && planId) {
      navigate(`/planner/${planId}`);
    } else {
      let url = `/grower/${searchParams.get('grower_id')}`;
      url += searchParams.get('operative') ? `?operative=${searchParams.get('operative')}` : '';
      navigate(url);
    }
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('field-visits.title')} onGoBackBtnPress={handleOnGoBackBtnPress} />
      <div css={styles.scrollableArea(theme)}>
        <LoadingOverlay
          spinner
          active={getFieldVisitsStepsState.isLoading}
          text={t('field-visits.loading-message')}
          styles={{ wrapper: styles.listLoadingOverlayContent }}
        >
          {!getFieldVisitsStepsState.isLoading && (!data || data?.length === 0) ? (
            <Typography color="text.secondary" css={styles.emptyStateMessage(theme)}>
              {t('field-visits.empty-state-text')}
            </Typography>
          ) : (
            <>
              {downloadId && <DownloadFieldDataBanner downloadLastUpdate={downloadLastUpdate} onSwitchBtnPress={() => switchFromDownloadToOnline()} />}
              <List>
                {data?.map((step: any) => (
                  <Card key={step.stepId} elevation={0} css={styles.visitCard(theme)}>
                    <div css={styles.visitCardHeader}>
                      <Typography color="text.primary" css={styles.visitCardHeaderTitle(theme)}>
                        {step.label}
                      </Typography>
                      <div css={styles.visitCardActionsHeader(theme)}>
                        {step.mainActionCompleted === STEP_STATUS.COMPLETE && (
                          <IconButton size="small" onClick={() => onStartBtnPress(step.stepId)} disabled={!online && !downloadId}>
                            <EditIcon />
                          </IconButton>
                        )}
                        {step.mainActionCompleted === STEP_STATUS.COMPLETE && (
                          <CheckCircleOutlineIcon color={step.localId ? 'disabled' : 'success'} css={styles.visitCardActionsHeaderIcon} />
                        )}
                        {[STEP_STATUS.INCOMPLETE, STEP_STATUS.PENDING].includes(step.mainActionCompleted) && !step.localId && (
                          <ReportGmailerrorredIcon color="warning" css={styles.visitCardActionsHeaderIcon} />
                        )}
                        {step.mainActionCompleted === STEP_STATUS.INCOMPLETE && step.localId && (
                          <CheckCircleOutlineIcon color="disabled" css={styles.visitCardActionsHeaderIcon} />
                        )}
                      </div>
                    </div>

                    <CardContent css={styles.visitCardContent(theme)}>
                      <>
                        {step.mainActionCompleted === STEP_STATUS.COMPLETE && (
                          <>
                            <Typography color="text.primary" fontSize={14}>
                              {t('field-visits.completed-by-label')}: {step.completedBy}
                            </Typography>

                            <Typography color="text.primary" fontSize={14}>
                              {t('field-visits.completed-date-label')}: {Dayjs(step.completedAt).format('MM/DD/YYYY')}
                            </Typography>
                          </>
                        )}
                        {step.localId && downloadId && (
                          <Typography color="text.primary" fontSize={14}>
                            {t('field-visits.unsync-message')}
                          </Typography>
                        )}
                      </>
                    </CardContent>

                    <CardActions css={styles.visitCardActions}>
                      {step.localId && (
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => onSyncBtnPress(step.localId)}
                          disabled={submitsFieldDataState.isLoading || !online}
                        >
                          {submitsFieldDataState.isLoading ? t('field-visits.syncing-message') : t('field-visits.sync-button')}
                        </Button>
                      )}
                      {!step.localId && [STEP_STATUS.INCOMPLETE, STEP_STATUS.PENDING].includes(step.mainActionCompleted) && (
                        <Button size="small" variant="contained" onClick={() => onStartBtnPress(step.stepId)} disabled={!online && !downloadId}>
                          {t('field-visits.start-button')}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                ))}
              </List>
            </>
          )}
        </LoadingOverlay>
      </div>
    </Paper>
  );
};

export default FieldVisits;
