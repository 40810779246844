import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { getTrialFieldsListApi } from '@services/trial';
import { setFields } from '@state-mgmt/slices/trial-slice';
import { parseTrialFields } from '@utils/parseTrialFields';
import { TrialField } from '@mytypes/trial';

export const useGetFields = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (trialId: string) => {
      try {
        const { protocol_fields } = (await getTrialFieldsListApi(trialId)) || {};
        if (!protocol_fields) return [];
        const result = parseTrialFields(protocol_fields);
        localStorage.setItem('fields', JSON.stringify(result));
        dispatch(setFields(result));
        return result;
      } catch {
        const fields = JSON.parse(localStorage.getItem('fields') || '[]') as TrialField[];
        dispatch(setFields(fields));
        return fields;
      }
    }, [])
  );
};
