/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Paper, Typography } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import logoDark from '@assets/images/logo-dark.png';
import logoLight from '@assets/images/logo-light.png';
import { styles } from './styles';

const Updating = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    window.location.href = '/';
  }, []);

  return (
    <Paper elevation={2} css={styles.container}>
      <img src={themeMode === THEME_MODE.DARK ? logoDark : logoLight} alt="logo" css={styles.image} />

      <Typography color="text.secondary" css={styles.text(theme)}>
        {t('updating.message')}
      </Typography>
    </Paper>
  );
};

export default Updating;
