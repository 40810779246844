import { refreshTokenApi } from '@services/fdc';
import jwt_decode from 'jwt-decode';

export const validateToken = async (): Promise<boolean> => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    // @ts-ignore
    const { exp } = jwt_decode(token);
    if (Date.now() >= exp * 1000) {
      await refreshTokenApi();
      if (!localStorage.getItem('accessToken')) {
        return new Promise(resolve => resolve(false));
      }
      // token could be refreshed
      return new Promise(resolve => resolve(true));
    }
    // if the token is not expired
    return new Promise(resolve => resolve(true));
  } else {
    // no token found
    console.error('No token found.');
    return new Promise(resolve => resolve(false));
  }
};
