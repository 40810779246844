/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, InputLabel, MenuItem, TextField, OutlinedInput, Box, FormHelperText, Select } from '@mui/material';

import { PhoneNumberMask } from '@components/PhoneNumberMask';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetProtocolName } from '@hooks/useGetProtocolName';
import { Trial } from '@mytypes/trial';
import { Protocol } from '@mytypes/protocol';
import { registerUserApi } from '@services/user';
import { addMemberApi } from '@services/trial';
import { addAccessibleUserApi } from '@services/clients';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export type EnrolledGrower = {
  fullName: string;
  growerId: string;
  protocolNames: string[];
  protocols: Protocol[];
  trial: Trial | undefined;
};

export interface Props {
  email?: string;
  emailStatus: string;
  growerId: string | undefined;
  handleSubmitComplete: (enrolledGrower: EnrolledGrower | undefined) => void;
  isSubmiting: (loading: boolean) => void;
  onError: (message: string) => void;
  submit: boolean;
  trial: Trial | undefined;
}

type FormData = {
  firstname: string;
  lastname: string;
  phonenumber: string;
  protocols: string[];
};

const GrowerEnrollmentForm = ({ email, emailStatus, growerId, handleSubmitComplete, isSubmiting, onError, submit, trial }: Props) => {
  const { t } = useTranslation();

  const ENROLL_WIZARD_STEPS = [t('enrollment-wizard.farmer-step'), t('enrollment-wizard.field-step')];
  const theme = useAppTheme({});
  const { clientId } = useSelector((state: RootState) => state.app);
  const { protocols: allProtocols } = useSelector((state: RootState) => state.protocol);
  const { users } = useSelector((state: RootState) => state.client);
  const getProtocolName = useGetProtocolName();
  const fromRef = useRef<HTMLFormElement>();

  const {
    register,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    setValue,
    getValues
  } = useForm<FormData>({ defaultValues: { protocols: [] } });

  useEffect(() => {
    if (growerId) {
      const user = users.find(u => u.user_id === growerId);
      if (user) {
        setValue('firstname', user.first);
        setValue('lastname', user.last);
        setValue('phonenumber', user.phone, { shouldDirty: true, shouldValidate: true });
      }
    } else {
      reset();
    }
  }, [growerId]);

  useEffect(() => {
    if (submit) {
      fromRef.current?.requestSubmit();
    }
  }, [submit]);

  useEffect(() => handleSubmitComplete(undefined), [isValid]);

  const registerGrower = async (email: string, firstname: string, lastname: string, phonenumber: string): Promise<string | undefined> => {
    const registerPayload = {
      users: [{ user_id: uuidv4(), first_name: firstname, last_name: lastname, email, phone: `+${phonenumber.replace(/[-+()\s]/g, '')}` }]
    };
    try {
      const { created_ids } = (await registerUserApi(registerPayload)) || {};
      if (created_ids && created_ids.length) {
        return created_ids[0];
      }
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const addGrowerToClient = async (growerId: string): Promise<boolean> => {
    if (!clientId) return false;

    try {
      const { added } = (await addAccessibleUserApi({ clientId, user_ids: [growerId] })) || {};
      if (added && added > 0) {
        return true;
      }
      return true; // @todo check if we need to consider created_ids.length === 0 as an error
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const enrollGrower = async (growerId: string, protocol: string[]): Promise<boolean> => {
    if (!clientId || !trial) return false;

    const enrollPayload = {
      clientId,
      members: [{ user_id: growerId, group_name: 'trial-grower', protocol_ids: protocol }],
      trialId: trial.id
    };
    try {
      const { created_ids } = (await addMemberApi(enrollPayload)) || {};
      if (created_ids && created_ids.length) {
        return true;
      }
      return true; // @todo check if we need to consider created_ids.length === 0 as an error
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onSubmitHandler = async ({ firstname, lastname, phonenumber, protocols }: FormData) => {
    if (emailStatus === 'not-registered' && email) {
      isSubmiting(true);
      const newId = await registerGrower(email, firstname, lastname, phonenumber);
      if (newId) {
        await addGrowerToClient(newId); // @todo check if it false
        const result = await enrollGrower(newId, protocols);
        if (result) {
          handleSubmitComplete({
            growerId: newId,
            fullName: `${firstname} ${lastname}`,
            protocolNames: protocols.map(protocol => getProtocolName(protocol) || ''),
            trial,
            protocols: allProtocols.filter(protocol => protocols.includes(protocol.id || ''))
          });
        } else {
          onError(t('grower-enrollement-form.unable-enroll-message'));
          handleSubmitComplete(undefined);
        }
        isSubmiting(false);
      }
    }
    if (emailStatus === 'registered' && growerId) {
      isSubmiting(true);
      const result = await enrollGrower(growerId, protocols);
      if (result) {
        handleSubmitComplete({
          growerId,
          fullName: `${firstname} ${lastname}`,
          protocolNames: protocols.map(protocol => getProtocolName(protocol) || ''),
          trial,
          protocols: allProtocols.filter(protocol => protocols.includes(protocol.id || ''))
        });
      } else {
        onError(t('grower-enrollement-form.unable-enroll-message'));
        handleSubmitComplete(undefined);
      }
      isSubmiting(false);
    }
  };

  const validateRequired = (value: string) => {
    return value.trim() !== '' || t('grower-enrollement-form.value-required-message');
  };

  const validateLength = (value: string) => {
    return value.trim().length >= 2 || t('grower-enrollement-form.value-required-message');
  };

  const validateRequiredProtocols = (value: string[]) => {
    return !!value.length || t('grower-enrollement-form.value-required-message');
  };

  return (
    <Box ref={fromRef} component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      <div css={styles.userData(theme)}>
        <FormControl>
          <TextField
            disabled={emailStatus === 'registered'}
            label={t('grower-enrollement-form.first-name-label')}
            autoComplete="off"
            type={'text'}
            error={!!errors['firstname']}
            helperText={errors['firstname'] ? errors['firstname'].message : ''}
            InputLabelProps={{ shrink: true }}
            {...register('firstname', {
              validate: {
                required: validateRequired,
                length: validateLength
              }
            })}
          />
        </FormControl>

        <FormControl>
          <TextField
            disabled={emailStatus === 'registered'}
            variant="outlined"
            label={t('grower-enrollement-form.last-name-label')}
            autoComplete="off"
            type={'text'}
            error={!!errors['lastname']}
            helperText={errors['lastname'] ? errors['lastname'].message : ''}
            InputLabelProps={{ shrink: true }}
            {...register('lastname', {
              validate: {
                required: validateRequired,
                length: validateLength
              }
            })}
          />
        </FormControl>

        <FormControl error={!!errors['phonenumber']} sx={{ fieldset: { legend: { maxWidth: '100%' } } }}>
          <InputLabel error={!!errors['phonenumber']} variant="outlined" htmlFor="phonenumber-input" shrink={true}>
            {t('grower-enrollement-form.phone-number-label')}
          </InputLabel>
          <OutlinedInput
            label={t('grower-enrollement-form.phone-number-label')}
            disabled={emailStatus === 'registered'}
            id="phonenumber-input"
            autoComplete="off"
            value={getValues('phonenumber')}
            inputComponent={PhoneNumberMask as any}
            error={!!errors['phonenumber']}
            {...register('phonenumber', {
              validate: {
                required: validateRequired
              }
            })}
          />
          <FormHelperText>{errors['phonenumber'] ? errors['phonenumber'].message : ''}</FormHelperText>
        </FormControl>

        <FormControl error={!!errors['protocols']} sx={{ fieldset: { legend: { maxWidth: '100%' } } }}>
          <InputLabel error={!!errors['protocols']} variant="outlined" htmlFor="protocols-input" shrink={true}>
            {t('general.protocol')}
          </InputLabel>
          <Select
            id="protocols-input"
            value={getValues('protocols')}
            label={t('general.protocol')}
            error={!!errors['protocols']}
            multiple
            autoComplete="off"
            {...register('protocols', {
              validate: validateRequiredProtocols,
              onChange: e => setValue('protocols', e.target.value, { shouldValidate: true, shouldDirty: true })
            })}
          >
            {allProtocols.map(protocol => (
              <MenuItem key={protocol.id} value={protocol.id}>
                {protocol.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors['protocols'] ? errors['protocols'].message : ''}</FormHelperText>
        </FormControl>
      </div>
    </Box>
  );
};

export default GrowerEnrollmentForm;
