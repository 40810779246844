import { AlertQuestionsBySection, alertQuestion } from '@mytypes/fieldVisitWizard';

export const groupAlertsQuestionsByPage = (questions: alertQuestion[]): AlertQuestionsBySection[] =>
  Object.values(
    questions.reduce((acc: any, item) => {
      const { sectionId, sectionLabel, question } = item;
      if (!acc[sectionId]) {
        acc[sectionId] = {
          sectionId,
          sectionLabel: sectionLabel.trim(),
          questions: []
        };
      }
      acc[sectionId].questions.push(question);
      return acc;
    }, {})
  );
