import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SwitchClientModal } from '@components/SwitchClientModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useSwitchClientModal = () => {
  const dispatch = useDispatch();
  const modalKey = 'swtich-client-modal';

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(() => {
    const children = <SwitchClientModal onClose={onClose} />;
    dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
  }, [dispatch, onClose]);

  return displayModal;
};
