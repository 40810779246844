import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  `,
  titleContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: 0 ${theme.spacing(2)};
  `,
  title: (theme: Theme) => css`
    padding: ${theme.spacing(2)} 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  emptyCase: (theme: Theme) => css`
    text-align: center;
    margin-top: ${theme.spacing(3)};
  `,
  cardHeader: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #35363a;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `,
  cardContent: css`
    margin: 0;
  `,
  protocol: css`
    font-weight: 500;
  `,
  fieldCard: (theme: Theme) => css`
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  cardActions: css`
    justify-content: end;
  `,
  actionBtn: css`
    width: 100px;
  `,
  buttonsContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  addNewFieldBtn: (theme: Theme) => css`
    margin: 0 ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `
};
