import { css } from '@emotion/react';

export const styles = {
  option: css`
    white-space: break-spaces;
  `,
  actionsContainer: css`
    display: flex;
    justify-content: right;
  `,
  clearBtn: css`
    width: 100px;
  `
};
