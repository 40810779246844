import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f2128;
    row-gap: 10px;
    padding: ${theme.spacing(2)};
  `,
  text: css`
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #ffa726;
    font-weight: 100;
  `
};
