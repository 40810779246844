import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropGrowerModal } from '@components/DropGrowerModal';
import { ToastType } from '@components/ui/Toast';
import { useToast } from '@hooks/app/useToast';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useDropGrowerModal = () => {
  const modalKey = 'drop-grower-modal';
  const dispatch = useDispatch();
  const showToast = useToast();
  const [isDropped, setIsDropped] = useState<boolean>(false);

  const onClose = useCallback(
    (confirmed: boolean) => {
      setIsDropped(confirmed);
      dispatch(removeModal({ key: modalKey }));
    },
    [dispatch]
  );

  const onError = useCallback(() => showToast({ type: ToastType.ERROR, children: `Something went wrong.` }), []);

  const displayModal = useCallback(
    (grower: any) => {
      setIsDropped(false);
      const children = <DropGrowerModal grower={grower} onClose={onClose} onError={onError} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return {
    displayModal,
    isDropped
  };
};
