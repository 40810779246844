import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAsyncCallback } from '@react-hooks-library/core';

import { getFieldNotesApi } from '@services/protocol';
import { setNotes } from '@state-mgmt/slices/notesSlice';

export const useGetFieldNotes = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(
      async (trialId: string, protocolId: string, fieldId: string) => {
        try {
          const { notes } = (await getFieldNotesApi({ trialId, protocolId, fieldId })) || {};
          if (!notes) return [];
          dispatch(setNotes(notes));
          localStorage.setItem(`field-notes-${protocolId}`, JSON.stringify(notes));
          return notes;
        } catch (error) {
          const notes = JSON.parse(localStorage.getItem(`field-notes-${protocolId}`) || '[]');
          dispatch(setNotes(notes));
          return notes;
        }
      },
      [dispatch]
    )
  );
};
