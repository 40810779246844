import { useTranslation } from 'react-i18next';

const NoConnectionIndicator = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '9999',
        background: '#e58a11',
        width: '100%',
        color: '#000',
        textAlign: 'center'
      }}
    >
      <span> {t('no-connection-indicator.title')}</span>
    </div>
  );
};

export default NoConnectionIndicator;
