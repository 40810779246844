/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';
import { Position } from '@mytypes/map';
import { styles } from './styles';

export interface Props {
  hasLocation: boolean;
  name: string;
  options: string[];
  position?: Position;
  value: string[] | undefined;
  onAddLocation: () => void;
  onChange: (values: string[] | undefined) => void;
  onReCenterLocationEnabled: () => void;
}

const MultipleSelectionCharacteristicType = ({ hasLocation, name, options, position, value, onAddLocation, onChange, onReCenterLocationEnabled }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const displayModal = useNewLocationModal();
  const [controlledValue, setControlledValue] = useState<string[]>(value || []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    try {
      const {
        target: { value }
      } = event;
      setControlledValue(typeof value === 'string' ? value.split(',') : value);
    } catch (error) {
      showBoundary(error);
    }
  };

  const isControlledValueDirty = (): boolean => {
    try {
      if (controlledValue.length !== value?.length) return true;
      return !controlledValue.every(element => value?.includes(element));
    } catch (error) {
      showBoundary(error);
      return false;
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        name={name}
        multiple
        // @ts-ignore
        value={controlledValue}
        onChange={handleChange}
        onClose={() => {
          if (isControlledValueDirty()) onChange(controlledValue.length ? controlledValue : undefined);
        }}
        input={<OutlinedInput />}
      >
        {options.map(option => (
          <MenuItem key={option} value={option} css={styles.option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <div css={styles.actionsContainer}>
        <Button variant="text" size="small" onClick={() => displayModal(name, position)}>
          {t('caracteristics.set-location-button')}
        </Button>
        {hasLocation ? (
          <Button variant="text" size="small" onClick={() => onReCenterLocationEnabled()}>
            {t('caracteristics.re-center-location-button')}
          </Button>
        ) : (
          <Button variant="text" size="small" onClick={() => onAddLocation()}>
            {t('caracteristics.add-location-button')}
          </Button>
        )}
        <Button variant="text" size="small" css={styles.clearBtn} onClick={() => (value ? setControlledValue([]) : onChange(undefined))}>
          {t('caracteristics.clear-button')}
        </Button>
      </div>
    </FormControl>
  );
};

export default MultipleSelectionCharacteristicType;
