import { css } from '@emotion/react';

export const styles = {
  container: css`
    flex-grow: 2;
    padding-bottom: 300px;
  `,
  header: css`
    padding: 0 6px;
    display: flex;
  `,
  headerText: css``,
  headerIndicator: css`
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  `
};
