import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Alerts, FieldVisitAlertsModal } from '@components/FieldVisitWizard/FieldVisitAlertsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useFieldVisitAlertsModal = () => {
  const modalKey = 'field-visits-alerts-modal';
  const dispatch = useDispatch();
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  const onClose = useCallback(
    (readyToSubmit: boolean) => {
      setReadyToSubmit(readyToSubmit);
      dispatch(removeModal({ key: modalKey }));
    },
    [dispatch]
  );

  const displayModal = useCallback(
    (alerts: Alerts) => {
      const children = <FieldVisitAlertsModal alerts={alerts} onClose={onClose} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal, readyToSubmit };
};
