import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc((var(--vh, 1vh) * 100) - 56px);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border-radius: 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  listLoadingOverlayContent: css`
    position: relative;
    height: 100%;
  `,
  filter: (theme: Theme) => css`
    margin-top: 6px;
  `,
  enrollBtn: (theme: Theme) => css`
    margin: 0 ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
  growerFilter: (theme: Theme) => css`
    margin-top: 6px;
  `,
  userCard: (theme: Theme) => css`
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  cardHeader: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #35363a;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `,
  cardContent: css`
    margin: 0;
  `,
  cardActions: css`
    justify-content: center;
  `,
  email: css`
    font-weight: 500;
  `
};
