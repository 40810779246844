/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import { StepIconProps } from '@mui/material';

const WizardStepIconRoot = styled('div')<{
  ownerState: { active?: boolean };
}>(({ ownerState }) => ({
  color: '#1D303D',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .EnrollWizardStepIcon-completedIcon': {
    marginLeft: 12,
    marginTop: 12,
    color: '#FFFFFF',
    zIndex: 1,
    fontSize: 18,
    background: '#06B7FF',
    borderRadius: 12,
    width: 20,
    height: 20
  },
  '& .EnrollWizardStepIcon-circlebg': {
    width: 44,
    height: 44,
    borderRadius: '50%',
    backgroundColor: ownerState.active ? '#1D303D' : '#474955'
  },
  '& .EnrollWizardStepIcon-circle': {
    width: 13,
    height: 13,
    marginLeft: 15,
    marginTop: 15,
    borderRadius: '50%',
    backgroundColor: ownerState.active ? '#06B7FF' : '#FFFFFF'
  }
}));

const WizardStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <WizardStepIconRoot ownerState={{ active }} className={className}>
      <div className="EnrollWizardStepIcon-circlebg">
        {completed ? <Check className="EnrollWizardStepIcon-completedIcon" /> : <div className="EnrollWizardStepIcon-circle" />}
      </div>
    </WizardStepIconRoot>
  );
};
export default WizardStepIcon;
