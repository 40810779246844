import { getUserPerms } from '@utils/getUserPerms';
import { useMemo } from 'react';

export const userPermissions = {
  SUPER_ADMIN: 'super-admin',
  CLIENT: 'client',
  UPDATE: 'update',
  IMAGE: 'image',
  MANAGE: 'manage',
  ANALYTICS: 'analytics',
  OPERATE: 'operate',
  ENROLL: 'enroll',
  VISIT: 'visit',
  VISIT_SCHEDULING: 'visit-scheduling'
};

export const useUserPermissions = () => {
  const perms = getUserPerms();

  let isSuperAdmin = useMemo(() => {
    if (!perms || !perms.length) return false;
    return perms.includes(userPermissions.SUPER_ADMIN);
  }, [perms]);

  let isClientAdmin = useMemo(() => {
    if (!perms || !perms.length) return false;

    return perms.includes(userPermissions.CLIENT);
  }, [perms]);

  let isTrialAdmin = useMemo(() => {
    if (!perms || !perms.length) return false;
    return perms.includes(userPermissions.UPDATE);
  }, [perms]);

  let userHasEnrollPermission = useMemo(() => {
    if (!perms || !perms.length) return false;
    return perms.includes(userPermissions.SUPER_ADMIN) || perms.includes(userPermissions.ENROLL);
  }, [perms]);

  let userHasManagePermission = useMemo(() => {
    if (!perms || !perms.length) return false;

    return perms.includes(userPermissions.SUPER_ADMIN) || perms.includes(userPermissions.MANAGE);
  }, [perms]);

  return {
    isSuperAdmin,
    isClientAdmin,
    isTrialAdmin,
    userHasEnrollPermission,
    userHasManagePermission
  };
};
