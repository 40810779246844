import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Props as ToastProps } from '@components/ui/Toast';
import { addToast } from '@state-mgmt/slices/appSlice';

export const useToast = (): any => {
  const key = uuid();
  const dispatch = useDispatch();

  return useCallback(
    (props: ToastProps): string => {
      dispatch(addToast({ key, props }));
      return key;
    },
    [dispatch]
  );
};
