import { deleteFieldNotesUploadApi, getFieldNotesApi } from '@services/protocol';
import { setCurrentNote } from '@state-mgmt/slices/notesSlice';
import { useAsyncCallback } from '@utils/useAsyncCallback';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type Params = {
  trialId: string;
  protocolId: string;
  fieldId: string;
  noteId: string;
  uploadId: string;
};

export const useDeleteFieldNoteUpload = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async ({ trialId, protocolId, fieldId, noteId, uploadId }: Params) => {
      try {
        await deleteFieldNotesUploadApi({ fieldId, noteId, protocolId, trialId, uploadId });

        // Reload current note to get new upload urls
        const { notes } = (await getFieldNotesApi({ trialId, protocolId, fieldId })) || {};
        if (notes) {
          const note = notes.find(note => note.id === noteId);
          if (note) dispatch(setCurrentNote(note));
        }
      } catch (error) {
        throw new Error('Something went wrong');
      }
    }, [])
  );
};
