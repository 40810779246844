/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField, FormControl, IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { NumericKeyboard } from '@components/NumericKeyboard';

import { useIsMount } from '@hooks/useIsMount';
import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';
import { Position } from '@mytypes/map';
import { decimalInputHandler, integerInputHandler } from '@utils/decimalInputHandler';
import { isMobile } from '@utils/isMobile';
import { styles } from './styles';
import { useErrorBoundary } from 'react-error-boundary';

export enum NUMBER_TYPE {
  INTEGER = 'integer',
  DECIMAL = 'decimal'
}

export interface Props {
  hasLocation: boolean;
  name: string;
  number?: boolean;
  numberType?: NUMBER_TYPE;
  position?: Position;
  value: string | number | undefined;
  onAddLocation: () => void;
  onChange: (value: string | number | undefined) => void;
  onReCenterLocationEnabled: () => void;
}

const TextCharacteristicType = ({
  hasLocation,
  name,
  number = false,
  numberType,
  position,
  value,
  onAddLocation,
  onChange,
  onReCenterLocationEnabled
}: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const isMount = useIsMount();
  const displayModal = useNewLocationModal();
  const [controlledValue, setControlledValue] = useState<string | number | undefined>(value);
  const [openNumericKeyboard, setOpenNumericKeyboard] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e.target.value as string;
      setControlledValue(value === '' ? undefined : value);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    try {
      if (number && numberType === NUMBER_TYPE.INTEGER) {
        integerInputHandler(event);
      }
      if (number && numberType === NUMBER_TYPE.DECIMAL) {
        decimalInputHandler(event);
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnPaste = (event: any) => {
    try {
      if (number) {
        event.preventDefault();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        label={t('caracteristics.answer-label')}
        name={name}
        value={controlledValue || ''}
        onChange={handleChange}
        onPaste={handleOnPaste}
        onBlur={() => {
          if (controlledValue !== value) {
            onChange(controlledValue);
          }
        }}
        placeholder={t('caracteristics.type-answer-placeholder')}
        autoComplete="off"
        type="text"
        multiline={number ? false : true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" variant="standard">
              <IconButton
                aria-label="clear answer"
                onClick={() => {
                  setOpenNumericKeyboard(false);
                  setControlledValue(undefined);
                  onChange(undefined);
                }}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
          ...(number && {
            inputMode: 'none',
            onKeyDown: handleOnKeyDown
          }),
          ...(isMobile() &&
            number && {
              onFocus: () => {
                // @ts-ignore
                document.activeElement?.blur();
                setOpenNumericKeyboard(true);
              }
            })
        }}
      />
      <div css={styles.locationBtnContainer}>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            if (isMount) displayModal(name, position);
          }}
        >
          {t('caracteristics.set-location-button')}
        </Button>
        {hasLocation ? (
          <Button variant="text" size="small" onClick={() => onReCenterLocationEnabled()}>
            {t('caracteristics.re-center-location-button')}
          </Button>
        ) : (
          <Button variant="text" size="small" onClick={() => onAddLocation()}>
            {t('caracteristics.add-location-button')}
          </Button>
        )}
      </div>

      {isMobile() && number && (
        <NumericKeyboard
          open={openNumericKeyboard}
          decimal={number && numberType === NUMBER_TYPE.DECIMAL}
          value={controlledValue?.toString()}
          onConfirm={value => {
            setOpenNumericKeyboard(false);
            setControlledValue(value);
            onChange(value);
          }}
          onClose={() => {
            // @ts-ignore
            setOpenNumericKeyboard(false);
          }}
        />
      )}
    </FormControl>
  );
};

export default TextCharacteristicType;
