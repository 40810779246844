import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getFieldTables } from '@utils/getFieldTables';

export const useGetFieldTables = () =>
  useAsyncCallback(
    useCallback(
      async (
        trialId: string,
        protocolId: string,
        fieldId: string,
        includeTreatmentsZones: boolean = false,
        includeSoil: boolean = false
      ): Promise<any | undefined> => await getFieldTables(trialId, protocolId, fieldId, includeTreatmentsZones, includeSoil),
      []
    )
  );
