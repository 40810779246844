import * as turf from '@turf/turf';
import { Feature, FeatureCollection, Geometry } from '@turf/helpers';

export const findPolygonContainingPointInFeatureCollection = (polygonGeometry: any, coordinates: [number, number]) => {
  let point = turf.point(coordinates);

  return polygonGeometry?.features?.find((f: any) => {
    if (f.geometry.type === 'MultiPolygon') {
      let result = turf.flatten(f);
      if (!result) return false;

      return result.features.find((mpf: any) => turf.booleanContains(mpf, point));
    }

    return turf.booleanContains(f, point);
  });
};

/**
 * Calculates the area in acres for a given geojson input.
 *
 * @param geojson - geojson feature or feature collection object
 * @returns calculated acres for geojson input
 */
export const calcAcresForGeojson = (geojson: Feature<any> | FeatureCollection<any> | Geometry): number => {
  const areaSquareMeters = turf.area(geojson);
  // Formula - for an approximate result, divide the area value by 4047
  // https://www.inchcalculator.com/convert/square-meter-to-acre/
  const areaAcres = areaSquareMeters / 4047;
  const roundedAreaAcres = Math.round(areaAcres * 100) / 100;

  return roundedAreaAcres;
};
