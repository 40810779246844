/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, IconButton, List, Modal, Paper, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';

import { FIELD_ACTION_MODE } from '@components/DropGrowerModal';
import { useDropGrowerModal } from '@hooks/modals/useDropGrowerModal';
import { useGetProtocolName } from '@hooks/useGetProtocolName';
import { useDropUndropFieldModal } from '@hooks/modals/useDropUndropFieldModal';
import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  open: boolean;
  onClose: (needToRefresh: boolean) => void;
  growerId: string | undefined;
}

const GrowerFieldsModal = ({ growerId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { displayModal: displayGropGrowerModal, isDropped: growerIsDropped } = useDropGrowerModal();
  const { displayModal: displayDropUndropFieldModal, isDropped: fieldIsDroppedUndropped } = useDropUndropFieldModal();
  const getProtocolName = useGetProtocolName();

  const { fields } = useSelector((state: RootState) => state.trial);
  const { users } = useSelector((state: RootState) => state.client);

  const [grower, setGrower] = useState<any | undefined>();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (growerId) {
      const growerFound = users.find(user => user.user_id === growerId);
      if (growerFound) {
        setGrower(growerFound);
        setData(fields.filter(field => field.owner_id === growerId));
      }
    }
  }, [growerId]);

  useEffect(() => {
    growerIsDropped && onClose(true);
  }, [growerIsDropped]);

  useEffect(() => {
    fieldIsDroppedUndropped && onClose(true);
  }, [fieldIsDroppedUndropped]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Paper elevation={0} css={styles.container(theme)}>
        {grower && (
          <>
            <div css={styles.titleContainer(theme)}>
              <Typography color="text.primary" css={styles.title(theme)}>
                {t('grower-fields.title', { fullName: `${grower.first} ${grower.last}` })}
              </Typography>
              <IconButton onClick={() => onClose(false)}>
                <CloseIcon />
              </IconButton>
            </div>

            <div css={styles.scrollableArea(theme)}>
              {data && !data.length && (
                <Typography color="text.primary" css={styles.emptyCase(theme)}>
                  {t('grower-fields.no-enrolled-field-found')}
                </Typography>
              )}
              <List>
                {data
                  .slice()
                  .sort((a: any, b: any) => a.name.localeCompare(b.name))
                  .map(({ field_id, name, dropout, protocol_id }) => (
                    <Card key={`${field_id}&${protocol_id}`} elevation={1} css={styles.fieldCard(theme)}>
                      <div css={styles.cardHeader(theme)}>
                        <Typography color="text.primary">{name}</Typography>
                      </div>
                      <CardContent css={styles.cardContent}>
                        <Typography color="text.secondary" css={styles.protocol}>
                          {t('general.protocol')}: {getProtocolName(protocol_id) || ''}
                        </Typography>
                      </CardContent>
                      <CardActions css={styles.cardActions}>
                        <Button size="small" onClick={() => navigate(`/field-map/${field_id}/${protocol_id}`)}>
                          {t('grower-fields.view-map-button')}
                        </Button>
                        <Button
                          variant={dropout ? 'contained' : 'outlined'}
                          color={dropout ? 'primary' : 'error'}
                          size="small"
                          onClick={() => {
                            displayDropUndropFieldModal({ field_id, name, protocol_id }, dropout ? FIELD_ACTION_MODE.UNDROP : FIELD_ACTION_MODE.DROP);
                          }}
                          startIcon={dropout ? undefined : <ExitToAppIcon />}
                          css={styles.actionBtn}
                        >
                          {dropout ? t('grower-fields.re-enroll-button') : t('grower-fields.drop-out-button')}
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
              </List>
            </div>

            <div css={styles.buttonsContainer}>
              <Button css={styles.addNewFieldBtn(theme)} variant="outlined" color="error" onClick={() => displayGropGrowerModal(grower)}>
                {t('grower-fields.drop-out-grower-button')}
              </Button>
              <Button
                css={styles.addNewFieldBtn(theme)}
                variant="outlined"
                startIcon={<MapIcon />}
                onClick={() => navigate(`/enrollment/new?grower_id=${grower.user_id}&field=true`)}
              >
                {t('grower-fields.add-new-field-button')}
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
};

export default GrowerFieldsModal;
