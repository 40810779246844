export const clearAppData = async () => {
  try {
    // cookies
    const theCookies = document.cookie.split(';');
    for (var i = 1; i <= theCookies.length; i++) {
      const acookie = theCookies[i - 1];
      const cookieArr = acookie.split('=');
      document.cookie = cookieArr[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    // Get cache storage and clear cache storage
    window.caches.keys().then(function (names) {
      for (let name of names) window.caches.delete(name);
    });

    // clear localStorage
    window.localStorage.clear();

    // clear sessionStorage
    window.sessionStorage.clear();

    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  } catch (err) {
    console.error(err);
  }
};
