import { QUESTION_TYPE } from '@mytypes/protocol';

export const parseCharacterizationValueToSubmit = (value: any, valueType: QUESTION_TYPE): (string | number | boolean)[] => {
  if (valueType === QUESTION_TYPE.DECIMAL) {
    return [Number(value)];
  }
  if (valueType === QUESTION_TYPE.INTEGER) {
    return [parseInt(value)];
  }
  if (valueType === QUESTION_TYPE.SELECTION) {
    return [value];
  }
  if (valueType === QUESTION_TYPE.MULTI_SELECTION) {
    return value;
  }
  if (valueType === QUESTION_TYPE.FLAG) {
    return value === 'yes' ? [true] : [false];
  }
  return [value];
};
