import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Protocol } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';

export type ProtocolState = {
  protocols: Protocol[];
  currentProtocol: string | undefined;
};

const initialState: ProtocolState = {
  protocols: [],
  currentProtocol: undefined
};

export const protocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    setProtocols(state: ProtocolState, action: PayloadAction<Protocol[]>) {
      state.protocols = action.payload;
    },
    setCurrentProtocol(state: ProtocolState, action: PayloadAction<string | undefined>) {
      state.currentProtocol = action.payload;
    }
  }
});

// Actions
export const { setProtocols, setCurrentProtocol } = protocolSlice.actions;

// Selectors
export const selectProtocols = (state: RootState) => state.protocol.protocols;
export const selectCurrentProtocol = (state: RootState) => state.protocol.currentProtocol;

export default protocolSlice.reducer;
