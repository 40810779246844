import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: calc((var(--vh, 1vh) * 100) - 56px);
    display: flex;
    flex-direction: column;
    border-radius: 0;
  `,
  mapContainer: css`
    flex-grow: 2;
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `,
  mapOptionBtn: css`
    align-self: flex-end;
  `
};
