import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  userData: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)} 0;
  `
};
