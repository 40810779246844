import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  hidden: css`
    display: none;
  `,
  container: css`
    height: 100%;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
  `,
  sectionSubtitle: css`
    margin-bottom: 10px;
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `,
  sectionsListContainer: css`
    width: 100%;
    flex-grow: 2;
  `,
  sectionsList: css`
    display: contents;
  `,
  sectionsListBtnItem: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
  `,
  sectionsListItem: css`
    display: flex,
    align-items: center;

  `,
  sectionsListItemProductPoint: (color: string) => css`
    height: 15px;
    width: 15px;
    margin: 0 6px;
    background-color: ${color};
    border-radius: 50%;
    display: inline-block;
  `
};
