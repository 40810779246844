import jwt_decode from 'jwt-decode';

export const getUserPerms = (): string[] | undefined => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    // @ts-ignore
    const { perms } = jwt_decode(token);
    return perms as string[];
  }

  return undefined;
};
