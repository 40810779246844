import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';

export const styles = {
  container: (themeMode: THEME_MODE) => css`
    width: calc(100vw - 64px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${themeMode === THEME_MODE.DARK ? '#43454b' : '#fff'};
    border-radius: 4px;
  `,
  message: css`
    font-weight: 500;
  `,
  label: css`
    font-weight: 600;
  `,
  mapContainer: css`
    height: 300px;
    margin: 12px 0;
  `,
  actionsContainer: (theme: Theme) => css`
    display: flex;
    padding: 0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)};
  `
};
