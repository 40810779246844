/** @jsxImportSource @emotion/react */
import { Fab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styles } from './styles';

export interface Props {
  onClick: () => void;
}

const CloseModalButton = ({ onClick }: Props) => (
  <Fab color="primary" size="small" css={styles.btn} onClick={onClick}>
    <CloseIcon />
  </Fab>
);

export default CloseModalButton;
