import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Props as ToastProps } from '@components/ui/Toast';
import { Props as ModalProps } from '@components/ui/Modal';
import { Position } from '@mytypes/map';
import { RootState } from '@state-mgmt/store';
import { USER_ROLE } from '@mytypes/user';

export enum THEME_MODE {
  DARK = 'dark',
  LIGHT = 'light'
}

export type AppState = {
  accuracy: number | undefined;
  clientId: string | undefined;
  currentPosition: Position | undefined;
  favoriteClientId: string | null;
  isAunthetificated: boolean;
  isSplashing: boolean | undefined;
  modalList: { key: string; props: ModalProps }[];
  nearbyFieldsRatioDistance: number;
  nearbyFieldsRatioUOM: string;
  offSite: boolean;
  online: boolean;
  showAppNavigator: boolean;
  simulateGPS: boolean;
  syncEnabled: boolean;
  themeMode: THEME_MODE;
  toastList: { key: string; props: ToastProps }[];
  trialId: string | undefined;
  userRole: USER_ROLE;
  watchId: number | undefined;
};

const initialState: AppState = {
  accuracy: undefined,
  clientId: undefined,
  currentPosition: undefined,
  favoriteClientId: null,
  isAunthetificated: false,
  isSplashing: undefined,
  modalList: [],
  nearbyFieldsRatioDistance: 25,
  nearbyFieldsRatioUOM: 'miles',
  offSite: false,
  online: true,
  showAppNavigator: false,
  simulateGPS: false,
  syncEnabled: false,
  themeMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME_MODE.DARK : THEME_MODE.LIGHT,
  toastList: [],
  trialId: undefined,
  userRole: USER_ROLE.TRIAL_GROWER,
  watchId: undefined
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addModal(state: AppState, action: PayloadAction<{ key: string; props: ModalProps }>) {
      state.modalList = [...state.modalList, action.payload];
    },
    addToast(state: AppState, action: PayloadAction<{ key: string; props: ToastProps }>) {
      state.toastList = [...state.toastList, action.payload];
    },
    removeModal(state: AppState, action: PayloadAction<{ key: string }>) {
      state.modalList = state.modalList.filter(item => item.key !== action.payload.key);
    },
    removeToast(state: AppState, action: PayloadAction<{ key: string }>) {
      state.toastList = state.toastList.filter(item => item.key !== action.payload.key);
    },
    setAccuracy(state: AppState, action: PayloadAction<number>) {
      state.accuracy = action.payload;
    },
    setClientId(state: AppState, action: PayloadAction<string | undefined>) {
      state.clientId = action.payload;
    },
    setCurrentPosition(state: AppState, action: PayloadAction<Position | undefined>) {
      state.currentPosition = action.payload;
    },
    setFavoriteClient(state: AppState, action: PayloadAction<string | null>) {
      state.favoriteClientId = action.payload;
    },
    setIsAunthetificated(state: AppState, action: PayloadAction<boolean>) {
      state.isAunthetificated = action.payload;
    },
    setIsSplashing(state: AppState, action: PayloadAction<boolean>) {
      state.isSplashing = action.payload;
    },
    setNearbyFieldsRatioDistance(state: AppState, action: PayloadAction<number>) {
      state.nearbyFieldsRatioDistance = action.payload;
    },
    setNearbyFieldsRatioUOM(state: AppState, action: PayloadAction<string>) {
      state.nearbyFieldsRatioUOM = action.payload;
    },
    setOnline(state: AppState, action: PayloadAction<boolean>) {
      state.online = action.payload;
    },
    setOffSite(state: AppState, action: PayloadAction<boolean>) {
      state.offSite = action.payload;
    },
    setShowAppNavigator(state: AppState, action: PayloadAction<boolean>) {
      state.showAppNavigator = action.payload;
    },
    setSimulateGPS(state: AppState, action: PayloadAction<boolean>) {
      state.simulateGPS = action.payload;
    },
    setSyncEnabled(state: AppState, action: PayloadAction<boolean>) {
      state.syncEnabled = action.payload;
    },
    setThemeMode(state: AppState, action: PayloadAction<THEME_MODE>) {
      state.themeMode = action.payload;
    },
    setToastList(state: AppState, action: PayloadAction<{ key: string; props: ToastProps }[]>) {
      state.toastList = action.payload;
    },
    setTrialId(state: AppState, action: PayloadAction<string | undefined>) {
      state.trialId = action.payload;
    },
    setWatchId(state: AppState, action: PayloadAction<number | undefined>) {
      state.watchId = action.payload;
    },
    setUserInfo(state: AppState, action: PayloadAction<{ clientId: string | undefined; trialId: string | undefined }>) {
      state.clientId = action.payload.clientId;
      state.trialId = action.payload.trialId;
    },
    setUserRole(state: AppState, action: PayloadAction<USER_ROLE>) {
      state.userRole = action.payload;
    }
  }
});

// Actions
export const {
  addModal,
  addToast,
  removeModal,
  removeToast,
  setAccuracy,
  setClientId,
  setCurrentPosition,
  setFavoriteClient,
  setIsAunthetificated,
  setIsSplashing,
  setNearbyFieldsRatioDistance,
  setNearbyFieldsRatioUOM,
  setOffSite,
  setOnline,
  setShowAppNavigator,
  setSimulateGPS,
  setSyncEnabled,
  setThemeMode,
  setToastList,
  setTrialId,
  setUserInfo,
  setUserRole,
  setWatchId
} = appSlice.actions;

// Selectors
export const selectThemeMode = (state: RootState) => state.app.themeMode;
export const selectOnline = (state: RootState) => state.app.online;
export const selectShowAppNavigator = (state: RootState) => state.app.showAppNavigator;
export const selectSimulateGPS = (state: RootState) => state.app.simulateGPS;
export const selectIsAunthetificated = (state: RootState) => state.app.isAunthetificated;
export const selectIsSplashing = (state: RootState) => state.app.isSplashing;
export const selectCurrentPosition = (state: RootState) => state.app.currentPosition;
export const selectSyncEnabled = (state: RootState) => state.app.syncEnabled;
export const selectToastList = (state: RootState) => state.app.toastList;
export const selectModalList = (state: RootState) => state.app.modalList;

export default appSlice.reducer;
