import { useState } from 'react';
import { Button, Modal } from '@mui/material';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { CloseModalButton } from '@components/CloseModalButton';
import './styles.css';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (image: string) => void;
}

const CameraModal = ({ open, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  const [dataUri, setDataUri] = useState<undefined | string>();

  const handleCameraError = (error: any) => {
    alert(`handleCameraError: ${error}`);
  };

  return (
    <Modal open={open} onClose={onClose}>
      {!dataUri ? (
        <>
          <CloseModalButton
            onClick={() => {
              setDataUri(undefined);
              onClose();
            }}
          />

          <Camera
            onCameraError={handleCameraError}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            sizeFactor={0.75}
            onTakePhoto={dataUri => setDataUri(dataUri)}
            isDisplayStartCameraError={false}
          />
        </>
      ) : (
        <div className={'demo-image-preview'}>
          <CloseModalButton
            onClick={() => {
              setDataUri(undefined);
              onClose();
            }}
          />

          <div className={'image-container'}>
            <img src={dataUri} alt="something" />
          </div>
          <div className="actions">
            <Button variant="text" onClick={() => setDataUri(undefined)} sx={{ textTransform: 'uppercase' }}>
              {t('general.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onConfirm(dataUri);
                setDataUri(undefined);
              }}
              sx={{ textTransform: 'uppercase' }}
            >
              {t('general.confirm')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CameraModal;
