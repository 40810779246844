/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { Modal, Paper } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';

import { styles } from './styles';

export interface Props {
  open: boolean;
  decimal: boolean;
  value: string | undefined;
  onConfirm: (value: string | undefined) => void;
  onClose: () => void;
}

const NumericKeyboard = ({ open, decimal = false, value, onConfirm, onClose }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();

  const [controlledValue, setControlledValue] = useState<string>('');

  useEffect(() => {
    setControlledValue(value || '');
  }, [open]);

  const simulateKey = (key: string) => {
    try {
      if (key === '.' && controlledValue?.indexOf('.') > -1) {
        return;
      }
      setControlledValue(prev => (prev === '0' ? key : prev + key));
    } catch (error) {
      showBoundary(error);
    }
  };

  const onDoneBtnClicked = () => {
    try {
      onConfirm(controlledValue);
      onClose();
      setControlledValue('');
    } catch (error) {
      showBoundary(error);
    }
  };

  const onBackspaceBtnClicked = () => {
    try {
      setControlledValue(prevState => (prevState.length === 1 ? '' : prevState.slice(0, -1)));
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Paper elevation={0} css={styles.container}>
        <div css={styles.header}>
          <span css={styles.number}>{controlledValue}</span>
          <button css={styles.doneBtn} onClick={onDoneBtnClicked}>
            {t('general.done')}
          </button>
        </div>
        <div css={styles.numbs}>
          <button css={styles.button} onClick={() => simulateKey('1')}>
            1
          </button>
          <button css={styles.button} onClick={() => simulateKey('2')}>
            2
          </button>
          <button css={styles.button} onClick={() => simulateKey('3')}>
            3
          </button>

          <button css={styles.button} onClick={() => simulateKey('4')}>
            4
          </button>
          <button css={styles.button} onClick={() => simulateKey('5')}>
            5
          </button>
          <button css={styles.button} onClick={() => simulateKey('6')}>
            6
          </button>

          <button css={styles.button} onClick={() => simulateKey('7')}>
            7
          </button>
          <button css={styles.button} onClick={() => simulateKey('8')}>
            8
          </button>
          <button css={styles.button} onClick={() => simulateKey('9')}>
            9
          </button>

          <button css={styles.button} onClick={() => simulateKey('.')} disabled={!decimal}>
            .
          </button>

          <button css={styles.button} onClick={() => simulateKey('0')}>
            0
          </button>

          <button
            css={styles.button}
            onClick={() => {
              setControlledValue(prevState => (prevState.indexOf('-') > -1 ? prevState.slice(1) : '-' + prevState));
            }}
          >
            -
          </button>
          <button css={[styles.button, styles.extraButton]} onClick={() => setControlledValue('')}>
            CE
          </button>
          <div />
          <button css={[styles.button, styles.extraButton]} onClick={onBackspaceBtnClicked}>
            <BackspaceIcon />
          </button>
        </div>
      </Paper>
    </Modal>
  );
};

export default NumericKeyboard;
