/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, TextField, FormControl } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useIsMount } from '@hooks/useIsMount';
import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';
import { Position } from '@mytypes/map';
import { styles } from './styles';

export interface Props {
  hasLocation: boolean;
  name: string;
  position?: Position;
  value: Dayjs | null;
  onAddLocation: () => void;
  onChange: (value: string | undefined) => void;
  onReCenterLocationEnabled: () => void;
}

const DateCharacteristicType = ({ hasLocation, name, position, value, onAddLocation, onChange, onReCenterLocationEnabled }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const isMount = useIsMount();
  const displayModal = useNewLocationModal();
  const [controlledValue, setControlledValue] = useState<Dayjs | null | undefined>(value ? dayjs(value) : null);

  useEffect(() => {
    if (isMount) {
      controlledValue ? onChange(controlledValue.format('MM/DD/YYYY HH:mm:ss [GMT]Z')) : onChange(undefined);
    }
  }, [controlledValue]);

  const onClearAnswerBtnClicked = () => {
    try {
      if (value) {
        setControlledValue(null);
      } else {
        onChange(undefined);
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          // @ts-ignore'
          inputFormat="MM/DD/YYYY"
          value={controlledValue}
          onChange={(newValue: Dayjs | null) => setControlledValue(newValue)}
          textField={(params: any) => <TextField name={name} {...params} />}
          closeOnSelect={true}
        />
        <div css={styles.clearBtnContainer}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              if (isMount) displayModal(name, position);
            }}
          >
            {t('caracteristics.set-location-button')}
          </Button>
          {hasLocation ? (
            <Button variant="text" size="small" onClick={() => onReCenterLocationEnabled()}>
              {t('caracteristics.re-center-location-button')}
            </Button>
          ) : (
            <Button variant="text" size="small" onClick={() => onAddLocation()}>
              {t('caracteristics.add-location-button')}
            </Button>
          )}
          <Button variant="text" size="small" css={styles.clearBtn} onClick={onClearAnswerBtnClicked}>
            {t('caracteristics.clear-button')}
          </Button>
        </div>
      </LocalizationProvider>
    </FormControl>
  );
};

export default DateCharacteristicType;
