/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Dayjs from 'dayjs';

import { RootState } from '@state-mgmt/store';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  downloadLastUpdate: any;
  onSwitchBtnPress?: () => void;
}

const DownloadFieldDataBanner = ({ downloadLastUpdate, onSwitchBtnPress }: Props) => {
  const { t } = useTranslation();
  const { online } = useSelector((state: RootState) => state.app);

  return (
    <div css={styles.container}>
      <span>
        {t('download-field-data-banner.text')}: {Dayjs(downloadLastUpdate).format('MM/DD/YYYY hh:mm a')}
      </span>
      {online && (
        <Button variant="outlined" css={styles.switchBtn} onClick={onSwitchBtnPress}>
          {t('download-field-data-banner.switch-button')}
        </Button>
      )}
    </div>
  );
};

export default DownloadFieldDataBanner;
