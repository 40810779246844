import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fieldCard } from '@components/Planner/FieldsList/FieldsList';
import { RootState } from '@state-mgmt/store';

export type DownloaderState = {
  fields: fieldCard[];
};

const initialState: DownloaderState = {
  fields: []
};

export const downloaderSlice = createSlice({
  name: 'downloader',
  initialState,
  reducers: {
    setFields(state: DownloaderState, action: PayloadAction<fieldCard[]>) {
      state.fields = action.payload;
    },
    clearAllFields(state: DownloaderState) {
      state.fields = [];
    }
  }
});

// Actions
export const { setFields, clearAllFields } = downloaderSlice.actions;

// Selectors
export const selectFields = (state: RootState) => state.downloader.fields;

export default downloaderSlice.reducer;
