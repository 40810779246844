/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

import { FIELD_ACTION_MODE } from '@components/DropGrowerModal';
import { useAppTheme } from '@hooks/useAppTheme';
import { dropoutFieldApi, undropoutFieldApi } from '@services/protocol';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  mode: FIELD_ACTION_MODE;
  field: any | undefined;
  onClose: (confirmed: boolean) => void;
  onError?: () => void;
}

const DropUndropFieldModal = ({ mode, field, onClose, onError }: Props) => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const { themeMode, trialId } = useSelector((state: RootState) => state.app);
  const [isLoading, setIsLoading] = useState<boolean>();

  const onConfirmBtnClicked = () => {
    if (!trialId) return;

    setIsLoading(true);
    const promise = new Promise(resolve => {
      resolve(
        mode === FIELD_ACTION_MODE.DROP
          ? dropoutFieldApi({ trialId, protocolId: field.protocol_id, fieldId: field.field_id })
          : undropoutFieldApi({ protocolId: field.protocold, fieldId: field.field_id })
      );
    });

    promise
      .then(() => {
        setIsLoading(false);
        onClose(true);
      })
      .catch(error => {
        setIsLoading(false);
        onClose(false);
        onError && onError();
      });
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{mode === FIELD_ACTION_MODE.DROP ? t('field-dropout-enroll.title-drop') : t('field-dropout-enroll.title-reenroll')} </DialogTitle>
      <DialogContent>
        <Typography css={styles.title}>
          {mode === FIELD_ACTION_MODE.DROP ? t('field-dropout-enroll.text-drop') : t('field-dropout-enroll.text-reenroll')}
        </Typography>
        <Typography css={styles.fieldName(theme)}>{field?.name}</Typography>
      </DialogContent>
      <DialogActions css={styles.actionsContainer}>
        <Button onClick={() => onClose(false)} css={styles.cancelBtn}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          disabled={isLoading}
          variant="contained"
          onClick={() => onConfirmBtnClicked()}
          loading={isLoading}
          loadingIndicator={mode === FIELD_ACTION_MODE.DROP ? t('field-dropout-enroll.droping-message') : t('field-dropout-enroll.reenrolling-message')}
          css={styles.loadingBtn}
        >
          {t('general.confirm')}
        </LoadingButton>
      </DialogActions>
    </div>
  );
};

export default DropUndropFieldModal;
