export enum USER_ROLE {
  TRIAL_GROWER = 'trial-grower',
  TRIAL_OPERATIVE = 'trial-operative'
}

// request and response api payloads
export type ForgotPasswordRequestPayload = {
  email: string;
};

export type ForgotPasswordResponsePayload = {
  code?: number;
  description?: string;
  name?: string;
  result?: string;
  error?: boolean;
};

export type CheckUserRegisteredRequestPayload = {
  clientId: string;
  email: string;
};

export type CheckUserRegisteredResponsePayload = {
  registered: boolean;
  user_id: string;
};

export type RegisterUserRequestPayload = {
  users: {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }[];
};

export type RegisterUserResponsePayload = {
  created_ids: string[];
  result: string;
  uncreated_emails: {
    email: string;
    reason: string;
  }[];
};

export type HelpdeskUploadRequestPayload = {
  file: Blob;
};

export type HelpdeskUploadResponsePayload = {
  url: string;
};

export type FavoriteClientRequestPayload = {
  favoriteClientId: string | null;
};

export type FavoriteClientResponsePayload = {
  result: string;
};
