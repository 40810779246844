/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import { IconButton, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CheckIcon from '@mui/icons-material/Check';

import { styles } from './styles';

export interface Props {
  downloadedFields: any[];
  fieldId: string;
  fieldVisitPlans: any[];
  protocolId: string;
}

const DownloadFieldVisitsButton = ({ downloadedFields, fieldId, fieldVisitPlans, protocolId }: Props) => {
  // const { online } = useSelector((state: RootState) => state.app);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const plans = downloadedFields?.find(field => field.fieldId === fieldId && field.protocolId === protocolId)?.plans;

  const otherPlans = useMemo(() => {
    if (plans) {
      const growerFieldPlansIds: number[] = plans.map((plan: any) => Number(plan.id));
      return fieldVisitPlans?.filter(plan => !growerFieldPlansIds.includes(plan.id));
    }
  }, [plans, fieldVisitPlans]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return plans && plans.length ? (
    <>
      <IconButton size="small" onClick={handleClick} css={styles.icon}>
        <MapIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List component="nav">
          {plans.map((plan: any) => (
            <ListItem
              key={plan.id}
              css={styles.noPadding}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <CheckIcon color="success" />
                </IconButton>
              }
            >
              <ListItemButton>
                <ListItemText primary={plan.name} />
              </ListItemButton>
            </ListItem>
          ))}

          {otherPlans?.map((plan: any) => (
            <ListItem key={plan.id} css={styles.noPadding} secondaryAction={<></>}>
              <ListItemButton>
                <ListItemText primary={plan.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default DownloadFieldVisitsButton;
