/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useParams } from 'react-router-dom';

import { styles } from './styles';

import { ViewHeader } from '@components/ViewHeader';
import MapView from '@components/MapView/MapView';
import { ToastType } from '@components/ui/Toast';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { useCreateFieldNote } from '@hooks/useCreateFieldNote';
import { useGetCurrentLocation } from '@hooks/useGetCurrentLocation';
import { useToast } from '@hooks/app/useToast';
import { Position } from '@mytypes/map';
import { createFieldVisitNoteWithDexie } from '@services/local';
import { RootState } from '@state-mgmt/store';
import { setShowAppNavigator } from '@state-mgmt/slices/appSlice';
import { getFieldCenterPoint } from '@utils/getFieldCenterPoint';
import UploadPhoto from './UploadPhoto';
import config from '../../../config';

const API_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss [GMT]ZZ';

const FieldNoteCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showToast = useToast();

  const { growerId, fieldId, protocolId } = useParams();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { currentPosition, online, simulateGPS, trialId } = useSelector((state: RootState) => state.app);
  const getCurrentLocation = useGetCurrentLocation();

  const [files, setFiles] = useState<File[] | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [fieldBoundary, setFieldBoundary] = useState<any | null>(null);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const [geoLocationPermissionsAlertOpen, setGeoLocationPermissionsAlertOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const [getFieldTablesState, getFieldTables] = useGetFieldTables();
  const [createFieldNoteState, createFieldNote] = useCreateFieldNote();

  useEffect(() => {
    // showToast({ type: ToastType.INFO, children: `You can drag and drop the red marker to adjust the location on the map.` });
  }, []);

  useEffect(() => {
    if (createFieldNoteState.error) {
      setShowError(true);
    }
  }, [createFieldNoteState.error]);

  useEffect(() => {
    dispatch(setShowAppNavigator(false));
    if (!simulateGPS) {
      getCurrentLocation();
    }
  }, [simulateGPS, dispatch]);

  useEffect(() => {
    if (trialId && protocolId && fieldId) {
      getFieldTables(trialId, protocolId, fieldId, true);
    }
  }, [fieldId]);

  useEffect(() => {
    if (currentPosition) {
      setLatitude(currentPosition.latitude);
      setLongitude(currentPosition.longitude);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (getFieldTablesState.data) {
      if (getFieldTablesState.data.field) {
        const data = getFieldTablesState.data.field;
        setFieldBoundary(data);
        if (!currentPosition && !simulateGPS) {
          setGeoLocationPermissionsAlertOpen(true);
        }
      }
    }
  }, [getFieldTablesState.data]);

  useEffect(() => {
    if (createFieldNoteState.isSuccess) {
      handleGoBack();
    }
  }, [createFieldNoteState.isSuccess]);

  const hasLocation: boolean = useMemo(() => !!markers.length, [markers]);

  const handleGoBack = () => {
    dispatch(setShowAppNavigator(true));
    navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}`);
  };

  const onMarkerDragEnd = (event: any, newPosition: Position) => {
    setLatitude(newPosition.latitude);
    setLongitude(newPosition.longitude);
    setMarkers([{ latitude: newPosition.latitude, longitude: newPosition.longitude, draggable: true }]);
  };

  const onUpdateNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentPosition) {
      setMarkers([{ ...currentPosition, draggable: true }]);
    }
    setNote(event.target.value);
  };

  const onClearNote = () => {
    setNote('');
    setMarkers([]);
  };

  const onSaveNote = () => {
    if (!note.match(new RegExp(config.REACT_APP_NOTE_REGEX))) {
      showToast({ type: ToastType.ERROR, children: 'Invalid characters in description' });
      return;
    }

    if (!trialId) return;

    if (online) {
      createFieldNote({
        date: dayjs(new Date()).format(API_DATE_FORMAT),
        fieldId: fieldId || '',
        files: files || [],
        latitude: latitude || 0,
        longitude: longitude || 0,
        note: note || '',
        protocolId: protocolId || '',
        trialId
      });
    } else {
      createFieldVisitNoteWithDexie({
        files: files || [],
        protocolId: protocolId || '',
        date: dayjs(new Date()).format(API_DATE_FORMAT),
        note: note || '',
        latitude: latitude || 0,
        longitude: longitude || 0,
        fieldId: fieldId || '',
        zoneId: null
      });

      setShowError(true);
    }
  };

  const handleOnSetLocation = () => {
    const centerPoint = getFieldCenterPoint(fieldBoundary);
    if (centerPoint) {
      setMarkers([{ ...centerPoint, draggable: true }]);
      setLatitude(centerPoint.latitude);
      setLongitude(centerPoint.longitude);
    }
  };

  return (
    <>
      <LoadingOverlayWrapper
        spinner
        active={createFieldNoteState.isLoading}
        text={t('create-field-note.saving-message')}
        styles={{
          wrapper: {
            height: '100vh'
          }
        }}
      >
        <Paper css={styles.container(theme)}>
          <ViewHeader title={t('create-field-note.title')} onGoBackBtnPress={handleGoBack} />
          <div css={styles.mapContainer}>
            <LoadingOverlayWrapper
              spinner
              active={getFieldTablesState.isLoading}
              styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
              text={t('create-field-note.loading-message')}
            >
              <MapView
                showCurrentPosition={true}
                showFlyToCurrentLocation={true}
                fieldBoundary={fieldBoundary}
                markers={markers}
                onFocusMarkerLocationChange={onMarkerDragEnd}
              />
            </LoadingOverlayWrapper>
          </div>
          <div css={styles.locationBtnContainer}>
            <Button variant="text" size="small" onClick={() => handleOnSetLocation()}>
              {hasLocation ? t('create-field-note.edit-location-button') : t('create-field-note.add-location-button')}
            </Button>
          </div>
          <div css={styles.scrollableArea(theme)}>
            <FormControl fullWidth>
              <TextField
                label={t('create-field-note.note-label')}
                value={note}
                type="text"
                multiline
                minRows={4}
                onChange={onUpdateNote}
                inputProps={{
                  maxLength: 4000,
                  endAdornment: (
                    <InputAdornment position="end" variant="standard">
                      <IconButton aria-label="clear answer" onClick={onClearNote} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <UploadPhoto hideSave={true} files={files} setFiles={setFiles} inputRef={inputRef} />
          </div>

          <div css={styles.actionsContainer(theme)}>
            <Button variant="text" onClick={handleGoBack} sx={{ textTransform: 'uppercase' }}>
              {t('general.cancel')}
            </Button>
            <Button
              css={styles.actionsBtn}
              variant="contained"
              onClick={() => onSaveNote()}
              disabled={markers.length === 0 || note === ''}
              sx={{ textTransform: 'uppercase' }}
            >
              {t('general.save')}
            </Button>
          </div>

          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showError} onClose={() => setShowError(false)}>
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowError(false);
                    handleGoBack();
                  }}
                >
                  {t('general.try-later')}
                </Button>
              }
            >
              <AlertTitle>{t('general.error')}</AlertTitle>
              {t('create-field-note.sync-error')}
            </Alert>
          </Snackbar>
        </Paper>
      </LoadingOverlayWrapper>

      <Dialog open={geoLocationPermissionsAlertOpen} onClose={() => setGeoLocationPermissionsAlertOpen(true)}>
        <DialogTitle>{t('create-field-note.location-not-enabled-dialog-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText css={styles.dialogText}>{t('create-field-note.location-not-enabled-dialog-text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGeoLocationPermissionsAlertOpen(false)} variant="contained" autoFocus sx={{ textTransform: 'uppercase' }}>
            {t('general.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FieldNoteCreate;
