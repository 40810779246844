import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Client, ClientUser } from '@mytypes/client';
import { RootState } from '@state-mgmt/store';

export type ClientState = {
  clients: Client[];
  users: ClientUser[];
};

const initialState: ClientState = {
  clients: [],
  users: []
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients(state: ClientState, action: PayloadAction<Client[]>) {
      state.clients = action.payload;
    },
    setUsers(state: ClientState, action: PayloadAction<ClientUser[]>) {
      state.users = action.payload;
    }
  }
});

// Actions
export const { setClients, setUsers } = clientSlice.actions;

// Selectors
export const selectClients = (state: RootState) => state.client.clients;
export const selectUsers = (state: RootState) => state.client.users;

export default clientSlice.reducer;
