/** @jsxImportSource @emotion/react */
import { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Map, { MapLayerMouseEvent, MapLayerTouchEvent, MapRef } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import * as turf from '@turf/turf';
import debounce from 'lodash.debounce';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import GestureIcon from '@mui/icons-material/Gesture';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import GeojsonPolygonLayer from '@components/MapView/GeojsonPolygonLayer';
import { DrawControl } from '@components/DrawControl';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetCLUs } from '@hooks/useGetCLUs';
import { MapViewState } from '@mytypes/map';
import { createFieldApi } from '@services/fields';
import { enrollFieldApi } from '@services/protocol';
import { createTableApi } from '@services/mdi';
import { RootState } from '@state-mgmt/store';
import { wait } from '@utils/wait';
import { calcAcresForGeojson, findPolygonContainingPointInFeatureCollection } from '@utils/geojson';
import { styles } from './styles';
import './custom-draw-controls.css';
import config from '../../config';

const CLU_LIMIT = 9999;
const CANADA_COUNTRY_NAME = 'canada';
const GEOCODER_TYPE_LIST = 'country,region,place,postcode,locality,neighborhood';
const INITIAL_MAP_ZOOM = 10;
const MINIMAL_ZOOM_TO_GET_CLU = 12;
const DEBOUNCE_FOR_REFRESH_CLU = 500;
const MAP_STYLES = {
  dark: 'mapbox://styles/mapbox/dark-v10',
  light: 'mapbox://styles/mapbox/light-v10',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v12',
  navigationLight: 'mapbox://styles/mapbox/navigation-day-v1',
  navigationNight: 'mapbox://styles/mapbox/navigation-night-v1'
};

export const defaultViewState: MapViewState = {
  longitude: -100,
  latitude: 40,
  zoom: 4
};

export interface Props {
  clientId: string;
  trialId: string;
  protocolId: string | undefined;
  growerId: string;
  onClose: () => void;
  onConfirm: (fieldId: string, fieldName: string) => void;
}

export type EnrollBoundaryInfo = {
  geojson: any;
  acres?: number;
  viewConfirmation?: boolean;
};

const CreateNewFieldModal = ({ clientId, trialId, protocolId, growerId, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  const mapRef = useRef<MapRef>();
  const geocoderRef = useRef();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);
  const [areaAcres, setAreaAcres] = useState<number | undefined>();
  const [geojsonFromDrawing, setGeojsonFromDrawing] = useState<any | undefined>();
  const [featuresDrawed, setFeaturesDrawed] = useState({});
  const [boundarySelectInfo, setBoundarySelectInfo] = useState<EnrollBoundaryInfo>();
  const [selectCLUMode, setSelectCLUMode] = useState<boolean>(false);
  const [getCLUsState, getCLUs] = useGetCLUs();
  const [getLLDsState, getLLDs] = useGetCLUs();
  const [createNewFieldDialogOpen, setCreateNewFieldDialogOpen] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string | undefined>();
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);
  const [centerWktPoint, setCenterWktPoint] = useState<string>();

  const geocoder = new MapboxGeocoder({
    accessToken: config.REACT_APP_MAPBOX_ACCESS_TOKEN,
    types: GEOCODER_TYPE_LIST
  });

  geocoder.on('result', e => {
    const { center } = e.result;
    if (e.result.center)
      mapRef.current?.flyTo({
        center: center,
        zoom: INITIAL_MAP_ZOOM,
        essential: true
      });
  });

  // maybe zoom out to initial view state??
  geocoder.on('clear', () => {});

  useEffect(() => {
    // hack for waiting for the map initialized
    wait(0).then(() => {
      if (geocoderRef.current) {
        geocoder.addTo(geocoderRef.current);
      }
    });
  }, []);

  useEffect(() => {
    if (!geojsonFromDrawing?.features?.length) {
      setAreaAcres(undefined);
      setCenterWktPoint(undefined);
      return;
    }
    setAreaAcres(calcAcresForGeojson(geojsonFromDrawing));
    setCenterWktPoint(getCenterWktPoint(geojsonFromDrawing));
  }, [geojsonFromDrawing]);

  useEffect(() => {
    const elem = document.querySelector('.mapboxgl-ctrl-group') as HTMLInputElement | null;
    if (selectCLUMode) {
      if (elem) {
        elem.style.display = 'none';
      }
      refreshCLUs();
    } else {
      if (elem) {
        elem.style.display = 'initial';
      }
    }
  }, [selectCLUMode]);

  useEffect(() => {
    if (Object.keys(featuresDrawed).length) {
      setGeojsonFromDrawing({
        type: 'FeatureCollection',
        features: Object.values(featuresDrawed)
      });
    } else {
      setGeojsonFromDrawing(undefined);
    }
  }, [featuresDrawed]);

  const createField = async () => {
    try {
      setIsEnrolling(true);

      if (fieldName && centerWktPoint && trialId && protocolId) {
        const response = await createFieldApi({
          fields: [
            {
              owner_id: growerId,
              name: fieldName,
              wkt_point: centerWktPoint,
              protocol_id: protocolId,
              boundary: btoa(JSON.stringify(selectCLUMode ? boundarySelectInfo?.geojson : geojsonFromDrawing))
            }
          ],
          trial_id: trialId,
          client_id: clientId
        });

        const { created_ids } = response || {};
        
        if (created_ids && created_ids.length && protocolId) {
          const fieldId = created_ids[0];
          onConfirm(fieldId, fieldName);
          setIsEnrolling(false);
        }
      } else {
        // handle the case where fieldName, centerWktPoint, or trialId is not defined
        setIsEnrolling(false);
        // show a snackbar or notification to the user about missing information
      }
    } catch (error) {
      setIsEnrolling(false);
      // show that something went wrong to the user into a snackbar
    }
  };

  const refreshCLUs = async () => {
    if (!selectCLUMode || !mapRef.current || mapRef.current?.getZoom() < MINIMAL_ZOOM_TO_GET_CLU) {
      return;
    }

    // get bounding box for current map viewport
    let bounds = mapRef.current.getBounds();
    let bbox = bounds.toArray().reduce((acc, bound) => {
      return [...acc, ...bound];
    }, []) as turf.BBox;

    getCLUs(bbox, CLU_LIMIT);
    getLLDs(bbox, CLU_LIMIT, CANADA_COUNTRY_NAME);
  };

  // calculate middle wkp point
  const getCenterWktPoint = (geojson: turf.helpers.FeatureCollection): string | undefined => {
    if (!geojson.features.length) return undefined;

    const centroid = turf.centroid(geojson);
    if (!centroid) return undefined;

    return `POINT(${centroid.geometry.coordinates[0]} ${centroid.geometry.coordinates[1]})`;
  };

  const handleCluMapSelect = (event: MapLayerMouseEvent | MapLayerTouchEvent) => {
    if (!selectCLUMode) return;

    const point: [number, number] = [event.lngLat.lng, event.lngLat.lat];

    // first check if selected polygon is already selected and remove it from selection if so
    if (boundarySelectInfo?.geojson) {
      const selectedResult = findPolygonContainingPointInFeatureCollection(boundarySelectInfo.geojson, point);
      if (selectedResult) {
        let geojson = turf.featureCollection([...boundarySelectInfo.geojson.features.filter((f: any) => f !== selectedResult)]);
        const roundedAreaAcres = calcAcresForGeojson(geojson);
        setAreaAcres(roundedAreaAcres);
        setCenterWktPoint(getCenterWktPoint(geojson));
        setBoundarySelectInfo(prevState => ({
          ...prevState,
          geojson,
          acres: roundedAreaAcres
        }));
        return;
      }
    }

    const result =
      findPolygonContainingPointInFeatureCollection(getCLUsState.data, point) || findPolygonContainingPointInFeatureCollection(getLLDsState.data, point);

    if (result?.geometry) {
      const previousSelected = boundarySelectInfo?.geojson?.features;
      let geojson = turf.featureCollection(previousSelected ? [...previousSelected, result] : [result]);
      const roundedAreaAcres = calcAcresForGeojson(geojson);
      setAreaAcres(roundedAreaAcres);
      setCenterWktPoint(getCenterWktPoint(geojson));
      setBoundarySelectInfo(prevState => {
        return {
          ...prevState,
          geojson,
          acres: roundedAreaAcres
        };
      });
    }
  };

  const onUpdateDraw = useCallback((e: any) => {
    setFeaturesDrawed(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        // @ts-ignore
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDeleteDraw = useCallback((e: any) => {
    setFeaturesDrawed(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        // @ts-ignore
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const debouncedMapMoveChangeHandler = debounce(refreshCLUs, DEBOUNCE_FOR_REFRESH_CLU);

  return (
    <div css={styles.container(themeMode)}>
      <div css={styles.content}>
        {/* @ts-ignore */}
        <div ref={geocoderRef} css={styles.geoCoder(theme)} />
        <div css={styles.mapContainer}>
          <Map
            // @ts-ignore
            ref={mapRef}
            mapboxAccessToken={config.REACT_APP_MAPBOX_ACCESS_TOKEN}
            initialViewState={defaultViewState}
            mapStyle={MAP_STYLES.satellite}
            attributionControl={false}
            onMoveEnd={debouncedMapMoveChangeHandler}
            onClick={handleCluMapSelect}
          >
            {selectCLUMode && getCLUsState.data ? <GeojsonPolygonLayer color="white" geometry={getCLUsState.data} /> : null}
            {selectCLUMode && getLLDsState.data ? <GeojsonPolygonLayer color="white" geometry={getLLDsState.data} /> : null}

            {boundarySelectInfo?.geojson ? (
              <GeojsonPolygonLayer color="white" geometry={boundarySelectInfo?.geojson} filled={true} getFillColor="#ccc" />
            ) : null}

            {!selectCLUMode && (
              <DrawControl
                position="top-right"
                displayControlsDefault={false}
                controls={{
                  polygon: true,
                  trash: true
                }}
                onCreate={onUpdateDraw}
                onUpdate={onUpdateDraw}
                onDelete={onDeleteDraw}
              />
            )}

            <Button
              color="primary"
              variant="contained"
              css={styles.modeBtn}
              startIcon={selectCLUMode ? <GestureIcon /> : <TouchAppIcon />}
              onClick={() => {
                setAreaAcres(undefined);
                setCenterWktPoint(undefined);
                if (selectCLUMode) {
                  setBoundarySelectInfo(undefined);
                }
                setSelectCLUMode(!selectCLUMode);
              }}
            >
              {selectCLUMode ? t('create-new-field.draw-mode-button') : t('create-new-field.selection-mode-button')}
            </Button>
          </Map>
        </div>
        <div css={styles.legendContainer(theme)}>
          <Typography color="text.primary" css={styles.centeredText}>
            {t('create-new-field.area-selected-label')}: {areaAcres || 0}
          </Typography>
          {selectCLUMode ? (
            <Typography color="text.primary" css={styles.centeredText}>
              {t('create-new-field.select-message')}
            </Typography>
          ) : (
            <Typography color="text.primary" css={styles.centeredText}>
              {t('create-new-field.draw-message')}
            </Typography>
          )}
        </div>
        <div css={styles.actionsContainer(theme)}>
          <Button css={styles.actionBtn(theme)} size="large" variant="outlined" onClick={() => onClose()}>
            {t('general.cancel')}
          </Button>
          <Button css={styles.actionBtn(theme)} size="large" variant="contained" onClick={() => setCreateNewFieldDialogOpen(true)} disabled={!areaAcres}>
            {t('create-new-field.create-new-field-button')}
          </Button>
        </div>

        <Dialog open={createNewFieldDialogOpen} onClose={() => setCreateNewFieldDialogOpen(true)} fullWidth>
          <DialogTitle>{t('create-new-field.create-new-field-title')}</DialogTitle>
          <DialogContent css={styles.contentDialog(theme)}>
            <FormControl fullWidth>
              <TextField
                label={t('create-new-field.field-name-label')}
                autoComplete="off"
                type={'text'}
                InputLabelProps={{ shrink: true }}
                onChange={e => setFieldName(e.target.value)}
                disabled={isEnrolling}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateNewFieldDialogOpen(false)} disabled={isEnrolling}>
              {t('general.cancel')}
            </Button>
            <LoadingButton
              onClick={() => createField()}
              variant="contained"
              autoFocus
              disabled={!fieldName}
              loading={isEnrolling}
              loadingIndicator={t('create-new-field.enrolling-message')}
              css={styles.loadingBtn}
            >
              {t('general.ok')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CreateNewFieldModal;
