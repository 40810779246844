import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { ClientUser } from '@mytypes/client';
import { getClientUsersListApi } from '@services/clients';
import { setUsers } from '@state-mgmt/slices/client-slice';

export const useGetClientUsers = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (clientId: string) => {
      try {
        const users = await getClientUsersListApi(clientId);
        if (!users) return [];
        dispatch(setUsers(users));
        localStorage.setItem('clientUsers', JSON.stringify(users));
        return users;
      } catch {
        const users = JSON.parse(localStorage.getItem('clientUsers') || '[]') as ClientUser[];
        dispatch(setUsers(users));
        return users;
      }
    }, [])
  );
};
