/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onClose: () => void;
}

const SyncVisitsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { themeMode } = useSelector((state: RootState) => state.app);

  const handleSync = () => {
    onClose();
    navigate('sync-field-visits');
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('field-visits-sync.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('field-visits-sync.text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('general.cancel')}</Button>
        <Button onClick={handleSync} variant="contained" autoFocus>
          {t('general.sync')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default SyncVisitsModal;
