import { useCallback } from 'react';

import { useAsyncCallback } from '@utils/useAsyncCallback';
import { updateFieldNoteApi } from '@services/protocol';

export const useEditFieldNote = () => {
  return useAsyncCallback(
    useCallback(async (note: any) => {
      try {
        await updateFieldNoteApi(note);
      } catch (error) {
        throw new Error('Something went wrong');
      }
    }, [])
  );
};
