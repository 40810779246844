/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from 'react';

import { Modal as MuiModal } from '@mui/material';

export interface Props {
  visible: boolean;
  children: any;
  onClose?: (...args: any[]) => void;
}

export const Modal = ({ visible, children, onClose }: PropsWithChildren<Props>) => {
  return (
    <MuiModal open={visible} onClose={onClose}>
      <>{children}</>
    </MuiModal>
  );
};
export default Modal;
