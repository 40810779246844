/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { Typography, Box, Checkbox, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';
import { Page } from '@mytypes/protocol';

export interface Props {
  hidden: boolean;
  fieldName: string;
  data: Page[];
  isLoading: boolean;
  onSelectionChange: (selection: string[]) => void;
}

const CharacteristicSections = ({ hidden = false, fieldName, data, isLoading = false, onSelectionChange }: Props) => {
  const { t } = useTranslation();

  const triggered = useRef(false);
  const theme = useAppTheme({});
  const { showBoundary } = useErrorBoundary();
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const { trialProducts } = useSelector((state: RootState) => state.trial);

  useEffect(() => {
    if (data.length && !triggered.current) {
      const allPageIds = data.map(({ pageId }) => pageId);
      setSelectedSections(allPageIds);
      triggered.current = true;
    }
  }, [data]);

  useEffect(() => {
    onSelectionChange(selectedSections);
  }, [selectedSections]);

  const handleToggle = (sectionId: string) => () => {
    try {
      selectedSections.some(id => id === sectionId)
        ? setSelectedSections(selectedSections.filter(id => id !== sectionId))
        : setSelectedSections([...selectedSections, sectionId]);
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={[styles.container, hidden && styles.hidden]}>
      <Typography color="text.primary" fontSize={22}>
        {fieldName}
      </Typography>
      <Typography color="text.primary" fontSize={14} css={styles.sectionSubtitle}>
        {t('caracteristics.sections-selection-text')}
      </Typography>

      <Box css={styles.sectionsListContainer}>
        <List css={styles.sectionsList}>
          <LoadingOverlay
            spinner
            active={isLoading}
            text={t('caracteristics.loading-caracteristics-message')}
            styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
          >
            {data.map(({ pageId, label, products }) => (
              <ListItem
                key={`section-${pageId}`}
                secondaryAction={
                  <Checkbox name={`section-${pageId}`} edge="end" onChange={handleToggle(pageId)} checked={selectedSections.some(id => id === pageId)} />
                }
                disablePadding
              >
                <ListItemButton css={styles.sectionsListBtnItem(theme)}>
                  <ListItemText
                    primary={
                      <div css={styles.sectionsListItem}>
                        <span>{label}</span>
                        <span
                          css={styles.sectionsListItemProductPoint(
                            products?.length > 0 ? trialProducts.find(tp => tp.id === products.at(0))?.color || 'initial' : 'initial'
                          )}
                        ></span>
                      </div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </LoadingOverlay>
        </List>
      </Box>
    </div>
  );
};

export default CharacteristicSections;
