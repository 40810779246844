import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { getTrialProductsApi } from '@services/trial';
import { setTrialProducts } from '@state-mgmt/slices/trial-slice';
import { TrialProduct } from '@mytypes/trial';

export const useGetTrialProducts = () => {
  const dispatch = useDispatch();
  return useAsyncCallback(
    useCallback(async (trialId: string) => {
      try {
        const { products } = (await getTrialProductsApi(trialId)) || {};

        if (!products) return [];

        localStorage.setItem('products', JSON.stringify(products));
        dispatch(setTrialProducts(products));
        return products;
      } catch {
        const products = JSON.parse(localStorage.getItem('products') || '[]') as TrialProduct[];
        dispatch(setTrialProducts(products));
        return products;
      }
    }, [])
  );
};
