import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { getGrowersByOperativeUserApi } from '@services/protocol';
import { RootState } from '@state-mgmt/store';

export const useGetGrowerFieldsByOperative = () => {
  const { trialGrowers } = useSelector((state: RootState) => state.trial);

  return useAsyncCallback(
    useCallback(async (trialId: string, userId: string) => {
      try {
        const { grower_protocols } = (await getGrowersByOperativeUserApi(trialId, userId)) || {};
        let result: any[] = [];
        if (grower_protocols.length) {
          grower_protocols.forEach((gp: any) => {
            result = [...result, ...trialGrowers.filter(tg => tg.user_id === gp.user_id)];
          });
        }
        return result;
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
};
