import { useCallback, useState } from 'react';

import { useGetProtocols } from '@hooks/useGetProtocols';
import { useGetFields } from '@hooks/useGetFields';
import { useGetTrialsFields } from '@hooks/useGetTrialsFields';
import { useGetTrialGrowerMembers } from '@hooks/useGetTrialGrowerMembers';
import { useGetTrialProducts } from '@hooks/useGetTrialProducts';

export const useGetTrialData = (): {
  getTrialData: (clientId: string, trialId: string) => Promise<void>;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, getProtocols] = useGetProtocols();
  const [, getFields] = useGetFields();
  const [, getTrialsFields] = useGetTrialsFields();
  const [, getMembers] = useGetTrialGrowerMembers();
  const [, getTrialsProducts] = useGetTrialProducts();

  const getTrialData = useCallback(async (clientId: string, trialId: string) => {
    try {
      setIsLoading(true);

      let promises = [];
      promises.push(getProtocols(trialId));
      promises.push(getFields(trialId));
      promises.push(getTrialsFields(clientId));
      promises.push(getMembers(trialId));
      promises.push(getTrialsProducts(trialId));
      await Promise.all(promises);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, []);

  return {
    getTrialData,
    isLoading
  };
};
