/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserPermissions } from '@hooks/useUserPermissions';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export const Navigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userHasManagePermission } = useUserPermissions();
  const { t } = useTranslation();

  const [value, setValue] = useState('home');
  const { showAppNavigator, syncEnabled, themeMode } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    const pathname = location.pathname.replace(/\/$/, '/home');
    if (['/support'].includes(pathname)) {
      setValue('');
    }
    if (['/home', '/planner', '/enrollment', '/profile', '/sync'].includes(pathname)) {
      setValue(pathname.substring(1));
    }
  }, [location]);

  return (
    <Paper css={[styles.container, !showAppNavigator && styles.hidden]} elevation={3}>
      <BottomNavigation showLabels css={styles.navigator(themeMode)} value={value} onChange={(event, newValue) => setValue(newValue)}>
        <BottomNavigationAction label={t('navigation.home')} value="home" icon={<HomeIcon />} onClick={() => navigate('/')} />
        <BottomNavigationAction label={t('navigation.planner')} value="planner" icon={<ChecklistIcon />} onClick={() => navigate('/planner')} />
        {userHasManagePermission && (
          <BottomNavigationAction
            label={t('navigation.enrollment')}
            value="enrollment"
            icon={<DescriptionOutlinedIcon />}
            onClick={() => navigate('/enrollment')}
          />
        )}
        <BottomNavigationAction label={t('navigation.profile')} value="profile" icon={<PersonIcon />} onClick={() => navigate('/profile')} />
        <BottomNavigationAction
          label={t('navigation.sync')}
          value="sync"
          disabled={!syncEnabled}
          color="disabled"
          css={[styles.syncBtn(themeMode), syncEnabled ? styles.syncBtnEnabled(themeMode) : null]}
          icon={<SyncProblemIcon css={[styles.syncBtnIcon(themeMode), syncEnabled ? styles.syncBtnIconEnabled(themeMode) : null]} />}
          onClick={() => navigate('/sync-field-visits')}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Navigator;
