import { css } from '@emotion/react';

export const styles = {
  container: css`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
    padding: 20px;
  `,
  image: css`
    width: 100%;
  `,
  progress: css`
    width: 100%;
  `
};
