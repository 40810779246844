import { LocalFieldVisit } from '@mytypes/local';
import { SYNC_PROGRESS_STATE, SyncData, SyncDataResult, SyncDataTrial, syncProgress } from '@mytypes/syncFieldVisits';

export const prepareLocalFieldVisitsToSync = (data: LocalFieldVisit[]): SyncDataResult => {
  let progress: syncProgress[] = [];

  const result = data.reduce((acc: any, current: LocalFieldVisit) => {
    progress = [...progress, { localId: current.id, state: SYNC_PROGRESS_STATE.ON_HOLD }];

    const clientIndex: number = acc.findIndex((item: SyncData) => item.clientId === current.clientId);
    if (clientIndex !== -1) {
      const trialIndex: number = acc[clientIndex].trials.findIndex((item: SyncDataTrial) => item.trialId === current.trialId);

      if (trialIndex !== -1) {
        acc[clientIndex].trials[trialIndex].fields.push({
          protocolName: current.protocolName,
          protocolId: current.protocolId,
          fieldId: current.fieldId,
          fieldName: current.fieldName,
          id: current.id,
          stepId: current.stepId,
          pages: current.pages
        });
      } else {
        acc[clientIndex].trials.push({
          trialId: current.trialId,
          trialName: current.trialName,
          fields: [
            {
              protocolId: current.protocolId,
              protocolName: current.protocolName,
              fieldId: current.fieldId,
              fieldName: current.fieldName,
              id: current.id,
              stepId: current.stepId,
              pages: current.pages
            }
          ]
        });
      }
    } else {
      acc.push({
        clientId: current.clientId,
        clientName: current.clientName,
        trials: [
          {
            trialId: current.trialId,
            trialName: current.trialName,
            fields: [
              {
                protocolId: current.protocolId,
                protocolName: current.protocolName,
                fieldId: current.fieldId,
                fieldName: current.fieldName,
                id: current.id,
                stepId: current.stepId,
                pages: current.pages
              }
            ]
          }
        ]
      });
    }

    return acc;
  }, []);

  return {
    data: result,
    progress
  };
};
