import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { Protocol } from '@mytypes/protocol';
import { getProtocolsListApi } from '@services/protocol';
import { setProtocols } from '@state-mgmt/slices/protocol-slice';

export const useGetProtocols = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (trialId: string) => {
      try {
        const { protocols } = (await getProtocolsListApi(trialId)) || {};
        if (!protocols) return [];

        dispatch(setProtocols(protocols));
        localStorage.setItem('protocols', JSON.stringify(protocols));
        return protocols as Protocol[];
      } catch {
        const protocols = JSON.parse(localStorage.getItem('protocols') || '[]');
        dispatch(setProtocols(protocols));
        return protocols as Protocol[];
      }
    }, [])
  );
};
