import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Trial, TrialGroupUser, TrialField, TrialProduct, NearbyField } from '@mytypes/trial';

import { RootState } from '@state-mgmt/store';

export type TrialState = {
  fields: TrialField[];
  nearbyFields: NearbyField[];
  trialGrowers: TrialGroupUser[];
  trialOperatives: TrialGroupUser[];
  trialProducts: TrialProduct[];
  trials: Trial[];
};

const initialState: TrialState = {
  fields: [],
  nearbyFields: [],
  trialGrowers: [],
  trialOperatives: [],
  trialProducts: [],
  trials: []
};

export const trialSlice = createSlice({
  name: 'trial',
  initialState,
  reducers: {
    setFields(state: TrialState, action: PayloadAction<TrialField[]>) {
      state.fields = action.payload;
    },
    setNearbyFields(state: TrialState, action: PayloadAction<NearbyField[]>) {
      state.nearbyFields = action.payload;
    },
    setTrialGrowers(state: TrialState, action: PayloadAction<TrialGroupUser[]>) {
      state.trialGrowers = action.payload;
    },
    setTrialOperatives(state: TrialState, action: PayloadAction<TrialGroupUser[]>) {
      state.trialOperatives = action.payload;
    },
    setTrialProducts(state: TrialState, action: PayloadAction<TrialProduct[]>) {
      state.trialProducts = action.payload;
    },
    setTrials(state: TrialState, action: PayloadAction<Trial[]>) {
      state.trials = action.payload;
    }
  }
});

// Actions
export const { setFields, setNearbyFields, setTrialGrowers, setTrialOperatives, setTrialProducts, setTrials } = trialSlice.actions;

// Selectors
export const selectFields = (state: RootState) => state.trial.fields;
export const selectTrialGrowers = (state: RootState) => state.trial.trialGrowers;
export const selectTrialOperatives = (state: RootState) => state.trial.trialOperatives;
export const setlectTrialProducts = (state: RootState) => state.trial.trialProducts;
export const selectTrials = (state: RootState) => state.trial.trials;

export default trialSlice.reducer;
