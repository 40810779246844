/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Autocomplete, Button, Card, CardActions, CardContent, Paper, TextField, Typography } from '@mui/material';
import List from '@mui/material/List';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetOperative } from '@hooks/useGetOperative';
import { useGetGrowerFieldsByOperative } from '@hooks/useGetGrowerFieldsByOperative';
import { TrialGroupUser } from '@mytypes/trial';
import { GrowerSelectOption } from '@mytypes/grower';
import { RootState } from '@state-mgmt/store';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';

import { styles } from './styles';

const OperativeDetail = () => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { memberId } = useParams();

  const { trialId } = useSelector((state: RootState) => state.app);

  const [selectedGrower, setSelectedGrower] = useState<GrowerSelectOption | undefined>();
  const [growers, setGrowers] = useState<TrialGroupUser[]>([]);
  const [getGrowerFieldsByOperativeState, getGrowerFieldsByOperative] = useGetGrowerFieldsByOperative();

  const getOperative = useGetOperative();

  const member = useMemo(() => {
    if (memberId) {
      return getOperative(memberId);
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId && trialId) {
      getGrowerFieldsByOperative(trialId, memberId);
    }
  }, [memberId]);

  useEffect(() => {
    if (getGrowerFieldsByOperativeState.data) {
      setGrowers(getGrowerFieldsByOperativeState.data);
    }
  }, [getGrowerFieldsByOperativeState]);

  useEffect(() => {
    if (selectedGrower) {
      setGrowers(
        growers
          .filter(grower => (selectedGrower ? selectedGrower.id === grower.user_id : true))
          .sort((a: TrialGroupUser, b: TrialGroupUser) => a.first_name.localeCompare(b.first_name))
      );
    }
  }, [growers, selectedGrower]);

  return (
    <Paper css={styles.container(theme)}>
      {member && (
        <>
          <ViewHeader title={`${member.first_name} ${member.last_name}`} subtitle={t('operative-detail.subtitle')} onGoBackBtnPress={() => navigate('/')} />

          <LoadingOverlay
            spinner
            active={getGrowerFieldsByOperativeState.isLoading}
            text={t('operative-detail.loading-message')}
            styles={{ wrapper: styles.listLoadingOverlayContent }}
          >
            {growers.length === 0 && !getGrowerFieldsByOperativeState.isLoading && (
              <Typography color="text.secondary" css={styles.emptyStateMessage(theme)}>
                {t('operative-detail.empty-state-message')}
              </Typography>
            )}

            {growers.length > 0 && (
              <div css={styles.scrollableArea(theme)}>
                <Autocomplete
                  options={growers.map(({ user_id, first_name, last_name }) => ({ id: user_id, label: first_name + ' ' + last_name }))}
                  renderInput={params => <TextField {...params} label={t('operative-detail.grower-search-filter')} />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  onChange={(event: any, newValue) => setSelectedGrower(newValue ? newValue : undefined)}
                  value={selectedGrower || null}
                  css={styles.filter(theme)}
                />

                <List>
                  {growers.map(({ email, first_name, last_name, phone, user_id }) => (
                    <Card key={user_id} elevation={0} css={styles.card(theme)}>
                      <Typography color="text.primary" css={styles.cardTitle(theme)}>
                        {first_name + ' ' + last_name}{' '}
                      </Typography>

                      <CardContent css={styles.cardContent(theme)}>
                        <Typography color="text.primary" fontSize={14}>
                          {t('general.email')}: {email}
                        </Typography>
                        <Typography color="text.primary" fontSize={14}>
                          {t('general.phone')}: {formatPhoneNumber(phone)}
                        </Typography>
                      </CardContent>

                      <CardActions css={styles.cardActions}>
                        <Button
                          sx={{ textTransform: 'uppercase' }}
                          size="small"
                          onClick={() => {
                            navigate(`/grower/${user_id}?operative=${memberId}`);
                          }}
                        >
                          {t('general.view')}
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </List>
              </div>
            )}
          </LoadingOverlay>
        </>
      )}
    </Paper>
  );
};

export default OperativeDetail;
