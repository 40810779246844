/** @jsxImportSource @emotion/react */
import { PropsWithChildren, ReactNode, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export interface Props {
  type: ToastType;
  action?: ReactNode;
  onClose?: () => void;
}

export const Toast = ({ type, onClose, action, children }: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose && onClose();
  };

  return (
    <>
      {!!children && typeof children === 'string' ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity={type}
            // action={action}
            onClose={() => {
              setOpen(false);
              onClose && onClose();
            }}
          >
            {children}
          </Alert>
        </Snackbar>
      ) : (
        children
      )}
    </>
  );
};

export default Toast;
