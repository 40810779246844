import { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Navigator } from '@components/Navigator';
import { RootState } from '@state-mgmt/store';
import { setIsAunthetificated } from '@state-mgmt/slices/appSlice';
import { validateToken } from '@utils/isTokenExpired';
import { useUserPermissions } from '@hooks/useUserPermissions';

type Props = {
  children?: ReactNode;
};

const ProtectedRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAunthetificated } = useSelector((state: RootState) => state.app);
  const { userHasManagePermission } = useUserPermissions();

  // handling refresh event through splash window
  useEffect(() => {
    const [pageNav] = performance.getEntriesByType('navigation') as [PerformanceNavigationTiming];
    if (pageNav.type === 'reload') {
      const encodedUrl = encodeURIComponent(location.pathname + location.search);
      navigate(`/splash?redirectTo=${encodedUrl}`);
    } else {
      if (location.pathname === '/') {
        navigate(`/splash`);
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/enrollment') {
      if (!userHasManagePermission) {
        alert('Unauthorized Access');
        navigate('/splash?redirectTo=/');
      }
    }

    if (!localStorage.getItem('accessToken')) {
      dispatch(setIsAunthetificated(false));
      navigate('/signin');
      return;
    }

    validateToken().then(isValid => {
      if (!isValid) {
        alert('Your session has expired! Please, login again.');
        dispatch(setIsAunthetificated(false));
        navigate('/signin');
      }
    });
    dispatch(setIsAunthetificated(true));
  }, [location]);

  return isAunthetificated ? (
    <>
      {children}
      <Navigator />
    </>
  ) : (
    <></>
  );
};

export default ProtectedRoute;
