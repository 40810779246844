import { useMemo } from 'react';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import { PathStyleExtension } from '@deck.gl/extensions/typed';

import { useControl } from 'react-map-gl';
import { convertHexToArray } from '@utils/colorUtility';

const DeckGLOverlay = (
  props: MapboxOverlayProps & {
    interleaved?: boolean;
  }
) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

/**
 * Generic map layer for displaying geojson as a polygon
 *
 * @param props:
 *    layerId - unique string identifying the layer - make sure it's unique for the map as it
 *      will only display one with the same layerId
 *    geometry - geojson to display
 *    color - color for polygon outline.  Defaults to white
 */
const GeojsonPolygonLayer = ({
  color,
  dashedOutline = false,
  filled = false,
  geometry,
  getFillColor,
  getLineColor,
  lineWidth = 0.3,
  lineWidthScale,
  style,
  tooltip
}: {
  color?: string;
  dashedOutline?: boolean;
  filled?: boolean;
  geometry: string | any;
  getFillColor?: any;
  getLineColor?: any;
  lineWidth?: any;
  lineWidthScale?: any;
  style?: any;
  tooltip?: string;
}) => {
  const layer = useMemo(
    () =>
      new GeoJsonLayer({
        id: 'geojson-layer',
        data: geometry,
        pickable: true,
        stroked: true,
        filled: filled,
        extruded: false,
        lineWidthScale: lineWidthScale ? lineWidthScale : 20,
        lineWidthMinPixels: 2,
        getLineColor: getLineColor ? getLineColor : () => (color ? convertHexToArray(color) : new Uint8Array([255, 255, 255])),
        getFillColor: getFillColor ? getFillColor : undefined,
        getPointRadius: 100,
        getLineWidth: lineWidth,
        getElevation: 30,
        ...(dashedOutline
          ? {
              getDashArray: [3, 2],
              dashJustified: true,
              dashGapPickable: true,
              extensions: [new PathStyleExtension({ dash: true })]
            }
          : {})
      }),
    [geometry, color, getLineColor, lineWidthScale, getFillColor, filled]
  );

  return <DeckGLOverlay layers={[layer]} getTooltip={({ object }) => object && tooltip} style={style} />;
};

export default GeojsonPolygonLayer;
