import { getProtocolStatusFieldApi } from '@services/protocol';
import { STEP_TYPE, ProtocolStatusField } from '../types/protocol';

export const getProtocolStatusField = async (trialId: string, protocolId: string, fieldId: string): Promise<ProtocolStatusField[]> => {
  try {
    const response = await getProtocolStatusFieldApi({ trialId, protocolId, fieldId });
    const status = response?.status || [];
    return status.filter(row => row.step_type === STEP_TYPE.FIELD_VISIT);
  } catch (e) {
    console.error(e);
    return [];
  }
};
