import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SetLocationModal } from '@components/SetLocationModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';
import { Position } from '@mytypes/map';

export const useNewLocationModal = () => {
  const modalKey = 'new-location-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (characterizationId: string, position?: Position) => {
      const children = <SetLocationModal characterizationId={characterizationId} position={position} onClose={onClose} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return displayModal;
};
