/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import dayjs from 'dayjs';
import { Alert, Button, FormControl, IconButton, InputAdornment, Paper, Snackbar, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { parse } from 'wellknown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import { useEditFieldNote } from '@hooks/useEditFieldNote';
import { useAppTheme } from '@hooks/useAppTheme';
import { ViewHeader } from '@components/ViewHeader';
import { selectCurrentNote } from '@state-mgmt/slices/notesSlice';
import MapView from '@components/MapView/MapView';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { Position } from '@mytypes/map';

import PhotoList from './PhotoList';
import UploadPhoto from './UploadPhoto';
import { ToastType } from '@components/ui/Toast';
import { useSubmitFieldNoteUpload } from '@hooks/useSubmitFieldNoteUpload';
import { useDeleteFieldNoteUpload } from '@hooks/useDeleteCharacterizationUpload';
import { useToast } from '@hooks/app/useToast';
import { setShowAppNavigator } from '@state-mgmt/slices/appSlice';
import { getFieldCenterPoint } from '@utils/getFieldCenterPoint';
import { RootState } from '@state-mgmt/store';
import config from '../../../config';

const API_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss [GMT]ZZ';

const FieldNoteEdit = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const showToast = useToast();

  const { noteId, growerId, fieldId, protocolId } = useParams();
  const { trialId, clientId } = useSelector((state: RootState) => state.app);
  const selectedNote = useSelector(selectCurrentNote);

  const theme = useAppTheme({});
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [editFieldNoteState, editFieldNote] = useEditFieldNote();

  const [files, setFiles] = useState<File[] | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [fieldBoundary, setFieldBoundary] = useState<any | null>(null);
  const [showEditLocationInfo, setShowEditLocationInfo] = useState(true);

  const [getFieldTablesState, getFieldTables] = useGetFieldTables();
  const [submitNoteUploadState, submitNoteUpload] = useSubmitFieldNoteUpload();
  const [deleteFieldNoteUploadState, deleteFieldNoteUpload] = useDeleteFieldNoteUpload();

  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    dispatch(setShowAppNavigator(false));

    if (trialId && protocolId && fieldId) {
      getFieldTables(trialId, protocolId, fieldId, true);
    }
  }, [fieldId, dispatch]);

  useEffect(() => {
    if (selectedNote) {
      let point: { type: string; coordinates: number[] } | null = selectedNote.wkt_point
        ? (parse(selectedNote.wkt_point) as { type: string; coordinates: number[] })
        : null;

      if (point && point.coordinates) {
        setLatitude(point.coordinates[1]);
        setLongitude(point.coordinates[0]);
      }
      setNote(selectedNote.note);
    } else {
      dispatch(setShowAppNavigator(true));
      navigate('/');
    }
  }, [selectedNote]);

  useEffect(() => {
    if (latitude && longitude) {
      setMarkers([{ latitude, longitude, draggable: true }]);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (getFieldTablesState.data) {
      if (getFieldTablesState.data.field) {
        const data = getFieldTablesState.data.field;
        setFieldBoundary(data);
      }
    }
  }, [getFieldTablesState.data]);

  useEffect(() => {
    if (editFieldNoteState.isSuccess) {
      handleGoBack();
    }
  }, [editFieldNoteState.isSuccess]);

  useEffect(() => {
    if (submitNoteUploadState.isSuccess) {
      setFiles(null);
    }
  }, [submitNoteUploadState.isSuccess]);

  const handleGoBack = () => {
    dispatch(setShowAppNavigator(true));
    navigate(`/field-notes/${fieldId}/${protocolId}/${growerId}`);
  };

  const onMarkerDragEnd = (event: any, newPosition: Position) => {
    setLatitude(newPosition.latitude);
    setLongitude(newPosition.longitude);
  };

  const onUpdateNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const onClearNote = () => {
    setNote('');
  };

  const onSaveNote = () => {
    if (!note || !noteId || !protocolId || !trialId) return;

    if (!note.match(new RegExp(config.REACT_APP_NOTE_REGEX))) {
      showToast({ type: ToastType.ERROR, children: 'Invalid characters in description' });
      return;
    }

    editFieldNote({
      date: selectedNote?.date ? dayjs(selectedNote.date).format(API_DATE_FORMAT) : '',
      fieldId,
      field_note_id: noteId,
      note: note,
      protocolId: protocolId,
      trialId,
      ...(selectedNote?.zone_id && { zone_id: selectedNote?.zone_id }),
      ...(latitude && { latitude: latitude }),
      ...(longitude && { longitude: longitude })
    });
  };

  const onSavePhotos = () => {
    if (files && files.length && selectedNote && protocolId && fieldId && trialId) {
      const point: { type: string; coordinates: number[] } | null = selectedNote.wkt_point
        ? (parse(selectedNote.wkt_point) as {
            type: string;
            coordinates: number[];
          })
        : null;

      submitNoteUpload({
        trialId,
        files,
        protocolId,
        field_note_id: selectedNote.id,
        upload_type: 'PHOTO',
        fieldId,
        latitude: point ? point.coordinates[1] : undefined,
        longitude: point ? point.coordinates[0] : undefined
      });
    }
  };

  const onDeletePhoto = (valueId: any) => {
    if (!selectedNote || !protocolId || !fieldId || !trialId) return;

    deleteFieldNoteUpload({
      trialId,
      fieldId,
      noteId: selectedNote?.id,
      protocolId,
      uploadId: valueId
    });
  };

  const handleOnSetLocation = () => {
    const centerPoint = getFieldCenterPoint(fieldBoundary);
    setMarkers([{ ...centerPoint, draggable: true }]);
  };

  return (
    <LoadingOverlayWrapper
      spinner
      active={editFieldNoteState.isLoading || submitNoteUploadState.isLoading || deleteFieldNoteUploadState.isLoading}
      text={t('edit-field-note.saving-message')}
      styles={{
        wrapper: {
          height: '100vh'
        }
      }}
    >
      <Paper css={styles.container(theme)}>
        <ViewHeader title={t('edit-field-note.title')} onGoBackBtnPress={handleGoBack} />

        <div css={styles.mapContainer}>
          <LoadingOverlayWrapper
            spinner
            active={getFieldTablesState.isLoading}
            styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
            text={t('edit-field-note.loading-message')}
          >
            <MapView showFlyToCurrentLocation={true} fieldBoundary={fieldBoundary} markers={markers} onFocusMarkerLocationChange={onMarkerDragEnd} />
          </LoadingOverlayWrapper>
        </div>

        <div css={styles.locationBtnContainer}>
          <Button variant="text" size="small" onClick={() => handleOnSetLocation()}>
            {t('edit-field-note.edit-location-button')}
          </Button>
        </div>

        <div css={styles.scrollableArea(theme)}>
          <FormControl fullWidth>
            <TextField
              label={t('edit-field-note.note-label')}
              value={note}
              type="text"
              multiline
              minRows={4}
              onChange={onUpdateNote}
              inputProps={{
                maxLength: 4000,
                endAdornment: (
                  <InputAdornment position="end" variant="standard">
                    <IconButton aria-label="clear answer" onClick={onClearNote} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>

          <UploadPhoto files={files} setFiles={setFiles} inputRef={inputRef} onSavePhotos={onSavePhotos} />

          <PhotoList onDelete={onDeletePhoto} />
        </div>

        <div css={styles.actionsContainer(theme)}>
          <Button variant="text" onClick={handleGoBack} sx={{ textTransform: 'uppercase' }}>
            {t('general.cancel')}
          </Button>
          <Button
            css={styles.actionsBtn}
            variant="contained"
            onClick={onSaveNote}
            disabled={markers.length === 0 || note === ''}
            sx={{ textTransform: 'uppercase' }}
          >
            {t('general.save')}
          </Button>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showEditLocationInfo}
          autoHideDuration={6000}
          onClose={() => setShowEditLocationInfo(false)}
        >
          <Alert severity="info">{t('edit-field-note.alert-drag-drop')} </Alert>
        </Snackbar>
      </Paper>
    </LoadingOverlayWrapper>
  );
};

export default FieldNoteEdit;
