import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DeletePlanConfirmationModal } from '@components/DeletePlanConfirmationModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useDeletePlanConfirmationModal = () => {
  const modalKey = 'delete-plan-confirmation-modal';
  const dispatch = useDispatch();
  const [planIdDeleted, setPlanIdDeleted] = useState<number>();

  const onClose = useCallback(
    (planId: number | undefined) => {
      setPlanIdDeleted(planId);
      dispatch(removeModal({ key: modalKey }));
    },
    [dispatch]
  );

  const displayModal = useCallback(
    (planId: number) => {
      const children = <DeletePlanConfirmationModal planId={planId} onClose={onClose} />;
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal, planIdDeleted };
};
