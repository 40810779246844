import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { forgotPasswordApi } from '@services/user';

export const useForgotPassword = () =>
  useAsyncCallback(
    useCallback(async (email: string) => {
      try {
        const data = await forgotPasswordApi({ email });
        return data;
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
