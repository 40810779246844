/** @jsxImportSource @emotion/react */
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { styles } from './styles';

export default function UploadPhoto({
  setFiles,
  files,
  inputRef,
  onSavePhotos,
  hideSave = false
}: {
  files: File[] | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
  inputRef: React.RefObject<HTMLInputElement>;
  onSavePhotos?: () => void;
  hideSave?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useAppTheme({});

  return (
    <div>
      <Typography variant="body2" css={styles.subtitle(theme)}>
        {t('field-notes.upload-photo.title')}
      </Typography>

      <Grid item xs={12}>
        <Button variant="contained" component="label">
          {t('field-notes.upload-photo.add-photos-button')}
          <input
            ref={inputRef}
            accept="image/*"
            hidden
            type="file"
            multiple
            onChange={event => {
              if (event.target.files) {
                if (Array.prototype.some.call(event.target.files, file => !file.type.match(/image.*/))) {
                  alert(t('field-notes.upload-photo.format-error'));
                  return;
                } else {
                  setFiles(prev => {
                    return prev ? [...prev, ...(Array.from(event.target.files as any) as File[])] : [...(Array.from(event.target.files as any) as File[])];
                  });
                }
              }
            }}
          />
        </Button>

        {files && files.length ? (
          <>
            {Array.prototype.map.call(files, (file, index) => {
              return (
                <div key={index} css={styles.uploadContainer}>
                  <Typography key={file.name} css={styles.uploadContainerText}>
                    {file.name}
                  </Typography>

                  <Typography key={file.name}>{(file.size / (1024 * 1024)).toFixed(2)} MB</Typography>

                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      const newUploadFiles = [...files];
                      newUploadFiles.splice(index, 1);
                      setFiles(newUploadFiles);
                    }}
                    color="error"
                  >
                    {t('field-notes.upload-photo.remove-photo-button')}
                  </Button>
                </div>
              );
            })}
          </>
        ) : null}
      </Grid>

      {!hideSave && onSavePhotos ? (
        <Grid item container xs={12} justifyContent={'flex-end'}>
          <Button variant="contained" color="primary" onClick={onSavePhotos} disabled={!files || !files.length}>
            {t('field-notes.upload-photo.save-photos-button')}
          </Button>
        </Grid>
      ) : null}
    </div>
  );
}
