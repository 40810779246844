import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { Trial } from '@mytypes/trial';
import { getTrialsApi } from '@services/trial';
import { setTrials, setTrialGrowers } from '@state-mgmt/slices/trial-slice';
import { setProtocols } from '@state-mgmt/slices/protocol-slice';

export const useGetTrialsFields = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (clientId: string) => {
      try {
        if (!clientId) return;
        const { trials } = (await getTrialsApi(clientId)) || {};
        if (!trials) throw new Error("Failure fetching client trials");

        const activeTrials = trials.filter((trial: Trial) => trial.active);
        localStorage.setItem('trials', JSON.stringify(activeTrials));
        dispatch(setTrials(activeTrials));
        return activeTrials;
      } catch {
        dispatch(setTrials([]));
        dispatch(setTrialGrowers([]));
        dispatch(setProtocols([]));
        // throw e
      }
    }, [])
  );
};
