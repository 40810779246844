/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Modal, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles.css';
import { styles } from './styles';

export interface Props {
  open: boolean;
  photo: any;
  onClose: () => void;
  onPhotoDeleted: (id: string, valueId: string) => void;
}

const PhotoViewModal = ({ open, photo, onClose, onPhotoDeleted }: Props) => {
  const { t } = useTranslation();

  return photo ? (
    <Modal open={open} onClose={onClose}>
      <div className="photo-view-container">
        <img src={photo?.src} className="photo" alt="some place" />

        <ButtonGroup variant="text" aria-label="outlined primary button group" css={styles.actionsContainer}>
          <Button variant="outlined" onClick={onClose}>
            {t('general.close')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => {
              onPhotoDeleted(photo.id, photo.valueId);
              onClose();
            }}
          >
            {t('general.delete')}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default PhotoViewModal;
