import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { Client } from '@mytypes/client';
import { getClientListApi } from '@services/clients';
import { setClients } from '@state-mgmt/slices/client-slice';
import { setFavoriteClient } from '@state-mgmt/slices/appSlice';
import { setTrialGrowers, setTrials } from '@state-mgmt/slices/trial-slice';
import { setProtocols } from '@state-mgmt/slices/protocol-slice';

export const useGetClients = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async () => {
      try {
        const data = await getClientListApi();
        if (!data) return { clients: [], favoriteClientId: null };
        const { clients, favorite_client_id } = data;

        dispatch(setClients(clients));
        dispatch(setFavoriteClient(favorite_client_id));
        localStorage.setItem('clients', JSON.stringify(clients));
        localStorage.setItem('favoriteClientId', favorite_client_id);
        return { clients, favoriteClientId: favorite_client_id };
      } catch  {
        dispatch(setTrials([]));
        dispatch(setTrialGrowers([]));
        dispatch(setProtocols([]));
        const clients = JSON.parse(localStorage.getItem('clients') || '[]') as Client[];
        const favoriteClientId = localStorage.getItem('favoriteClientId') || null;
        dispatch(setClients(clients));
        dispatch(setFavoriteClient(favoriteClientId));
        return { clients, favoriteClientId };
      }
    }, [])
  );
};
