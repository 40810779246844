import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Note } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';

export type NotesState = {
  notes: Note[] | null;
  currentNote: Note | undefined;
};

const initialState: NotesState = {
  notes: null,
  currentNote: undefined
};

export const protocolSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes(state: NotesState, action: PayloadAction<Note[]>) {
      state.notes = action.payload;
    },
    setCurrentNote(state: NotesState, action: PayloadAction<Note>) {
      state.currentNote = action.payload;
    }
  }
});

// Actions
export const { setNotes, setCurrentNote } = protocolSlice.actions;

// Selectors
export const selectNotes = (state: RootState) => state.notes.notes;
export const selectCurrentNote = (state: RootState) => state.notes.currentNote;

export default protocolSlice.reducer;
