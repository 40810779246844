import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Crop } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';

export type CropState = {
  crops: Crop[];
};

const initialState: CropState = {
  crops: []
};

export const cropSlice = createSlice({
  name: 'crop',
  initialState,
  reducers: {
    setCrops(state: CropState, action: PayloadAction<Crop[]>) {
      state.crops = action.payload;
    }
  }
});

// Actions
export const { setCrops } = cropSlice.actions;

// Selectors
export const selectCrops = (state: RootState) => state.crop.crops;

export default cropSlice.reducer;
