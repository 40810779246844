/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { LocalVisitProgress } from '@mytypes/local';
import { clearFieldVisitProgressWithDexie } from '@services/local';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  visitProgress: LocalVisitProgress;
  onClose: () => void;
}

const FieldVisitWIPModal = ({ visitProgress, onClose }: Props) => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const { themeMode } = useSelector((state: RootState) => state.app);

  const handleOnCancelVisit = async () => {
    try {
      await clearFieldVisitProgressWithDexie();
      onClose();
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnContinueVisit = () => {
    try {
      navigate(visitProgress.url);
      onClose();
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('field-visits-wip.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            {t('general.trial')}: {visitProgress.trialName}
          </p>
          <p>
            {t('general.protocol')}: {visitProgress.protocolName}
          </p>
          <p>
            {t('general.field')}: {visitProgress.fieldName}
          </p>
          <p>
            {t('general.grower')}: {visitProgress.growerName}{' '}
          </p>
          <p>
            {t('general.step')}: {visitProgress.stepName}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancelVisit}>Cancel Visit</Button>
        <Button onClick={handleOnContinueVisit} variant="contained" autoFocus>
          {t('field-visits-wip.continue-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default FieldVisitWIPModal;
