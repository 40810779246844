/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { TrialGroupUser } from '@mytypes/trial';
import { GrowerSelectOption } from '@mytypes/grower';
import { RootState } from '@state-mgmt/store';

export interface Props {
  selectedProtocolId: string;
  onChange: (grower: string | undefined) => void;
}

const GrowerFilter = ({ selectedProtocolId, onChange }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { trialGrowers } = useSelector((state: RootState) => state.trial);
  const [growers, setGrowers] = useState<TrialGroupUser[]>([]);
  const [controlledValue, setControlledValue] = useState<GrowerSelectOption | undefined>();

  useEffect(() => onChange(controlledValue?.id), [controlledValue]);

  useEffect(() => {
    setGrowers(
      trialGrowers
        .filter(grower => {
          const conditionProtocolFilter =
            selectedProtocolId !== 'all' ? grower.protocol_ids.some(({ protocol_id }) => protocol_id === selectedProtocolId) : true;
          const conditionGrowerFilter = controlledValue ? controlledValue.id === grower.user_id : true;
          return conditionProtocolFilter && conditionGrowerFilter;
        })
        .sort((a: TrialGroupUser, b: TrialGroupUser) => a.first_name.localeCompare(b.first_name))
    );
  }, [selectedProtocolId, trialGrowers, controlledValue]);

  return (
    <Autocomplete
      id="grower-filter"
      options={growers.map(({ user_id, first_name, last_name }) => ({ id: user_id, label: first_name + ' ' + last_name }))}
      renderInput={params => <TextField {...params} label={t('planner.create.grower-filter-label')} />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      onChange={(event: any, val) => setControlledValue(val ? val : undefined)}
      value={controlledValue || null}
    />
  );
};

export default GrowerFilter;
