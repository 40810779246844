/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';
import { Position } from '@mytypes/map';
import MapView from '@components/MapView/MapView';
import { useNewLocationModal } from '@hooks/modals/useNewLocationModal';

export enum LocationWarningType {
  ACCURACY = 'success',
  OUT_OF_BOUNDARIES = 'warning'
}

export interface Props {
  accuracy: number;
  characterizationId: string;
  locationWarningType: LocationWarningType;
  position: Position;
  onClose: () => void;
}

const LocationWarningModal = ({ accuracy, characterizationId, locationWarningType, position, onClose }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);
  const { fieldBoundary } = useSelector((state: RootState) => state.visit);
  const displayModal = useNewLocationModal();

  const markers = [
    {
      draggable: false,
      focus: true,
      latitude: position.latitude,
      longitude: position.longitude
    }
  ];

  const onSetNewLocationBtnClicked = () => {
    try {
      onClose();
      displayModal(characterizationId, position);
    } catch (error) {
      showBoundary(error);
    }
  };

  const onKeepLocationBtnClicked = () => {
    try {
      onClose();
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('location-warning.title')}</DialogTitle>
      <DialogContent>
        {locationWarningType === LocationWarningType.ACCURACY && <Typography css={styles.message}>{t('location-warning.accuracy-message')}</Typography>}
        {locationWarningType === LocationWarningType.OUT_OF_BOUNDARIES && <Typography css={styles.message}>{t('location-warning.outside-message')}</Typography>}
        <div css={styles.mapContainer}>
          <MapView markers={markers} fieldBoundary={fieldBoundary} disableFlyToAnimation={true} />
        </div>
      </DialogContent>
      <DialogActions css={styles.actionsContainer(theme)}>
        <Button css={styles.actionsBtn} variant="outlined" onClick={onSetNewLocationBtnClicked}>
          {t('location-warning.set-location-button')}
        </Button>
        <Button css={styles.actionsBtn} variant="contained" onClick={onKeepLocationBtnClicked}>
          {t('location-warning.keep-location-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default LocationWarningModal;
