import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreateNewFieldModal } from '@components/CreateNewFieldModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useCreateNewFieldModal = () => {
  const modalKey = 'sync-visits-modal';
  const dispatch = useDispatch();
  const [newFieldCreated, setNewFieldCreated] = useState<any>();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const onConfirm = useCallback(
    (fieldId: string, fieldName: string) => {
      setNewFieldCreated({
        id: fieldId,
        name: fieldName
      });
      onClose();
    },
    [dispatch]
  );

  const displayModal = useCallback(
    (clientId: string, trialId: string, protocolId: string | undefined, growerId: string) => {
      const children = (
        <CreateNewFieldModal clientId={clientId} trialId={trialId} protocolId={protocolId} growerId={growerId} onClose={onClose} onConfirm={onConfirm} />
      );
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal, newFieldCreated };
};
