/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
  value: string;
}

const PhoneNumberMask = forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, value, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      unmask={true}
      value={value}
      inputMode="tel"
      // @ts-ignore
      inputRef={ref}
      // onAccept={(value: any) => {
      //   onChange({ target: { value } });
      // }}
      // overwrite
      // @ts-ignore
      onChange={onChange}
      // onComplete={value => props.onChange({ target: { value } })}
    />
  );
});

export default PhoneNumberMask;
