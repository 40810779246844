/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogTitle } from '@mui/material';

import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onConfirmOffSite: () => void;
  onConfirmOnSite: () => void;
}

const OnSiteModal = ({ onConfirmOffSite, onConfirmOnSite }: Props) => {
  const { t } = useTranslation();
  const { themeMode } = useSelector((state: RootState) => state.app);

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle> {t('on-site.title')}</DialogTitle>
      <DialogActions css={styles.actionsContainer}>
        <Button onClick={onConfirmOffSite}>{t('general.no')}</Button>
        <Button variant="contained" onClick={onConfirmOnSite}>
          {t('general.yes')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default OnSiteModal;
