/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import MapView from '@components/MapView/MapView';
import { ToastType } from '@components/ui/Toast';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { useAppTheme } from '@hooks/useAppTheme';
import { useCreateNewFieldModal } from '@hooks/modals/useCreateNewFieldModal';
import { useToast } from '@hooks/app/useToast';
import { Trial } from '@mytypes/trial';
import { Protocol } from '@mytypes/protocol';
import { getFieldsByGrowerApi } from '@services/fields';
import { RootState } from '@state-mgmt/store';
import { enrollFieldApi } from '@services/protocol';
import { styles } from './styles';

export type EnrolledField = {
  trial: Trial | undefined;
  protocol: Protocol | undefined;
  field: any;
};

export interface Props {
  growerId: string;
  handleSubmitComplete: (enrolledField: EnrolledField | undefined) => void;
  isSubmiting: (loading: boolean) => void;
  onSelectExistingField: () => void;
  protocol: Protocol | undefined;
  submit: boolean;
  trial: Trial | undefined;
}

const FieldEnrollmentForm = ({ growerId, trial, handleSubmitComplete, onSelectExistingField, submit, isSubmiting, protocol }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const showToast = useToast();
  const [getFieldTablesState, getFieldTables] = useGetFieldTables();
  const { displayModal: displayCreateNewFieldModal, newFieldCreated } = useCreateNewFieldModal();

  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const { fields: enrolledFields } = useSelector((state: RootState) => state.trial);
  const { currentProtocol, protocols } = useSelector((state: RootState) => state.protocol);
  const [selectedProtocol, setSelectedProtocol] = useState<string | undefined>(protocol?.id || currentProtocol);
  const [isLoadingFields, setIsLoadingFields] = useState<boolean>(false);
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);
  const [growerFields, setGrowerFields] = useState<any[]>();
  const [selectedField, setSelectedField] = useState<string>('loading');
  const [fieldBoundary, setFieldBoundary] = useState<any | undefined>();

  useEffect(() => {
    setIsLoadingFields(true);
    getFieldsByGrowerApi({ userId: growerId }).then(result => {
      setGrowerFields(result?.fields);
      setIsLoadingFields(false);
    });
  }, []);

  useEffect(() => {
    isSubmiting(isEnrolling);
  }, [isEnrolling]);

  useEffect(() => {
    if (selectedProtocol) {
      const processedFields = growerFields?.map((field: any) => {
        const fieldFound = enrolledFields.find(enrolledField => enrolledField.field_id === field.field_id);
        return { ...field, disabled: fieldFound?.protocol_id === selectedProtocol ? true : false };
      });
      setGrowerFields(processedFields);
    }
  }, [selectedProtocol]);

  useEffect(() => {
    if (clientId && trialId && selectedProtocol && selectedField) {
      getFieldTables(trialId, selectedProtocol, selectedField, false, false);
    }
  }, [selectedField]);

  useEffect(() => {
    if (getFieldTablesState.data && getFieldTablesState.data.field) {
      const data = getFieldTablesState.data.field;
      setFieldBoundary(data);
    }
  }, [getFieldTablesState.data]);

  useEffect(() => {
    if (submit) {
      enrollField();
    }
  }, [submit]);

  useEffect(() => {
    if (newFieldCreated) {
      handleSubmitComplete({
        trial,
        protocol: protocols.find(p => p.id === selectedProtocol),
        field: {
          fieldId: newFieldCreated.id,
          fieldName: newFieldCreated.name
        }
      });
    }
  }, [newFieldCreated]);

  const enrollField = async () => {
    if (!selectedProtocol || !clientId || !trial) return;

    try {
      setIsEnrolling(true);
      isSubmiting(true);
      const fieldName = growerFields?.find(f => f.field_id === selectedField)?.field_name;
      await enrollFieldApi({ clientId, trialId: trial.id, protocolId: selectedProtocol, fieldId: selectedField });
      setIsEnrolling(false);
      isSubmiting(false);
      handleSubmitComplete({
        trial,
        protocol: protocols.find(p => p.id === selectedProtocol),
        field: { selectedField, fieldName }
      });
    } catch (error) {
      console.error(error);
      setIsEnrolling(false);
      isSubmiting(false);
      showToast({ type: ToastType.ERROR, children: t('field-enrollement-form.error-message') });
    }
  };

  return (
    <div css={styles.container}>
      <Typography color="text.primary" css={styles.trialTitle(theme)}>
        {t('general.trial')}: {trial?.name}
      </Typography>

      <TextField
        select
        label={t('general.protocol')}
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={selectedProtocol}
        onChange={event => {
          const value = event.target.value as string;
          setSelectedProtocol(value);
        }}
        disabled={isEnrolling || !!currentProtocol}
      >
        {protocols
          .slice()
          .sort((a: Protocol, b: Protocol) => a.name.localeCompare(b.name))
          .map(protocol => (
            <MenuItem key={protocol.id} value={protocol.id}>
              {protocol.name}
            </MenuItem>
          ))}
      </TextField>

      <FormControl fullWidth sx={{ fieldset: { legend: { maxWidth: '100%' } } }}>
        <InputLabel variant="outlined" htmlFor="field-input" shrink={true}>
          {t('field-enrollement-form.select-field-label')}
        </InputLabel>

        <Select
          id="field-input"
          value={selectedField}
          disabled={isLoadingFields || isEnrolling || !selectedProtocol}
          label={t('field-enrollement-form.select-field-label')}
          autoComplete="off"
          onChange={(event: SelectChangeEvent) => {
            const value = event.target.value as string;
            setSelectedField(value);
            onSelectExistingField();
          }}
        >
          {isLoadingFields ? (
            <MenuItem disabled key="loading" value="loading">
              <em>{t('field-enrollement-form.loading-fields-message')}</em>
            </MenuItem>
          ) : (
            growerFields &&
            growerFields
              .sort((a: any, b: any) => a.field_name.localeCompare(b.field_name))
              .map(({ field_id, field_name, disabled }) => (
                <MenuItem key={field_id} value={field_id} disabled={disabled}>
                  {field_name}
                </MenuItem>
              ))
          )}
        </Select>

        <FormHelperText css={styles.helperContainer(theme)}>
          <WarningIcon color="primary" css={styles.helperIcon} />
          <Typography css={styles.helperText}>{t('field-enrollement-form.help-message')}</Typography>
        </FormHelperText>
      </FormControl>

      <Button
        variant="outlined"
        onClick={() => {
          if (!clientId) return;
          displayCreateNewFieldModal(clientId, trial?.id || '', currentProtocol || selectedProtocol, growerId);
        }}
        disabled={isEnrolling || !selectedProtocol}
      >
        {t('field-enrollement-form.create-new-field-button')}
      </Button>

      <div css={styles.mapContainer}>
        <LoadingOverlay
          spinner
          active={getFieldTablesState.isLoading}
          styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
          text={t('field-enrollement-form.loading-field-map-message')}
        >
          <MapView fieldBoundary={fieldBoundary} showCurrentPosition={true} />
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default FieldEnrollmentForm;
