import { FieldVisitObjStore, FieldVisitProgressObjStore, FieldVisitNoteObjStore, FieldVisitPlanObjStore, FieldObjStore } from '@mytypes/local';
import Dexie, { type EntityTable } from 'dexie';

const db = new Dexie('invision-mobile') as Dexie & {
  fieldVisits: EntityTable<FieldVisitObjStore, 'id'>;
  fieldVisitsProgress: EntityTable<FieldVisitProgressObjStore, 'id'>;
  fieldVisitNotes: EntityTable<FieldVisitNoteObjStore, 'id'>;
  fieldVisitPlans: EntityTable<FieldVisitPlanObjStore, 'id'>;
  fields: EntityTable<FieldObjStore, 'id'>;
};

db.version(2).stores({
  fieldVisits:
    'id++, clientId, clientName, createdAt, fieldId, fieldName, label, pages, protocolId, protocolName, savedValues, stepId, trialId, trialName, updatedAt',
  fieldVisitsProgress: '++id, fieldId, fieldName, growerName, pages, protocolId, protocolName, savedValues, stepId, stepName, trialId, trialName, url',
  fieldVisitNotes: '++id, createdAt, date, fieldId, files, id, latitude, longitude, note, protocolId, updatedAt, zoneId',
  fieldVisitPlans: '++id, createdAt, date, fields, name, updatedAt',
  fields:
    '++id, boundaries, clientId, clientName, createdAt, fieldId, fieldName, growerId, planId, planName, protocolId, protocolName, plans, trialId, trialName, updatedAt, visits'
});

export { db };
