import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FieldMapOptionsModal } from '@components/FieldMapOptionsModal';
import { MAP_LAYERS } from '@components/FieldMapOptionsModal/FieldMapOptionsModal';
import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

export const useFieldMapOptionsModal = () => {
  const modalKey = 'field-map-options-modal';
  const dispatch = useDispatch();
  const [selectedLayers, setSelectedLayers] = useState<string[]>([MAP_LAYERS.TRIAL_AREA, MAP_LAYERS.TREATMENT_ZONES]);

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (treatmentZones: any, handleMarkersView: boolean = false) => {
      const children = (
        <FieldMapOptionsModal
          handleMarkersView={handleMarkersView}
          selectedLayers={selectedLayers}
          treatmentZones={treatmentZones}
          onClose={onClose}
          onMapLayersChange={mapLayers => setSelectedLayers(mapLayers)}
        />
      );
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose, selectedLayers]
  );

  return { displayModal, selectedLayers };
};
